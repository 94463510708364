/* variables */
$body-color: #000;
$color-p: #1e75ff;
$color-s: #393126;
$color-black: #000;
$color-white: #fff;
$color-error: #ff5050;
$font-family-1: "Pretendard", sans-serif;
$font-family-2: "klavika-web", "Pretendard", sans-serif;

/* mixins */
@mixin flex-center($display: inline-flex) {
  display: $display;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@mixin bg-inline($bg-size: contain) {
  background-repeat: no-repeat;
  background-position: center;
  background-size: $bg-size;
}

/***** common *****/
/* fonts */
@import url(../../common/css/font.css);

:root {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  cursor: default;
  overflow-wrap: break-word;
  word-break: break-word;
  tab-size: 4;
}

html {
  font-size: 10px;
}
.ts-theme- {
  &body {
    margin: 0;
    background: $color-white;
    color: $body-color;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    font-family: $font-family-1;

    /* 폰트 margin제거 */
    [class*="ts-theme-fs"] {
      margin: 0;
    }
  }

  /* 시각적 숨김 */
  &vhide {
    overflow: hidden;
    position: absolute;
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
  }

  /* hide */
  &display {
    &-none {
      display: none !important;
    }
  }
  &vis {
    &-hide {
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }

  /* overflow */
  &overflow- {
    &hidden {
      overflow: hidden !important;
    }
    &visible {
      overflow: visible !important;
    }
  }

  /* bg */
  &bg {
    &-none {
      background: none !important;
    }
    &-f5f6f8 {
      background-color: #f5f6f8 !important;
    }
  }

  /* color */
  &color {
    &-000000 {
      color: #000000 !important;
    }
    &-666666 {
      color: #666666 !important;
    }
    &-999999 {
      color: #999999 !important;
    }
    &-1e75ff {
      color: #1e75ff !important;
    }
  }

  /* icon */
  &ico {
    display: inline-flex;
    @include bg-inline;
    &.ico-72 {
      width: 72px;
      height: 72px;
    }
  }

  /* align */
  &align {
    &-left {
      text-align: left !important;
    }
    &-center {
      text-align: center !important;
    }
    &-right {
      text-align: right !important;
    }
  }

  /* flex */
  &flex {
    display: flex !important;
    flex-wrap: wrap !important;
    &-1 {
      flex: 1 !important;
    }
    &-none {
      flex: none !important;
    }
    &-auto {
      flex: auto !important;
    }
    &-nowrap {
      flex-wrap: nowrap !important;
    }
  }
  &justify {
    &-start {
      justify-content: flex-start !important;
    }
    &-center {
      justify-content: center !important;
    }
    &-end {
      justify-content: flex-end !important;
    }
    &-between {
      justify-content: space-between;
    }
  }
  &items {
    &-start {
      align-items: flex-start !important;
    }
    &-center {
      align-items: center !important;
    }
    &-end {
      align-items: flex-end !important;
    }
  }

  /* width, height */
  &width {
    &-full {
      width: 100% !important;
    }
    &-auto {
      width: auto !important;
    }
  }
  &height {
    &-full {
      height: 100% !important;
    }
    &-auto {
      height: auto !important;
    }
  }

  /* margin, padding */
  &m {
    &a {
      &-96 {
        margin: 96px !important;
      }
      &-64 {
        margin: 64px !important;
      }
      &-56 {
        margin: 56px !important;
      }
      &-48 {
        margin: 48px !important;
      }
      &-40 {
        margin: 40px !important;
      }
      &-32 {
        margin: 32px !important;
      }
      &-24 {
        margin: 24px !important;
      }
      &-20 {
        margin: 20px !important;
      }
      &-16 {
        margin: 16px !important;
      }
      &-12 {
        margin: 12px !important;
      }
      &-8 {
        margin: 8px !important;
      }
      &-0 {
        margin: 0 !important;
      }
      &-auto {
        margin: auto !important;
      }
    }
    &x {
      &-96 {
        margin-right: 96px !important;
        margin-left: 96px !important;
      }
      &-64 {
        margin-right: 64px !important;
        margin-left: 64px !important;
      }
      &-56 {
        margin-right: 56px !important;
        margin-left: 56px !important;
      }
      &-48 {
        margin-right: 48px !important;
        margin-left: 48px !important;
      }
      &-40 {
        margin-right: 40px !important;
        margin-left: 40px !important;
      }
      &-32 {
        margin-right: 32px !important;
        margin-left: 32px !important;
      }
      &-24 {
        margin-right: 24px !important;
        margin-left: 24px !important;
      }
      &-20 {
        margin-right: 20px !important;
        margin-left: 20px !important;
      }
      &-16 {
        margin-right: 16px !important;
        margin-left: 16px !important;
      }
      &-12 {
        margin-right: 12px !important;
        margin-left: 12px !important;
      }
      &-8 {
        margin-right: 8px !important;
        margin-left: 8px !important;
      }
      &-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
      &-auto {
        margin-right: auto !important;
        margin-left: auto !important;
      }
    }
    &y {
      &-96 {
        margin-top: 96px !important;
        margin-bottom: 96px !important;
      }
      &-64 {
        margin-top: 64px !important;
        margin-bottom: 64px !important;
      }
      &-56 {
        margin-top: 56px !important;
        margin-bottom: 56px !important;
      }
      &-48 {
        margin-top: 48px !important;
        margin-bottom: 48px !important;
      }
      &-40 {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
      }
      &-32 {
        margin-top: 32px !important;
        margin-bottom: 32px !important;
      }
      &-24 {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
      }
      &-20 {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
      }
      &-16 {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
      }
      &-12 {
        margin-top: 12px !important;
        margin-bottom: 12px !important;
      }
      &-8 {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
      }
      &-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      &-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
      }
    }
    &t {
      &-96 {
        margin-top: 96px !important;
      }
      &-64 {
        margin-top: 64px !important;
      }
      &-56 {
        margin-top: 56px !important;
      }
      &-48 {
        margin-top: 48px !important;
      }
      &-40 {
        margin-top: 40px !important;
      }
      &-32 {
        margin-top: 32px !important;
      }
      &-24 {
        margin-top: 24px !important;
      }
      &-20 {
        margin-top: 20px !important;
      }
      &-16 {
        margin-top: 16px !important;
      }
      &-12 {
        margin-top: 12px !important;
      }
      &-8 {
        margin-top: 8px !important;
      }
      &-0 {
        margin-top: 0 !important;
      }
      &-auto {
        margin-top: auto !important;
      }
    }
    &r {
      &-96 {
        margin-right: 96px !important;
      }
      &-64 {
        margin-right: 64px !important;
      }
      &-56 {
        margin-right: 56px !important;
      }
      &-48 {
        margin-right: 48px !important;
      }
      &-40 {
        margin-right: 40px !important;
      }
      &-32 {
        margin-right: 32px !important;
      }
      &-24 {
        margin-right: 24px !important;
      }
      &-20 {
        margin-right: 20px !important;
      }
      &-16 {
        margin-right: 16px !important;
      }
      &-12 {
        margin-right: 12px !important;
      }
      &-8 {
        margin-right: 8px !important;
      }
      &-0 {
        margin-right: 0 !important;
      }
      &-auto {
        margin-right: auto !important;
      }
    }
    &b {
      &-96 {
        margin-bottom: 96px !important;
      }
      &-64 {
        margin-bottom: 64px !important;
      }
      &-56 {
        margin-bottom: 56px !important;
      }
      &-48 {
        margin-bottom: 48px !important;
      }
      &-40 {
        margin-bottom: 40px !important;
      }
      &-32 {
        margin-bottom: 32px !important;
      }
      &-24 {
        margin-bottom: 24px !important;
      }
      &-20 {
        margin-bottom: 20px !important;
      }
      &-16 {
        margin-bottom: 16px !important;
      }
      &-12 {
        margin-bottom: 12px !important;
      }
      &-8 {
        margin-bottom: 8px !important;
      }
      &-0 {
        margin-bottom: 0 !important;
      }
      &-auto {
        margin-bottom: auto !important;
      }
    }
    &l {
      &-96 {
        margin-left: 96px !important;
      }
      &-64 {
        margin-left: 64px !important;
      }
      &-56 {
        margin-left: 56px !important;
      }
      &-48 {
        margin-left: 48px !important;
      }
      &-40 {
        margin-left: 40px !important;
      }
      &-32 {
        margin-left: 32px !important;
      }
      &-24 {
        margin-left: 24px !important;
      }
      &-20 {
        margin-left: 20px !important;
      }
      &-16 {
        margin-left: 16px !important;
      }
      &-12 {
        margin-left: 12px !important;
      }
      &-8 {
        margin-left: 8px !important;
      }
      &-0 {
        margin-left: 0 !important;
      }
      &-auto {
        margin-left: auto !important;
      }
    }
  }
  &p {
    &a {
      &-96 {
        padding: 96px !important;
      }
      &-64 {
        padding: 64px !important;
      }
      &-56 {
        padding: 56px !important;
      }
      &-48 {
        padding: 48px !important;
      }
      &-40 {
        padding: 40px !important;
      }
      &-32 {
        padding: 32px !important;
      }
      &-24 {
        padding: 24px !important;
      }
      &-20 {
        padding: 20px !important;
      }
      &-16 {
        padding: 16px !important;
      }
      &-12 {
        padding: 12px !important;
      }
      &-8 {
        padding: 8px !important;
      }
      &-0 {
        padding: 0 !important;
      }
    }
    &x {
      &-96 {
        padding-right: 96px !important;
        padding-left: 96px !important;
      }
      &-64 {
        padding-right: 64px !important;
        padding-left: 64px !important;
      }
      &-56 {
        padding-right: 56px !important;
        padding-left: 56px !important;
      }
      &-48 {
        padding-right: 48px !important;
        padding-left: 48px !important;
      }
      &-40 {
        padding-right: 40px !important;
        padding-left: 40px !important;
      }
      &-32 {
        padding-right: 32px !important;
        padding-left: 32px !important;
      }
      &-24 {
        padding-right: 24px !important;
        padding-left: 24px !important;
      }
      &-20 {
        padding-right: 20px !important;
        padding-left: 20px !important;
      }
      &-16 {
        padding-right: 16px !important;
        padding-left: 16px !important;
      }
      &-12 {
        padding-right: 12px !important;
        padding-left: 12px !important;
      }
      &-8 {
        padding-right: 8px !important;
        padding-left: 8px !important;
      }
      &-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
    &y {
      &-96 {
        padding-top: 96px !important;
        padding-bottom: 96px !important;
      }
      &-64 {
        padding-top: 64px !important;
        padding-bottom: 64px !important;
      }
      &-56 {
        padding-top: 56px !important;
        padding-bottom: 56px !important;
      }
      &-48 {
        padding-top: 48px !important;
        padding-bottom: 48px !important;
      }
      &-40 {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
      }
      &-32 {
        padding-top: 32px !important;
        padding-bottom: 32px !important;
      }
      &-24 {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
      }
      &-20 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
      &-16 {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }
      &-12 {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
      }
      &-8 {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
      &-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    &t {
      &-96 {
        padding-top: 96px !important;
      }
      &-64 {
        padding-top: 64px !important;
      }
      &-56 {
        padding-top: 56px !important;
      }
      &-48 {
        padding-top: 48px !important;
      }
      &-40 {
        padding-top: 40px !important;
      }
      &-32 {
        padding-top: 32px !important;
      }
      &-24 {
        padding-top: 24px !important;
      }
      &-20 {
        padding-top: 20px !important;
      }
      &-16 {
        padding-top: 16px !important;
      }
      &-12 {
        padding-top: 12px !important;
      }
      &-8 {
        padding-top: 8px !important;
      }
      &-0 {
        padding-top: 0 !important;
      }
    }
    &r {
      &-96 {
        padding-right: 96px !important;
      }
      &-64 {
        padding-right: 64px !important;
      }
      &-56 {
        padding-right: 56px !important;
      }
      &-48 {
        padding-right: 48px !important;
      }
      &-40 {
        padding-right: 40px !important;
      }
      &-32 {
        padding-right: 32px !important;
      }
      &-24 {
        padding-right: 24px !important;
      }
      &-20 {
        padding-right: 20px !important;
      }
      &-16 {
        padding-right: 16px !important;
      }
      &-12 {
        padding-right: 12px !important;
      }
      &-8 {
        padding-right: 8px !important;
      }
      &-0 {
        padding-right: 0 !important;
      }
    }
    &b {
      &-96 {
        padding-bottom: 96px !important;
      }
      &-64 {
        padding-bottom: 64px !important;
      }
      &-56 {
        padding-bottom: 56px !important;
      }
      &-48 {
        padding-bottom: 48px !important;
      }
      &-40 {
        padding-bottom: 40px !important;
      }
      &-32 {
        padding-bottom: 32px !important;
      }
      &-24 {
        padding-bottom: 24px !important;
      }
      &-20 {
        padding-bottom: 20px !important;
      }
      &-16 {
        padding-bottom: 16px !important;
      }
      &-12 {
        padding-bottom: 12px !important;
      }
      &-8 {
        padding-bottom: 8px !important;
      }
      &-0 {
        padding-bottom: 0 !important;
      }
    }
    &l {
      &-96 {
        padding-left: 96px !important;
      }
      &-64 {
        padding-left: 64px !important;
      }
      &-56 {
        padding-left: 56px !important;
      }
      &-48 {
        padding-left: 48px !important;
      }
      &-40 {
        padding-left: 40px !important;
      }
      &-32 {
        padding-left: 32px !important;
      }
      &-24 {
        padding-left: 24px !important;
      }
      &-20 {
        padding-left: 20px !important;
      }
      &-16 {
        padding-left: 16px !important;
      }
      &-12 {
        padding-left: 12px !important;
      }
      &-8 {
        padding-left: 8px !important;
      }
      &-0 {
        padding-left: 0 !important;
      }
    }
  }

  /* font */
  &fs {
    &-48 {
      font-size: 48px !important;
      line-height: 58px !important;
    }
    &-40 {
      font-size: 40px !important;
      line-height: 48px !important;
    }
    &-32 {
      font-size: 32px !important;
      line-height: 38px !important;
    }
    &-28 {
      font-size: 28px !important;
      line-height: 34px !important;
    }
    &-24 {
      font-size: 24px !important;
      line-height: 29px !important;
    }
    &-20 {
      font-size: 20px !important;
      line-height: 30px !important;
    }
    &-18 {
      font-size: 18px !important;
      line-height: 27px !important;
    }
    &-16 {
      font-size: 16px !important;
      line-height: 24px !important;
    }
    &-14 {
      font-size: 14px !important;
      line-height: 21px !important;
    }
    &-12 {
      font-size: 12px !important;
      line-height: 18px !important;
    }
  }
  &fw {
    &-100 {
      font-weight: 100 !important;
    }
    &-300 {
      font-weight: 300 !important;
    }
    &-400 {
      font-weight: 400 !important;
    }
    &-500 {
      font-weight: 500 !important;
    }
    &-600 {
      font-weight: 600 !important;
    }
    &-700 {
      font-weight: 700 !important;
    }
    &-900 {
      font-weight: 900 !important;
    }
  }
  &ff {
    &-k {
      font-family: $font-family-2 !important;
    }
    &-p {
      font-family: $font-family-1 !important;
    }
  }
  &keep-all {
    word-break: keep-all !important;
  }

  /* hr */
  &hr-gray {
    &-8 {
      height: 8px;
      margin: 0 -24px;
      background-color: #f5f6f8;
    }
  }

  /* icon */
  &check-heart {
    width: 24px;
    height: 24px;
    margin: 0;
    box-shadow: none;
    appearance: none;
    background-color:transparent;
    @include bg-inline;
    background-image: url(../images/common/ico_heart_off.svg);
    cursor: pointer;
    &:checked {
      background-image: url(../images/common/ico_heart_on.svg);
    }
    &.ver-black {
      background-image: url(../images/common/ico_heart_off.svg);
      &:checked {
        background-image: url(../images/common/ico_heart_black.svg);
      }
    }
  }

  /* dim */
  &dim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 600;
  }

  /* js-tab */
  &js-tabcon {
    display: none;
    &.active {
      display: block;
    }
    & > .list-alarm {
      margin-top:24px;
    }
  }

  /* tooltip */
  &tooltip {
    position:relative;
    .speech-bubble {
      display:none;
      position:absolute;
      bottom:46px;
      left:50%;
      width: 256px;
      padding: 16px;
      border-radius:8px;
      box-sizing:border-box;
      color:$color-white;
      font-size:14px;
      text-align:left;
      background-color:#1f75ff;
      transform:translateX(-50%);
      &:after {
        content:"";
        display:block;
        position:absolute;
        bottom:-10px;
        left:50%;
        width: 14px;
        height:11px;
        transform:translateX(-50%);
        background: url(../images/common/ico_tooltip_tail.svg) no-repeat center center/cover; 
      }
    }
    .btn-tooltip {
      border: 0;
      padding: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../images/common/ico_tooltip.svg) no-repeat center/contain;
      border-radius: 50%;
      cursor: pointer;
      &.on{
        background-image: url(../images/common/ico_tooltip_on.svg);
      }
    }
  }
  /* loader */
  &loader {
    display: flex;
    @include flex-center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .loader-box {
      @include flex-center;
      flex-direction: column;
      width: 72px;
      height: 72px;
      border-radius: 100%;
      background-color: rgba(31, 117, 255, 0.72);
      text-align: center;
      .loader {
        position: relative;
        width: 26px;
        height: 26px;
        .box {
          position: absolute;
          border-radius: 2px;
          background-color: $color-white;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
        .box01 {
          top: 0;
          left: 0;
          width: 18px;
          height: 12px;
          animation-name: loaderBox01;
        }
        .box02 {
          top: 0;
          right: 0;
          width: 6px;
          height: 12px;
          animation-name: loaderBox02;
        }
        .box03 {
          right: 0;
          bottom: 0;
          width: 18px;
          height: 12px;
          animation-name: loaderBox03;
        }
        .box04 {
          bottom: 0;
          left: 0;
          width: 6px;
          height: 12px;
          animation-name: loaderBox04;
        }
      }
      @keyframes loaderBox01 {
        0% {
          width: 18px;
          height: 12px;
        }
        20% {
          width: 12px;
          height: 6px;
        }
        40% {
          width: 12px;
          height: 16px;
        }
        60% {
          width: 6px;
          height: 12px;
        }
        80% {
          width: 18px;
          height: 12px;
        }
        100% {
          width: 18px;
          height: 12px;
        }
      }
      @keyframes loaderBox02 {
        0% {
          width: 6px;
          height: 12px;
        }
        20% {
          width: 12px;
          height: 18px;
        }
        40% {
          width: 12px;
          height: 6px;
        }
        60% {
          width: 18px;
          height: 12px;
        }
        80% {
          width: 6px;
          height: 12px;
        }
        100% {
          width: 6px;
          height: 12px;
        }
      }
      @keyframes loaderBox03 {
        0% {
          width: 18px;
          height: 12px;
        }
        20% {
          width: 12px;
          height: 6px;
        }
        40% {
          width: 12px;
          height: 18px;
        }
        60% {
          width: 6px;
          height: 12px;
        }
        80% {
          width: 18px;
          height: 12px;
        }
        100% {
          width: 18px;
          height: 12px;
        }
      }
      @keyframes loaderBox04 {
        0% {
          width: 6px;
          height: 12px;
        }
        20% {
          width: 12px;
          height: 18px;
        }
        40% {
          width: 12px;
          height: 6px;
        }
        60% {
          width: 18px;
          height: 12px;
        }
        80% {
          width: 6px;
          height: 12px;
        }
        100% {
          width: 6px;
          height: 12px;
        }
      }
      .txt {
        margin-top: 4px;
        color: $color-white;
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
        font-family: $font-family-2;
        letter-spacing: 0.08em;
      }
    }
  }
  /* error 404 */
  &error-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 64px 0 0;
    text-align:center;
    .wrap-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 226px;
      font-family: $font-family-2;
      font-size: 121px;
      font-weight: 500;
      line-height: 153px;
      .ico-error-404 {
        display:block;
        overflow:hidden;
        width: 59px;
        height: 99px;
        font-size:0;
        text-indent: -999px;
        background: url(../images/common/ico_error_404.svg) no-repeat center center/contain;
      }
    }
    .title {
      margin: 24px 0 0 0;
      font-family: $font-family-1;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
    .text {
      margin: 20px 0 0 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  &btn-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 12;
    border-radius: 50%;
    border:0;
    box-shadow:none;
    outline:0;
    width: 48px;
    height: 48px;
    color: $color-white;
    font-family: $font-family-2;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;
    background-color: $color-black;
    opacity: 0;

    i {
      position: relative;
      display: block;
      margin: 4px 0 0 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &.on {
      opacity: 1;
    }

    &.reverse-color {
      color: $color-black;
      background-color: $color-white;
      transition:all 0.3s;
      i {
        &::before {
          border-color: $color-black;
        }
      }
    }

    &.app {
      bottom: 100px;
    }
  }

  &btn-back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 16px;
    bottom: 16px;
    z-index: 12;
    border-radius: 50%;
    border:0;
    box-shadow:none;
    outline:0;
    width: 48px;
    height: 48px;
    color: $color-white;
    font-family: $font-family-2;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;
    background-color: $color-black;
    opacity: 1;

    i {
      position: relative;
      display: block;
      margin: 4px 0 0 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-134deg);
      }
    }

    &.reverse-color {
      color: $color-black;
      background-color: $color-white;
      transition:all 0.3s;
      i {
        &::before {
          border-color: $color-black;
        }
      }
    }

    &.app {
      bottom: 100px;
    }
  }

  /* import */
  @import "./ts-guide"; //가이드구조용
  @import "./ts-layout";
  @import "./ts-form";
  @import "./ts-button";
  @import "./ts-board";
  @import "./ts-table";
  @import "./ts-popup";
  @import "./ts-member";
  @import "./ts-main";
  @import "./ts-app-main";
  @import "./ts-gate";
  @import "./ts-error-503";
  @import "./sub/ts-sub";
}
/* main  */
@media (max-width: 850px) {
  .ts-theme-main .sec05 .cont {
    background-color:$color-white;
  }
  .ts-theme-main .sec05 .cont .card-content {
    margin:0 auto 80px;
  }

}
/* 경방 100년사 TS_UX_1008  */
@media (max-width: 689px) {
  .ts-theme-subpage-history-tab .tab-container {
    justify-content: flex-start;    
  }
  .ts-theme-subpage-history-content {
    flex-direction: column;
  }
  .ts-theme-subpage-history-content .pin-spacer {
    width:auto !important;
  }
  .ts-theme-subpage-history-content .history-view {
    width:auto !important;
    height:auto !important;
  }
}
/* clay.css 내 alert 위치 수정 요청 반영 */
html:not(#__):not(#___) .alert-container.cadmin.container .alert-notifications.alert-notifications-fixed {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.alert-notifications.alert-notifications-fixed .alert {width:80vw;}
.alert-notifications .alert .lead {display:block;}

/* 임시 내 차 주차 관리 */
#portlet_kr_co_timessquare_myPage_web_portlet_ParkingPortlet {
  position:static;
}
/* 임시 mobile main */
.ts-theme-body .portlet-layout .portlet-header {
  position:absolute;
  z-index:0;
}
.ts-theme-body.has-control-menu .portlet-layout .portlet-header {
  position:static;
}
._kr_co_timessquare_myPage_web_portlet_MyInfoPortlet_otherInfoStep.step4{
  & + .step4{
    display:none;
  }
}
body.ts-mobile-body .ts-theme-layer-pop.ts-mobile-block {
  display:flex;
}
/* 임시 mobile main */
.ts-app-body {
  .btn-up {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    border-radius: 50%;
    border:0;
    box-shadow:none;
    outline:0;
    width: 48px;
    height: 48px;
    color: $color-white;
    font-family: $font-family-2;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    transition: opacity 0.3s;
    background-color: $color-black;
    opacity: 0;

    i {
      position: relative;
      display: block;
      margin: 4px 0 0 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &.on {
      opacity: 1;
    }

    &.app {
      bottom: 100px;
    }
  }
}

/* 즐겨찾기 */
#portlet_kr_co_timessquare_myPage_web_portlet_FavoritePortlet {
  .ts-theme-tabbox.left {
    margin:56px 0 0;
  }
  .ts-theme-subpage-floor-title {
    margin-top:0;
  }
  .ts-theme-bo-image-list {
    margin:56px 0 0 !important;
  }
  #_kr_co_timessquare_myPage_web_portlet_FavoritePortlet_readMoreWrapper{
    .ts-theme-btnstyle {
      height:48px;
      color:#fff;
      font-family: $font-family-2;
      font-size:16px;
    }
  }
}

#_kr_co_timessquare_store_web_portlet_StorePortlet_reservation .step1 .ts-theme-form{
  margin-bottom: 104px;
}