&map-box {
    margin: 0 -24px;
    height:366px;
    border: 1px solid #eaeaea;
    overflow: hidden;
}
&map-info {
    margin:24px 0 0 0;
    .box-info {
        display: flex;
        align-items: center;
        .info-tit {
            margin:0;
            padding:0;
            width:calc(100% - 24px);
            font-size:20px;
            font-weight:700;
            line-height:30px;
        }
        .btn-copy {
            display:block;
            padding:0;
            border:0;
            width:24px;
            height:24px;
            background:transparent url(../images/common/ico_copy.svg) no-repeat center center/contain;
        }
    }
    .btns {
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        width:calc(100% + 8px);
        margin:28px -4px 0 -4px;
        .map-btn {
            display:flex;
            align-items: center;
            margin:4px;
            padding: 7px 15px;
            border:1px solid $color-black;
            border-radius:24px;
            color:$color-black;
            text-decoration: none;
            background-color:transparent;
            .txt {
                color:$color-black;
                font-family: $font-family-2;
                font-size: 16px;
                font-weight:500;
                line-height: 24px;
            }
            [class^="ico"] {
                display:block;
                margin:0 0 0 4px;
                width:24px;
                height:24px;
                background-repeat:no-repeat;
                background-position:center center;
                background-size:24px 24px;
            }
        }
    }
}