/* sub layout */
&subpage- {
  &title {
    text-align: center;

    .txt-ts {
      position: relative;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      opacity: 0.64;
      letter-spacing: 0.06em;
      font-family: $font-family-2;
      z-index: 1;
    }

    .txt-title {  
      position: relative;
      margin: 8px 0 0 0;
      font-family: $font-family-2;
      font-size: 48px;
      line-height: 58px;
      font-weight: 700;
      letter-spacing: -0.033em;
      z-index: 1;
    }

    &.event {
      position: relative;
      margin: calc(-80px - 56px) -24px 0; //header + padding-top
      padding-top: 137px;
      padding-bottom: calc(100% - 36px); //margin -24이유로 calc사용 불필요
      background: #ef9d2e;
      color: $color-white;
      box-sizing: border-box;

      .event-img-slide {
        position: absolute;
        right: 0;
        bottom: -80px;
        left: 0;
        padding-bottom: calc(100% - 48px);
        overflow: hidden;

        .swiper-container {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 0 18px;
          overflow: visible;

          .swiper-wrapper {
            padding: 0;
            margin: 0;
            list-style: none;

            .swiper-slide {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              height: auto;

              &.swiper-slide-active {
                .inbox {
                  padding-bottom: calc(100% - 12px);

                  .txtbox {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }

              .inbox {
                display: block;
                position: relative;
                height: 0;
                padding-bottom: calc(78% - 12px);
                margin: 0 6px;
                color: inherit;
                text-decoration: none;
                transition: 0.3s;

                .imgbox {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    vertical-align: top;
                  }
                }

                .txtbox {
                  display: none;
                  // display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  background-color: rgba(0, 0, 0, 0.64);
                  visibility: hidden;
                  opacity: 0;
                  transition: 0.6s;

                  .tit {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 700;
                  }

                  .info {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 24px 0 0 0;
                    list-style: none;

                    > li {
                      position: relative;
                      font-size: 16px;
                      line-height: 1px;
                      font-weight: 500;
                      font-family: $font-family-2;

                      + li {
                        margin-left: 8px;
                        padding-left: 9px;

                        &::before {
                          content: "";
                          position: absolute;
                          top: 50%;
                          left: 0;
                          width: 1px;
                          height: 12px;
                          background-color: $color-white;
                          transform: translateY(-50%);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      & + .ts-theme-subpage-content {
        .ts-theme-tabbox {
          margin-top:140px;
        }
        .ts-theme-bo-no-content {
          padding:120px 0;
          border-top:2px solid $color-black;
          border-bottom:1px solid #eaeaea;
        }
      }

      & + .ts-theme-tabbox {
        margin-top:140px;
      }
      & + .ts-theme-tabbox + .ts-theme-bo-image-list {
       .ts-theme-bo-no-content {
        padding:120px 0;
        border-top:2px solid $color-black;
        border-bottom:1px solid #eaeaea;
       }
      }
    }

    &.floor {
      position: relative;
      margin: calc(-80px - 56px) -24px 0; //header + padding-top
      padding-top: 137px;
      padding-bottom: 235px; //margin -24이유로 calc사용 불필요
      color: $color-white;
      box-sizing: border-box;
      .bg {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 0;
      }
      .floor-img-slide {
        display: flex;
        align-items: flex-end;
        position: absolute;
        top:259px;
        width: 100%;
        overflow: hidden;

        .img-item-group {
          display: flex;
          align-items: flex-end;

          .img-item {
            position: relative;
            width: 327px;
            margin-right: 12px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.2);
            }

            img {
              width: 100%;
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  &floor-title {
    display: flex;
    align-items: flex-end;
    margin-top: 116px;

    .title {
      margin: 0;
      font-size: 32px;
      line-height: 39px;
      font-weight: 700;
      letter-spacing: 0.015em;
      font-family: $font-family-2;
    }

    .title-select {
      flex: none;
      height: 43px;
      padding: 0 34px 0 0;
      border: none;
      background: url(../images/common/ico_floor_tit_arrow.svg) no-repeat right bottom/32px;
      color: $color-black;
      font-size: 32px;
      font-weight: 700;
      font-family: $font-family-2;
      appearance: none;
      & + .title {
        margin-left:auto;
      }
    }

    .btn-location {
      margin: 0 0 2px auto;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      @include bg-inline;
    }
  }

  &floor-tab {
    display: block;
    padding: 0;
    margin: 22px -24px 0;
    padding: 0 24px;
    list-style: none;
    overflow: auto;
    white-space: nowrap;

    li {
      display: inline-flex;
      margin-right: 8px;

      a {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 0 15px;
        border: 1px solid $color-black;
        color: inherit;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;

        .ico-heart {
          width: 24px;
          height: 24px;
          background: url("../images/common/ico_heart_black.svg") no-repeat center/contain;
        }

        &.active {
          background-color: $color-black;
          color: $color-white;
          .ico-heart {
            background-image:url("../images/common/ico_heart_white.svg");
          }
        }
      }
    }

    &.rounded {
      a {
        border-color: transparent;
        font-size: 16px;
        
        &.active {
          border-radius: 12px;
          background-color: $color-p;
          color: $color-white;
        }
      }
    }
    &::-webkit-scrollbar {
      display:none;
    }
    & + .ts-theme-bo-row-list {
      border-width:1px;
      li {
        border-color: $color-black;
      }
    }
  }

  &con-title {
    display: flex;
    align-items: flex-end;

    * {
      text-decoration: none;
    }
    & + .ts-theme-bo-coupon-list {
      margin:20px 0 0 0;
    }
  }

  &magazine {
    display: block;
    margin: 0 -24px;
    padding: 64px 24px;
    box-sizing: border-box;
    width: calc(100% + 48px);
    background-color: #8021ff;

    .link {
      display: block;
      color: $color-white;
      text-decoration: none;
      text-align: center;

      .date {
        font-family: $font-family-2;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .tit {
        margin: 12px 0 0 0;
        font-family: $font-family-2;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
      }

      img {
        display: block;
        width: 100%;
        margin: 48px auto 0;
      }
    }
  }

  &content{
    padding:0 0 64px;
    box-sizing:border-box;
    &.pdno {
      padding:0;
    }
  }
}

/* text */
&title {
  &-18 {
    margin: 56px 0 0 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
  }

  &-16 {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}

&text {
  &-16 {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: -0.02em;
    word-break: keep-all;
  }

  &-list {
    &-dot {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        position: relative;
        padding-left: 6px;
        color: #666;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;

        & + li {
          margin-top: 4px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 10px;
          left: 0;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: #666;
        }
      }
      
      .ts-emphasis {
        color:#fc1b1b;
        font-weight:300;
        &:before {
          background-color:#fc1b1b;
        }
      }
    }
  }

  &-bd-box {
    padding: 16px;
    border: 1px solid $color-black;
    color: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;

    p {
      margin: 0;
    }
  }
}

&ico-title {
  display: flex;
  align-items: flex-start;

  &-btn {
    display: flex;
    align-items: center;
    padding: 20px 40px 20px 20px;
    border: none;
    border-radius: 8px;
    width:100%;
    background: url(../images/common/ico_arrow_r.svg) no-repeat right 16px center/20px;
    background-color: #f5f6f8;
    font-family: $font-family-2;

    .ico {
      flex: none;
      display: inline-flex;
      margin-right: 8px;
    }

    .title {
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
    }

    .desc {
      display: inline-flex;
      margin: 0 0 0 16px;
      color: #666;
      font-size: 12px;
      font-weight: 400;
      line-height: 21px;

      strong {
        margin-left: 4px;
        color: $color-p;
        font-weight: 700;
      }
    }
  }

  .ico {
    flex: none;
    display: inline-flex;
    margin: 2px 4px 0 0;
  }

  .title {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }

  & + .ts-theme-sub-text {
    margin:16px 0 0 0;
    color:#666;
    font-size:14px;
    line-height:21px;
    .ts-emphasis {
      color:#fc1b1b;
    }
  }
}

&dl-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  font-size: 14px;
  line-height: 16px;

  &.in-pop {
    padding: 24px 8px;
    border-top: 1px solid #f5f6f8;
    border-bottom: 1px solid #f5f6f8;

    dt {
      text-align: left;
    }
  }

  dt {
    flex: none;
    width: 96px;
    margin: 12px 0 0 0;
    font-weight: 300;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  dd {
    width: calc(100% - 96px);
    margin: 12px 0 0 0;
    padding-left: 12px;
    text-align: right;
    font-weight: 600;
    box-sizing: border-box;

    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

&dl-left {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width:100%;
  font-size: 14px;
  line-height: 16px;
  text-align: left;

  dt {
    flex: none;
    width: 72px;
    margin: 16px 0 0 0;
    color: #666;
    font-weight: 300;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  dd {
    width: calc(100% - 96px);
    margin: 16px 0 0 0;
    padding-left: 16px;
    font-weight: 700;
    box-sizing: border-box;

    &:nth-child(2) {
      margin-top: 0;
    }

    .desc {
      width: 100%;
      margin: 4px 0 0 0;
      color: #999;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
    }

    .btn-coupon-black {
      margin-top:-3px;
      padding: 4px 24px 4px 8px;
      border: none;
      border-radius: 16px;
      background: url(../images/common/ico_12_arrow_white.svg) no-repeat right 8px center/12px;
      background-color: $color-black;
      color: $color-white;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      font-family: $font-family-2;
      &.done {
        padding:4px 8px;
        background: #808080;
      }

    }
  }

  & + .ts-theme-dl-left {
    margin-top:28px;
  }
}

&find-car {
  margin:32px 0 0 0;
  padding:20px;
  box-sizing:border-box;
  background-color:#f5f6f8;

  & + .ts-theme-find-car {
    margin: 8px 0 0 0;
  }
}

/* 나의줄서기/예약 */
&res-list {
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    & + li {
      margin-top: 20px;
    }

    > .inbox {
      display: flex;
      flex-wrap: wrap;
      padding: 24px;
      border: 1px solid #eaeaea;
      color: inherit;
      text-decoration: none;

      > .imgbox {
        flex: none;
        align-self: flex-start;
        position: relative;
        width: 104px;
        height: 104px;
        margin-right: 16px;

        .img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @include bg-inline;
        }
      }

      > .txtbox {
        flex: 1;
        display: flex;
        flex-direction: column;

        .f {
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;
        }

        .tit {
          margin: 6px 0;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          word-break: keep-all;
        }

        .state {
          display: flex;
          flex-wrap: wrap;
          margin-top: auto;

          [class*="state"] {
            position: relative;
            padding-left: 8px;
            margin-right: 16px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 300;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              width: 4px;
              height: 4px;
              transform: translateY(-50%);
            }
          }

          .state01::before {
            background-color: #34c7ab;
          }

          .state02::before {
            background-color: #f72828;
          }
        }
      }
    }

    > .res-infobox {
      padding: 24px;
      border: 1px solid #eaeaea;
      background-color: $color-white;

      &.disabled {
        background-color: #f8f8f8;

        .ri-state {
          > li {
            border-color: #d9d9d9;
            color: #999;
          }
        }

        .ri-tit {
          .tit {
            color: #999;
          }

          .btn-tel {
            background-image: url(../images/common/ico_phone_off.svg);
          }
        }

        .ri-tf {
          > li {
            color: #999;

            &::before {
              content: "";
              background-color: #999;
            }
          }
        }

        .ri-info {
          > li {
            color: #999;

            &::before {
              background-color: #999;
            }
          }
        }
      }

      .ri-state {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        > li {
          margin-right: 4px;
          padding: 0 7px;
          border: 1px solid $color-black;
          background-color: $color-white;
          font-size: 12px;
          line-height: 24px;
          font-weight: 700;
        }
      }

      .ri-tit {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;

        .tit {
          max-width: calc(100% - 32px);
          margin: 0 8px 0 0;
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
        }

        .btn-tel {
          flex: none;
          width: 24px;
          height: 24px;
          background: url(../images/common/ico_phone.svg) no-repeat center/contain;
        }
      }

      .ri-tf {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 0 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
          margin-right: 8px;
          padding-right: 9px;
          color: #666;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 12px;
            background-color: #d9d9d9;
            transform: translateY(-50%);
          }

          &:last-child {
            padding-right: 0;
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      .ri-info {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
          margin-right: 8px;
          padding-right: 9px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 12px;
            background-color: #d9d9d9;
            transform: translateY(-50%);
          }

          &:last-child {
            padding-right: 0;
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      .ri-note {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;
        padding: 8px;
        background-color: #f5f6f8;

        .ico {
          flex: none;
          width: 24px;
          height: 24px;

          img {
            width: inherit;
            height: inherit;
          }
        }

        .txt {
          flex: 1;
          margin: 4px 8px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;

          strong {
            color: $color-p;
            font-weight: 600;
          }
        }

        .btn-img {
          flex: none;
          padding: 0;
          margin-left: auto;
          border: none;
          background: none;
          appearance: none;
          width: 24px;
          height: 24px;

          img {
            width: inherit;
            height: inherit;
          }
        }
      }
    }

    .res-rcmd {
      margin: 0;
      padding: 24px;
      background: #f5f6f8;
      list-style: none;

      > li {
        & + li {
          margin-top: 16px;
        }

        > .inbox {
          display: flex;
          flex-wrap: wrap;
          color: inherit;
          text-decoration: none;

          > .imgbox {
            flex: none;
            align-self: flex-start;
            position: relative;
            width: 80px;
            height: 80px;
            margin-right: 16px;

            .img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              @include bg-inline;
            }
          }

          > .txtbox {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .tit {
              margin: 0;
              font-size: 16px;
              line-height: 19px;
              font-weight: 700;
            }

            .tf {
              display: flex;
              flex-wrap: wrap;
              margin: 8px 0 0 0;
              padding: 0;
              list-style: none;

              > li {
                position: relative;
                margin-right: 8px;
                padding-right: 9px;
                color: #666;
                font-size: 14px;
                line-height: 16px;
                font-weight: 300;

                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: 0;
                  width: 1px;
                  height: 12px;
                  background-color: #d9d9d9;
                  transform: translateY(-50%);
                }

                &:last-child {
                  padding-right: 0;
                  margin-right: 0;

                  &::before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ts-theme-no-content {
      border-top: 2px solid $color-black;
    }
  }

  &-bo {
    padding: 0;
    margin: 0;
    border-top: 2px solid $color-black;
    list-style: none;
    > li {
      border-bottom: 1px solid #eaeaea;
      > .inbox {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 0;
        color: inherit;
        font-family: $font-family-2;
        text-decoration: none;
        > .imgbox {
          flex: none;
          align-self: flex-start;
          position: relative;
          width: 104px;
          height: 104px;
          margin-right: 16px;
          .img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            @include bg-inline;
          }
        }
        > .txtbox {
          flex: 1;
          display: flex;
          flex-direction: column;
          .f {
            font-size: 14px;
            line-height: 16px;
            font-weight: 300;
          }
          .tit {
            margin: 6px 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            word-break: keep-all;
          }
          .state {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: auto;
            span {
              margin-right: 4px;
              padding: 5px 7px;
              border: 1px solid $color-black;
              color: $color-black;
              font-size: 12px;
              line-height: 14px;
              font-weight: 700;
              &.disabled {
                border-color: #d9d9d9;
                background-color: #f8f8f8;
                color: #999;
              }
            }
          }
        }
      }
    }
  }
}

&store-imgbox {
  display: flex;
  flex-direction: column;
  height: 320px;
  margin: calc(-80px - 56px) -24px 0; //header + padding-top
  padding: 32px 24px;
  box-sizing: border-box;
  @include bg-inline(cover);

  .store-info {
    margin-top: auto;
    color: $color-white;

    .si-tag {
      display: flex;
      flex-wrap: wrap;
      margin: -4px 0 0 -4px;
      padding: 0;
      list-style: none;

      > li {
        position: relative;
        margin: 4px 0 0 4px;
        padding: 0 11px;
        border: 1px solid $color-white;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.45);
        color: $color-white;
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
      }
    }

    .si-tit {
      display: flex;
      align-items: flex-start;
      margin-top: 12px;

      .tit {
        margin: 0 12px 0 0;
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
      }

      .btn-tel {
        width: 24px;
        height: 24px;
        margin: 5px 0 5px auto;
        background: url(../images/common/ico_phone_white.svg) no-repeat center/contain;
      }
    }

    .si-tf {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 0 0 0;
      padding: 0;
      list-style: none;

      > li {
        position: relative;
        margin-right: 8px;
        padding-right: 9px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 300;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 12px;
          background-color: $color-white;
          transform: translateY(-50%);
        }

        &:last-child {
          padding-right: 0;
          margin-right: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

&white-section {
  margin: 0 -24px;
  padding: 40px 24px;
  background: $color-white;

  & + [class*="white-section"] {
    border-top: 8px solid #f8f8f8;
  }

  /* 예약상세 */
  .my-resbox {
    .mr-wtng {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        margin-right: 4px;
        padding: 0 7px;
        border: 1px solid $color-black;
        background-color: $color-white;
        font-size: 14px;
        line-height: 26px;
        font-weight: 600;
      }
    }

    .mr-info {
      display: inline-flex;
      padding: 5px 7px;
      margin: 0;
      border: 1px solid $color-black;
      font-family: $font-family-2;
      font-weight: 600;
      list-style: none;

      > li {
        position: relative;

        + li {
          margin-left: 8px;
          padding-left: 9px;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 1px;
            height: 8px;
            background-color: #d9d9d9;
            transform: translateY(-50%);
          }
        }
      }
    }

    .mr-note {
      display: flex;
      align-items: flex-start;
      margin-top: 16px;

      .txt {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;

        strong {
          color: $color-p;
          font-weight: 700;
        }
      }

      .btn-img {
        flex: none;
        padding: 0;
        margin-left: 12px;
        border: none;
        background: none;
        appearance: none;
        width: 24px;
        height: 24px;

        img {
          width: inherit;
          height: inherit;
        }
      }
    }

    .mr-txt {
      margin: 8px 0 0 0;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
    }

    .mr-graybox {
      margin-top: 20px;
      padding: 12px;
      background-color: #f5f6f8;
      color: #666;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      text-align: center;
    }
  }

  .sec- {
    &title {
      display: flex;
      margin-bottom: 16px;

      & ~ .sec-title {
        margin-top: 56px;
      }

      .ico {
        flex: none;
        width: 20px;
        height: 20px;
        margin: 2px 8px 2px 0;

        img {
          width: inherit;
          height: inherit;
          object-fit: contain;
        }
      }

      .title {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    &text {
      margin: 0;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
    }

    &map {
      height: 160px;
      background-color: #f5f6f8;
    }

    &text-list {
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        position: relative;
        padding-left: 14px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;

        & + li {
          margin-top: 8px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 10px;
          left: 0;
          width: 4px;
          height: 4px;
          background-color: $color-black;
          transform: rotate(45deg);
        }
      }
    }
  }
}

/* 날짜&시간선택 */
&datetime {
  display: flex;
  flex-direction: column;
  margin: calc(-80px - 56px) -28px 0; //header + padding-top

  .box-date {
    padding: calc(80px + 56px) 24px 20px;
    border-radius: 0 0 16px 16px;
    background-color: #f5f6f8;

    .yymm-ctrl {
      @include flex-center(flex);

      .btn-prev {
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 50%;
        background: url(../images/common/btn_prev_arrow.svg) no-repeat center/contain;
        background-color: transparent;
      }

      .btn-next {
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 50%;
        background: url(../images/common/btn_next_arrow.svg) no-repeat center/contain;
        background-color: transparent;
      }

      .yymm {
        margin: 0 24px;
        font-size: 28px;
        line-height: 33px;
        font-family: $font-family-2;
        font-weight: 700;
      }
    }

    .calendar {
      margin-top: 48px;

      table {
        width: 100%;
        table-layout: fixed;
        border-spacing: 0;
        font-family: $font-family-2;

        &.active-table {
          padding-bottom: 12px;

          tbody {
            tr {
              td {
                .day {
                  padding-top: 4px;

                  &::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 68px;
                    border: 1px solid transparent;
                    border-radius: inherit;
                    box-sizing: border-box;
                  }

                  &.active {
                    &::before {
                      border-color: $color-black;
                    }
                  }
                }
              }
            }
          }
        }

        thead {
          tr {
            th {
              padding: 0 0 4px 0;
              color: #666;
              font-size: 14px;
              line-height: 17px;
              font-weight: 300;
              text-align: center;

              &.sun {
                color: #f33;
              }

              &.sat {
                color: #1c6be8;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 2px;
              font-size: 16px;
              line-height: 19px;
              font-weight: 500;
              text-align: center;

              .day {
                position: relative;
                max-width: 44px;
                margin: 0 auto;
                padding: 12px 0;
                border-radius: 27px;

                &.poss {
                  background-color: #1e75ff;
                  color:$color-white;
                }

                &::before {
                  content: "";
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  height: 43px;
                  border: 1px solid transparent;
                  border-radius: inherit;
                  box-sizing: border-box;
                }

                &.active {
                  color: #1e75ff;
                  &::before {
                    border-color: #1e75ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .box-time {
    padding: 40px 24px;

    .bt-title {
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;

      & ~ .bt-title {
        margin-top: 48px;
      }

      & + .bt-stit {
        margin-top: 24px;
      }
    }

    .bt-stit {
      margin: 32px 0 8px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }

    .time-radio {
      display: flex;
      flex-wrap: wrap;
      margin: -4px 0 0 -4px;

      > label {
        margin: 4px 0 0 4px;

        > input {
          display: none;

          &:checked + .txt {
            background-color: $color-black;
            color: $color-white;
          }

          &:disabled + .txt {
            border-color: #f8f8f8;
            background-color: #f8f8f8;
            color: #d9d9d9;
          }
        }

        > .txt {
          display: inline-flex;
          padding: 11px 15px;
          border: 1px solid $color-black;
          border-radius: 8px;
          background-color: $color-white;
          font-family: $font-family-2;
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
        }
      }
    }
  }
}

/* 주차관리 */
&parking-box {
  padding: 20px 16px;
  border: 1px solid #eaeaea;
  border-radius: 8px;

  .ico-title {
    display: flex;
    align-items: center;

    .ico {
      display: inline-flex;
      flex: none;
      margin-right: 8px;
    }

    .title {
      margin: 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
    }
  }

  .car-numbox {
    display:flex;
    align-items:center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 16px 0;
    box-sizing: border-box;
    text-align:center;
    &.none {
      border: 1px dashed #d9d9d9;
      border-radius: 8px;
      background-color: #fafafa;
      color: #999;
      text-align: center;

      .none-tit {
        width: 100%;
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
      }

      .none-txt {
        width: 100%;
        margin: 8px 0 0 0;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
    }

    .car-num {
      padding: 7px 15px;
      border: 1px solid $color-black;
      border-radius: 8px;
      background-color: #fafafa;
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      & + .car-num {
        margin-top: 8px;
      }
    }
  }
}

&find-result {
  margin-top: 32px;
  border-top: 1px solid #f5f6f8;

  .mycar-list {
    display: flex;
    flex-wrap: wrap;
    margin: 12px -4px 0;
    padding: 0;
    list-style: none;

    > li {
      flex: 0 0 50%;
      padding: 4px;
      box-sizing: border-box;

      > a {
        display: block;
        height: 48px;
        border-radius: 8px;
        background-color: #f5f6f8;
        color: $color-black;
        font-size: 16px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
      }
    }
  }

  .ts-theme-no-content {
    margin-top: -1px;
    padding: 96px 0;
    border-top: 2px solid $color-black;
    border-bottom: 1px solid #ccc;
  }
}

/* 주차쿠폰 */
&parking-coupon-list {
  padding: 0;
  margin: 0;
  list-style: none;

  > li {
    position: relative;
    border-radius: 16px;
    
    + li {
      margin-top: 20px;
    }

    .txtbox {
      position: relative;
      padding: 32px;
      border: 1px solid #eaeaea;
      border-bottom: none;
      border-radius: 16px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 12px;
        height: 24px;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: #eaeaea;
        border-radius: 0 12px 12px 0;
        background-color: $color-white;
        box-sizing: border-box;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -12px;
        width: 12px;
        height: 24px;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: #eaeaea;
        border-radius: 12px 0 0 12px;
        background-color: $color-white;
        box-sizing: border-box;
      }

      .tit {
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }

      .date {
        margin: 8px 0 0 0;
        color: #999;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
      }
    }

    .btnbox {
      border: 1px solid #eaeaea;
      border-top: none;
      border-radius: 16px;
      overflow: hidden;

      .btn-coupon {
        width: 100%;
        height: 64px;
        padding: 0;
        border: none;
        background-color: $color-p;
        color: $color-white;
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        font-family: $font-family-1;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}

/* 차량 번호 등록 및 변경 */
&carnum-type {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 8px;

  > li {
    flex: 0 0 calc(50% - 4px);

    > .carnum-label {
      @include flex-center(flex);
      flex-direction: column;
      position: relative;
      padding: 24px;
      width: inherit;
      height: inherit;
      border-radius: 8px;
      box-sizing: border-box;

      input {
        position: relative;
        width: 24px;
        height: 24px;
        margin: 0;
        z-index: 1;
        appearance: none;
        cursor: pointer;

        &[type="radio"] {
          position: relative;
          width: 24px;
          height: 24px;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
          background-color: $color-white;

          &:checked {
            border-color: $color-p;
            background-color: $color-p;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              transform: translate(-50%, -50%);
            }

            &:disabled {
              border-color: #d9d9d9;
              background: #f8f8f8;

              &::before {
                background-color: #d9d9d9;
              }
            }
          }

          &:disabled {
            background: #f8f8f8;
          }
        }

        &:checked + .txt {
          &::before {
            border-color: $color-p;
            background-color: rgba(30, 117, 255, 0.08);
          }
        }

        &:disabled + .txt {
          color: #999;
        }
      }

      .txt {
        margin-top: 9px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 1px solid #eaeaea;
          border-radius: 8px;
        }
      }

      .con {
        @include flex-center(flex);
        height: 40px;
        margin-top: 12px;

        .carnum {
          min-width: 80px;
          position: relative;
          padding: 5px;
          border: 1px solid $color-black;
          border-radius: 4px;
          background-color: $color-white;
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
          text-align: center;
          box-sizing: border-box;

          &.bg-green {
            border-color: #64c067;
            background-color: #64c067;
            color: $color-white;

            .area {
              display: block;
              font-size: 10px;
              line-height: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

&carnum-confirm {
  @include flex-center(flex);
  flex-direction: column;
  padding: 32px 24px;
  background-color: #f5f6f8;
  text-align: center;

  .ico {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  .txt {
    margin-top: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }

  .carnum {
    width: 100%;
    padding: 24px;
    margin-top: 16px;
    border: 1px solid $color-black;
    background-color: $color-white;
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    text-align: center;
    box-sizing: border-box;

    &.bg-green {
      border-color: #64c067;
      background-color: #64c067;
      color: $color-white;

      .area {
        display: block;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}

&box-app-layer {
  position: relative;
  .app-layer {
    display: none;
    position: absolute;
    bottom: 65px;
    left: 50%;
    width: 92%;
    max-width: 296px;
    transform: translateX(-50%);

    img {
      width: 100%;
      vertical-align: top;
    }
  }
  & + .ts-theme-box-app-layer {
    margin-top: 20px;
  } 
}

/* 보유한 쿠폰 */
&coupon-item {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  min-height: 276px;
  border-radius: 16px;

  &::before {
    content: "";
    position: absolute;
    left: -12px;
    bottom: 51px;
    width: 24px;
    height: 28px;
    background: url(../images/common/bg_coupon_round_left.png) no-repeat center / contain;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    right: -12px;
    bottom: 51px;
    width: 24px;
    height: 28px;
    background: url(../images/common/bg_coupon_round_right.png) no-repeat center / contain;
    z-index: 1;
  }

  .titbox {
    position: relative;
    flex-grow: 1;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    background-color: $color-white;
    border-radius: 16px 16px 0 0;
    padding: 35px 33px 38px;

    .badge {
      display: inline-block;
      margin-bottom: 16px;
      padding: 4px 8px;
      border-radius: 16px;
      color: $color-white;
      font-family: $font-family-2;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      background-color: $color-black;
    }

    .tit {
      display: block;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    .desc {
      margin-top: 8px;
      display: block;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
    }

    .date {
      display: block;
      margin-top: 24px;
      color: #999999;
      font-family: $font-family-2;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .downloadbox {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-top: none;
    border-radius: 0 0 16px 16px;
    height: 63px;
    text-decoration: none;
    background-color: #f5f6f8;

    .copy {
      display: inline-block;
      padding-right: 20px;
      font-size: 14px;
      color: #000;
      font-weight: 800;
      line-height: 24px;
    }
  }

  > .done {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.64);
    z-index: 9;

    .icon-done {
      display: inline-block;
      width: 116px;
      height: 115px;
      background: url(../images/common/bg_coupon_done.svg) no-repeat center/contain;

      > em {
        padding-top: 61px;
        display: block;
        text-align: center;
        font-style: normal;
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .btn-tooltip {
    display: block;
    position: absolute;
    bottom: 0;
    right: 50%;
    padding: 32px 4px;
    border: 0;
    border-radius: 50%;
    width: 24px;
    height: 16px;
    transform: translateX(58px);
    background: url(../images/common/ico_tooltip.svg) no-repeat center center;
    background-size: 16px 16px;

    &.on {
      background-image: url(../images/common/ico_tooltip_on.svg);
    }
  }
}

/* 안내글 */
&msgbox {
  padding: 16px;
  background-color: #f5f6f8;

  .msg-info {
    display: block;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: $color-black;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      margin-right: 4px;
      width: 14px;
      height: 14px;
      background: url(../images/common/ico_14_info_black.svg) no-repeat center/contain;
    }

    & + .list-desc {
      margin-top: 16px;
    }
  }

  .list-desc {
    margin: 0;
    padding: 0;

    > li {
      padding-left: 6px;
      position: relative;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      word-break: keep-all;
      list-style: none;

      &::before {
        content: "";
        display: block;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: #666666;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }

    > li + li {
      margin-top: 8px;
    }

    &.black {
      li {
        color: $color-black;

        &::before {
          background-color: $color-black;
        }
      }
    }
  }

  &.noBg {
    padding: 0;
    background-color: transparent;
  }

  &.line {
    border: 1px solid #EAEAEA;
    background-color: transparent;
  }
}

/* 개인정보처리방침 */
&policy {
  font-family: $font-family-1;
  font-size: 14px;

  .ts-theme-form {
    margin: 62px 0 0 0;
    text-align: right;

    .selectstyle {
      padding: 18px 48px 17px 0;
      border-width: 1px;
      border-color: transparent transparent $color-black transparent;
      color: $color-black;
      font-family: $font-family-1;
      font-size: 14px;
      font-weight: 500;
      background-position: right center;
    }

    & + .ts-theme-form-contents {
      margin: 40px 0 0 0;
    }
  }

  .item {
    & + .item {
      margin: 56px 0 0 0;
    }
  }

  h3 {
    margin:0;
    padding:0;
    font-size:20px;
    font-weight:700;
    & + ol {
      margin-top:12px;
    }
  }

  ul {
    margin:0;
    padding:0;
    li {
      list-style: none;
      font-size: 14px;
    }
    & + h3 {
      margin-top: 56px;
    }
  }

  ol {
    margin:0;
    padding:0;
    li {
      list-style: none;
      font-size: 14px;
      & + li {
        margin-top: 8px;
      }
    }
    & > li {
      & > ol {
        margin-top:4px;
        padding-left:16px;
        & > li {
          & + li {
            margin-top:4px;
          }
        }
      }
  
      & > ul {
        margin-top:4px;
        padding-left:16px;
        & > li {
          & + li {
            margin-top:4px;
          }
        }
      }
    }

    & + p {
      margin-top:12px;
    }

    & + h3 {
      margin-top:56px;
    }
  }

  p {
    margin:0;
    padding:0;
    font-size:14px;
    & + p {
      margin-top:4px;
    }
    & + ul {
      margin-top:4px;
    }
    & + ol {
      margin-top:4px;
    }
    &+ table {
      margin-top:4px;
    }
  }

  table {
    border-style:solid;
    border-color:#000 transparent #ddd transparent;
    border-width:1px 0 1px 0;
    border-collapse:collapse;
    border-spacing:0;
    width:100%;
    thead {
      tr {
        th {
          text-align: center; 
          padding: 26px 8px 24px; 
          font-weight: 700; 
          background-color: #f5f6f8; 
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 24px 8px; 
          border-bottom:1px solid #d9d9d9; 
          vertical-align: top;
        }
      }
    }
  }

  a {
    color:#000;
    text-decoration: none;
  }

  .list-dash {
    li {
      position: relative;
      padding: 0 0 0 28px;

      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 16px;
      }

      & + li {
        margin-top: 4px;
      }
    }
  }
}

/* 자동 로그인 */
&auto-login {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .box-text {
    width: calc(100% - 28px);

    .title {
      margin: 0;
      padding: 0;
      color: $color-black;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
    }

    .detail {
      margin: 12px 0 0 0;
      padding: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
  }

  .box-btn {
    align-self: flex-start;
    margin: 12px 0 0 10px;
    width: 28px;
  }
  &.app-version{
    .box-text {
      width:calc(100% - 86px);
    }
    .box-btn {
      width: auto;
      .btn-line {
        padding:9px 12px 8px;
        border-radius:8px;
        border:1px solid $color-black;
        font-family: $font-family-1;
        font-size:14px;
        font-weight:300;
        line-height:16px;
        background-color: $color-white;
      }
    }
  }
  & + .ts-theme-auto-login {
    margin: 32px 0 0 0;
  }
  &.policy {
    display:block;
    list-style: none;
    margin: 48px 0 0;
    padding:0;
    a {
      display: block;
      color: $color-black;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      text-decoration: none;
    }
    li {
      + li {
        margin-top: 18px;
      }
    }
  }
}

/* 마이페이지 */
&my-main {
  margin: -136px -24px -24px;
  padding-bottom: 96px;

  .top-box {
    padding: 136px 24px 48px;
    line-height: 1;
    background-color: #F5F6F8;
  }

  .txt-branch {
    margin: 0;
    padding: 0;
    font-family: $font-family-2;
    font-size: 16px;
    line-height: 19px;
  }

  .txt-name {
    margin: 0;
    padding: 8px 0 20px;
    font-size: 28px;
    font-weight: bold;
  }

  // [dev] 개발 적용 후 수정 필요
  .wrap-btn-info {
    display:flex;
  }
  .btn-info-change {
    display: inline-block;
    padding: 9px 12px;
    border: 1px solid $color-black;
    border-radius: 8px;
    color: $color-black;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    background: #fff;
    & + .btn-logout {
      margin-left:8px;
    }
  }

  // [dev] 개발 적용 후 수정 필요
  .my-parking-admin {
    display:none;
    margin: 32px 0 0;
    padding: 0 24px 32px;
    border-bottom: 8px solid #F5F6F8;

    .btn-my-parking {
      display: block;
      position: relative;
      padding: 16px 20px 16px 48px;
      border: 1px solid #EAEAEA;
      border-radius: 8px;
      color: $color-black;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      text-decoration: none;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 20px;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        background: url(../images/common/ico_myparking.svg) no-repeat center/contain;
      }

      &:after {
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        right: 20px;
        margin-top: -10px;
        content: '';
        background: url(../images/common/ico_mymain_arrow.svg) no-repeat center/contain;
      }
    }
  }

  .my-main-list {
    margin: 0;
    padding: 24px 24px 48px;
    list-style: none;

    li {
      a {
        display: flex;
        align-items:center;
        position: relative;
        padding: 16px 20px 16px 0;
        box-sizing:border-box;
        color: $color-black;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-decoration: none;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 0px;
          margin-top: -10px;
          width: 20px;
          height: 20px;
          background: url(../images/common/ico_mymain_arrow.svg) no-repeat center/contain;
        }

        span {
          display: inline-flex;
          margin: 0 0 0 8px;
          padding: 0 10px;
          border: 1px solid #1e75ff;
          border-radius: 16px;
          box-sizing: border-box;
          vertical-align: middle;
          height: 20px;
          color: #1e75ff;
          font-size: 12px;
          line-height: 18px;
          font-weight: 700;
          background-color: #e2edff;
        }
      }
    }
  }
  .my-banner {
    display:block;
    margin: 0px 24px;

    img {
      width: 100%;
      border-radius: 8px;
      vertical-align: top;
    }
    // [dev] 개발 적용 후 수정 필요
    & + .ts-theme-subpage-content {
      display:none;
    }
  }

  .app-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 64px 24px 0;

    .link {
      display: flex;
      width: calc(50% - 4px);
      max-width: 160px;
      text-decoration: none;

      img {
        display: block;
        width: 100%;
      }

      &+.link {
        margin-left: 8px;
      }
    }
  }

  .mymain-box-grey {
    margin: 0 24px;
    border-radius: 8px;
    text-align: center;
    background-color: #F5F6F8;

    p {
      position: relative;
      margin: 12px 0 0 0;
      padding: 20px 20px 20px 48px;
      line-height: 22px;
      text-align: left;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left:16px;
        width: 24px;
        height: 24px;
        background: url(../images/common/ico_mymain_alarm.svg) no-repeat left center;
        background-size:24px 24px;
        transform: translateY(-50%);
      }
    }
  }

  .wrap-logout {
    display:none;
    margin:24px 0;
    text-align:center;
    .btn-logout {
      display:inline-block;
      border:0;
      box-sizing: border-box;
      color:$color-black;
      font-family: $font-family-2;
      font-size: 14px;
      font-weight: 300;
      text-decoration: underline;
      background:transparent;
    }
    & + .ts-theme-subpage-content {
      display:none;
    }
  }

  &.app {
    padding-bottom: 0;

    .top-box {
      .box-name {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 0;

        .txt-name {
          margin: 8px 0 0;
          padding: 0;
          line-height: 33px;
        }

        .btn-info-change {
          padding: 8px 11px 7px;
          line-height: 16px;
        }
      }
      
      .list-square-box {
        margin: 48px 0 0;
        padding: 0;
        display: flex;
        align-items: center;
        list-style: none;
        gap: 6px;

        a {
          color: $color-black;
          text-decoration: none;
        }

        li {
          position: relative;
          width: 33.33%;
          margin: 0;
          padding: 0;
          border-radius: 12px;
          background-color: $color-white;

          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }

          .link {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .icon {
            display: block;
            position: relative;
            width: 32px;
            height: 32px;
            font-style: normal;
            background-repeat: no-repeat;
            background-position: center center;
            background: size 32px 32px;

            &.ico-coupon {
              background-image: url(../images/common/ico_app_coupon.svg);
            }

            &.ico-reserve {
              background-image: url(../images/common/ico_app_plocation.svg);
            }

            &.ico-parking {
              background-image: url(../images/common/ico_app_parking.svg);
            }

            .alarm {
              display: block;
              position: absolute;
              top: 1px;
              right: -9px;
              padding: 2px 4px;
              border: 1px solid $color-p;
              border-radius: 8px;
              color: $color-p;
              font-family: $font-family-2;
              font-size: 10px;
              font-weight: 700;
              line-height: 1;
              background-color: #E2EDFF;
            }
          }

          .name {
            display: block;
            margin: 8px 0 0 0;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }

    .my-banner {
      margin-bottom:0;
      a {
        display:block;
      }
    }

    .wrap-logout {
      display:block;
    }
  }
}

/* app */
&app- {
  &title {
    display: flex;
    align-items: center;
    margin:0;
    color: $color-black;
    font-size: 28px;
    line-height: 33px;
  }

  &chip-num {
    display: inline-block;
    margin: 0;
    padding: 4px 11px;
    border: 1px solid $color-p;
    border-radius: 16px;
    color: $color-p;
    font-family: $font-family-2;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    background-color: #e2edff;
  }
}

/* 대관문의, 입점문의, 팝업문의 */
&rent-information {
  margin: 132px 0 0 0;

  .box-text {
    .title {
      margin: 0;
      padding: 0;
      color: $color-black;
      font-size: 20px;
      line-height: 1;
    }

    .text {
      margin: 20px 0 0 0;
      padding: 0;
      color: $color-black;
      font-size: 18px;
      line-height: 27px;
    }
  }

  .list-process {
    margin: 40px 0 0 0;
    padding: 0;
    list-style: none;

    .item {
      border: 1px solid #eaeaea;
      margin: 0;
      padding: 24px;
      box-sizing: border-box;
      color: $color-black;

      .num {
        display: block;
        color: $color-black;
        font-family: $font-family-2;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
      }

      .title {
        margin: 24px 0 0 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      p {
        margin: 8px 0 0 0;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
      }

      .box-detail {
        p {
          line-height: 24px;

          .chip {
            display: inline-block;
            margin: 0 4px 0 0;
            border: 1px solid $color-black;
            border-radius: 20px;
            vertical-align: top;
            width: 36px;
            font-family: $font-family-2;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            text-align: center;
          }

          .discount {
            font-family: $font-family-2;
            font-weight: 500;
          }

          + p {
            margin-top: 8px;
          }
        }

        .information {
          display: block;
          margin: 12px 0 0 0;
          color: #666666;
          font-size: 14px;
          line-height: 24px;
        }
      }

      [class^="box"] {
        margin: 20px 0 0 0;

        a {
          display: block;
          color: $color-black;
          text-decoration: none;
        }

        i {
          font-style: normal;
        }

        .btn-download {
          display: inline-block;
          padding: 7px 47px 7px 15px;
          border: 1px solid $color-black;
          border-radius: 8px;
          font-size: 14px;
          line-height: 16px;
          background: url(../images/common/ico_small_download.svg) no-repeat right 15px center;
          background-size: 16px 16px;
        }

        .btn-round {
          display: inline-block;
          padding: 7px 15px 7px 15px;
          border: 1px solid $color-black;
          border-radius: 8px;
          font-size: 14px;
          line-height: 16px;
        }

        .btn-tel {
          padding: 0 0 0 28px;
          font-family: $font-family-2;
          font-size: 16px;
          line-height: 20px;
          background: url(../images/common/ico_small_tele.svg) no-repeat left center;
          background-size: 20px 20px;
        }

        .btn-email {
          padding: 0 0 0 28px;
          font-family: $font-family-2;
          font-size: 16px;
          line-height: 20px;
          background: url(../images/common/ico_small_email.svg) no-repeat left center;
          background-size: 20px 20px;

          & + .btn-email {
            margin: 8px 0 0 0;
          }
        }

        & + .box-utility {
          margin: 8px 0 0;
        }
      }
      .box-list {
        .list-dot {
          margin:0;
          li {
            color:#666;
            font-size: 14px;
            line-height: 21px;
            &:before{
              top:9px;
              background-color:#666;
            }
            & + li {
              margin-top: 9px;
            }
          }
        }
      }
      .list-dot {
        margin: 16px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          position: relative;
          padding: 0 0 0 6px;
          font-size: 16px;
          line-height: 20px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 0;
            border-radius: 50%;
            width: 2px;
            height: 2px;
            background-color: $color-black;
          }

          & + li {
            margin: 12px 0 0 0;
          }
        }
      }

      .contact {
        [class^="ico"] {
          display: block;
          position: relative;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }

        .detail {
          display: inline-block;
          margin: 8px 0 0 0;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 16px;
          text-decoration: none;
          line-height: 20px;
        }

        dl {
          margin: 8px 0 0 0;
          padding: 0;
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;

          dt {
            margin: 0;
            padding: 0;

            & + dd {
              margin-top: 8px;
            }
          }

          dd {
            margin: 0;
            padding: 0;

            .detail {
              margin-top: 0;
            }

            & + dt {
              margin: 12px 0 0 0;
            }
          }
        }

        + .contact {
          margin: 20px 0 0 0;
        }
      }

      & + .item {
        margin: 24px 0 0 0;
      }
    }
  }

  & + .ts-theme-rent-information {
    margin: 96px 0 0 0;
  }
}

/* 1:1 문의하기 상단 박스 */
&register-inquiry {
  margin: 0;
  padding: 24px;
  background-color: #f5f6f8;
  dl {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    dt {
      flex-shrink: 0;
      margin: 0;
      padding: 0;
      width: 96px;
      color: $color-black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    dd {
      margin: 0;
      padding: 0;
      width: calc(100% - 96px);
      font-family: $font-family-2;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }
    & + dl {
      margin-top: 20px;
    }
  }
  .btn-round {
    display: inline-block;
    margin: 20px 0 0 0;
    padding: 9px 12px 8px 12px;
    border: 1px solid $color-black;
    border-radius: 8px;
    color: $color-black;
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    background-color: $color-white;
    text-decoration: none;
  }
}

/* 통합검색 */
&integrated-search {
  .box-search {
    text-align: center;
    .integrated-search {
      overflow:hidden;
      position: relative;
      margin: 0 auto;
      border-width: 0 0 2px 0;
      border-style: solid;
      border-color: $color-black;
      outline:0;

      [class^="btn"] {
        position:absolute;
        top: 50%;
        right: 0;
        flex: none;
        margin:0;
        padding:0;
        border: 0;
        width: 24px;
        height: 24px;
        box-shadow: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px 24px;
        cursor: pointer;
        transform: translateY(-50%);
      }
      .btn-search {
        z-index: 0;
        background-image: url(../images/common/ico_search.svg);
      }
      .btn-clear {
        display:none;
        z-index: 5;
        background-color:#fff;
        background-image: url(../images/common/btn_ico_del.svg);
      }

      
    }

    .input-search {
      -webkit-appearance:searchfield;
      padding: 20px 30px 20px 0;
      border: 0;
      outline:0;
      box-sizing:border-box;
      width:100%;
      height:auto;
      color: $color-black;
      font-family: $font-family-1;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;

      &::-moz-input-placeholder,
      &::-webkit-input-placeholder {
        color: $color-black;
        font-family: $font-family-1;
        font-size: 20px;
        font-weight: 700;
      }

      &::placeholder {
        color: $color-black;
        font-family: $font-family-1;
        font-size: 20px;
        font-weight: 700;
      }

      &::-ms-clear,
      &::-ms-reveal {
        display:none;
        appearance: none;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display:none;
        -webkit-appearance: none;
      }
      &::-webkit-search-cancel-button {
        display:none;
        -webkit-appearance: none;
      }
    }

    .txt-result {
      // margin: 48px 0 0;
      margin: 48px 0 64px;
      color: #999;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      text-align: center;
      em {
        font-style: normal;
        color: $color-p;
      }
    }
    .input-group > .input-group-item.input-group-append {
      margin:0;
    }
  }
}

/* 인트로 동영상 */
&splash {
  overflow:hidden;
  position:relative;
  height:100vh;
  height:100dvh;
  .ts-theme-video {
    display:block;
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    object-fit: cover;
  }
  .img-splash {
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    height:100%;
    max-height:992px;
    object-fit: contain;
    animation: 2s linear 0s forwards animate-scale2;
  }
}
@keyframes animate-scale2 {
  0% {
      transform: translate(-50%, -50%) scale(1.3);
  }
  
  100% {
      transform: translate(-50%, -50%) scale(1.1);
  }
}
/* 서브 개별디자인 */
// 경방 100년사
@import "./TS_UX_1008";
// 타임스퀘어 소개
@import "./TS_UX_3016";
// 편의시설
@import "./TS_UX_3017";
// 주차안내
@import "./TS_UX_3018";
// 오시는 길
@import "./TS_UX_3019";
// 사업영역
@import "./TS_UX_3034";