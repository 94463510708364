&wrap.history &container {
  margin-top: -80px;
  padding: 0;
  .ts-theme-subpage-content {
    padding-bottom:0;
  }
}
&subpage-title {
  &.history {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    height: 100vh;
    height: 100dvh;
    background-color: #263040;
    .txt-title {
      margin: 0;
      font-size: 32px;
      line-height: 1;
      color: #fff;
      letter-spacing: 0;
    }
    .txt-bg {
      --timer: 100s;
      position: absolute;
      left: 0;
      display: flex;
      font-size: 200px;
      font-weight: 700;
      color: #f5f6f8;
      white-space: nowrap;
      opacity: 0.04;
      animation: textFlow1008 var(--timer) linear infinite;
    }
    .thumb-parallax {
      --timer: 100s;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: calc(1634px + 80px); //반복 간격 80px 더함.
      height: 100%;
      animation: imageFlow1008 var(--timer) linear infinite;
      &.animate {
        .target {
          .item {
            img {
              transform: translateX(0) scale(1);
              border-radius: 32px;
              box-shadow: 4px 8px 12px rgba(0, 0, 0, .2);
            }
          }
        }
      }
      .target {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        .item {
          position: absolute;
          margin: 0;
          img {
            width: 100%;
            transform: translateX(80vw) scale(0.3);
            transition: transform 1.5s;
          }
          &.history-1919 {
            left: 0px;
            top: 50%;
            width: 104px;
            height: 104px;
            transform: translateY(calc(-50% - 117px));
          }
          &.history-1924 {
            left: 168px;
            top: 50%;
            width: 240px;
            height: 240px;
            transform: translateY(calc(-50% + 164px));
          }
          &.history-1940 {
            left: 224px;
            top: 50%;
            width: 152px;
            height: 152px;
            transform: translateY(calc(-50% - 209px));
          }
          &.history-1945 {
            left: 464px;
            top: 50%;
            width: 104px;
            height: 104px;
            transform: translateY(calc(-50% + 164px));
          }
          &.history-1962 {
            left: 592px;
            top: 50%;
            width: 96px;
            height: 96px;
            transform: translateY(calc(-50% - 181px));
          }
          &.history-1973 {
            left: 736px;
            top: 50%;
            width: 152px;
            height: 152px;
            transform: translateY(calc(-50% + 44px));
          }
          &.history-1990 {
            left: 944px;
            top: 50%;
            width: 96px;
            height: 96px;
            transform: translateY(calc(-50% - 117px));
          }
          &.history-2000 {
            left: 1056px;
            top: 50%;
            width: 104px;
            height: 104px;
            transform: translateY(calc(-50% + 166px));
          }
          &.history-2010 {
            left: 1160px;
            top: 50%;
            width: 240px;
            height: 240px;
            transform: translateY(calc(-50% - 69px));
          }
          &.history-2024 {
            left: 1482px;
            top: 50%;
            width: 152px;
            height: 152px;
            transform: translateY(calc(-50% - 209px));
          }
        }
      }
    }
  }
}
&subpage-history {
  &-tab {
    position: relative;
    z-index: 20;
    height: 72px;
    margin: 0 0 0;
    .pin-spacer {
      pointer-events: none;
    }
    .tab-container {
      overflow-x: scroll;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0 0 20px;
      height: 100%;
      background-color: #fff;
      pointer-events: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        > li {
          display: inline-flex;
          flex-shrink: 0;
          padding-right: 24px;
          &:last-child {
            padding-right: 20px;
          }
          > a {
            height: 35px;
            line-height: 35px;
            font-size: 16px;
            font-family: $font-family-2;
            font-weight: 400;
            color: inherit;
            text-decoration: none;
            &.active {
              position: relative;
              z-index: 10;
              color: #fff;
              font-weight: 700;
              &::after {
                --spacing: 8px;
                content: '';
                position: absolute;
                left: calc( var(--spacing) * -1);
                top: 0;
                z-index: -1;
                border-radius: 8px;
                background-color: #000000;
                width: calc(100% + var(--spacing) * 2);
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    .pin-spacer{
      width:375px !important;
    }
    .history-view {
      overflow: hidden;
      position: relative;
      z-index: 10;
      width:375px !important;
      // height: 400px;
      // height: auto;
      height:100vh !important;
      padding: 0; //top: 72px
      text-align: center;
      box-sizing: border-box;
      background-color: #fff;
      .thumb-wrap {
        position: relative;
        top:20%;
        margin: 0 24px;
        .thumb {
          visibility: hidden;
          width: 100%;
          margin: 0;
          font-size: 0;
          opacity: 0;
          transition: opacity .5s, visibility .5s;
          img {
          width: 100%;
          }
          &.pos-abs {
            position: absolute;
            left: 0;
            top: 0;
          }
          &.active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .history-content-wrap {
      flex:1;
      position: relative;
      padding: 0 24px 96px 61px;
      box-sizing: border-box;
      text-align: center;
      .year-wrap {
        position: relative;
        padding-bottom: 32px;
        &:last-child {
          padding-bottom: 0;
        }
        .tit-area {
          position: relative;
          margin-left: -61px;
          margin-right: -24px;
          padding: 0 24px;
          background-color: #fff;
          .year {
            position: relative;
            margin: 0 0 32px;
            padding: 32px 0 0;
            font-size: 48px;
            font-weight: 300;
            line-height: 58px;
            font-family: $font-family-2;
            &::before {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              width: 1px;
              height: 16px;
              background-color: #e0e0e0;
            }
          }
          .tit {
            margin: 0 0 16px;
            font-family: $font-family-2;
            font-size: 20px;
            color: $color-p;
            font-weight: 700;
          }
          .txt {
            display: block;
            margin: 0;
            font-family: $font-family-2;
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            word-break: keep-all;
          }
        }
        .history-list {
          padding: 0;
          margin: 64px 0 0 0;
          // flex: 0 0 calc(50% - 48px);
          margin-left: auto;
          list-style: none;
          text-align: left;
          > li {
            + li {
              margin-top: 56px;
            }
            .tit {
              position: relative;
              font-family: $font-family-2;
              font-size: 20px;
              font-weight: 700;
              &::before {
                content: "";
                position: absolute;
                top: 8px;
                left: -24px;
                width: 6px;
                height: 6px;
                background-color: $color-p;
                transform: rotate(45deg);
              }
            }
            .txt {
              margin: 21px 0 0;
              font-family: $font-family-2;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   bottom: 0;
        //   width: calc(100% + 24px + 61px);
        //   height: 32px;
        //   margin: 0 -24px 0 -61px;
        //   background-color: #fff;
        // }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 40px;
        width: 1px;
        height: 100%;
        background-color: #e0e0e0;
      }
    }
  }
}
@keyframes textFlow1008 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--slide-width) * -1));
  }
}
@keyframes imageFlow1008 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    /* transform: translateX(calc(var(--slide-width) * -1)); */
  }
}