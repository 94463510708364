&app-main {
  margin-top: -80px;
  overflow: hidden;
  .ts-theme-section {
    overflow: hidden;
    position: relative;

    .tit-h2 {
      margin: 0;
      padding: 0;
      color: $color-black;
      font-family: $font-family-2;
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
    }

    .card-content {
      display: block;
      text-decoration: none;

      .imgbox {
        overflow:hidden;
      }

      img {
        display: block;
        width: 100%;
      }

      .detail {
        padding: 16px 0 0 0;
        box-sizing: border-box;

        .title {
          margin: 0;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }

        .infomation {
          display: flex;
          align-items: center;
          margin: 8px 0 0 0;
          color: #999;
          font-family: $font-family-2;
          font-size: 14px;
          line-height: 17px;

          .location {
            display: block;
            padding: 0 8px 0 0;
          }

          .date {
            display: block;
            position: relative;
            padding: 0 0 0 9px;

            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              width: 1px;
              height: 8px;
              background-color: #999;
              transform: translateY(-50%);
            }
          }

          .magazine {
            display: block;
            width: 100%;
            color: $color-black;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      &.giant {
        .detail {
          font-family: $font-family-2;
          text-align: center;

          .title {
            font-size: 28px;
          }

          .information {
            justify-content: center;
          }
        }
      }
    }
  }

  // sec01
  .ts-theme-sec01 {
    overflow: hidden;
    .box-visual {
      display:block;
      position: relative;
      overflow: hidden;
      width:100%;
      height:100%;
      color: $color-white;
      text-decoration: none;
      z-index: 5;

      .imgbox {
        overflow:hidden;
      }

      img {
        vertical-align: top;
        width: 100%;
        height: var(--visualheight);
        object-fit: cover;
      }

      .title {
        position: absolute;
        top: 83px;
        left: 24px;
        z-index: 3;
        margin: 0;
        padding: 0;
        font-family: $font-family-2;
        font-size: 48px;
        font-weight: 500;
        line-height: 58px;
        // font-style: italic;
        P {
          margin:0;
          padding:0;
        }
      }

      .box-detail {
        position: absolute;
        bottom: 156px;
        left: 24px;
        z-index: 3;
        margin: 0;
        padding: 0;
        font-family: $font-family-2;

        .text {
          margin: 0;
          padding: 0;
          font-size: 22px;
          line-height: 32px;
          font-weight: 700;
          line-height: 32px;
        }

        .duration {
          display: block;
          margin: 20px 0 0 0;
          font-size: 16px;
          line-height: 19px;
        }
      }
      
    }

    .wrap-scroll {
      position: absolute;
      bottom: 108px;
      left: 24px;
      z-index: 15;
      width: 141px;
      height: 13px;

      p {
        strong {
          color: #fff;
          font-size: 10px;
          font-weight: bold;
        }
      }

      .paging-box {
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
        color: #fff;

        .current {
          position: absolute;
          top: 0;
          left: 0;
        }

        .total {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .swiper-pagination-progressbar {
      overflow:hidden;
      width: 100px !important;
      height: 1px !important;
      top: 6px;
      left: 19px !important;
      background: rgba(255, 255, 255, 0.2);

      .swiper-pagination-progressbar-fill {
        background: #fff;
      }
    }
  }

  .visual-swiper {
    width: calc(100% + 1px);
    height: var(--visualheight);
    background: #fff;
    .swiper-slide {
      will-change: transform;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  }

  // sec02
  .ts-theme-sec02 {
    margin: 0;
    padding: 102px 24px;
    box-sizing: border-box;
  }

  .swiper-news {
    position: relative;
    width: 100%;
    margin: 32px 0 0 0;
    overflow: visible;

    .swiper-slide {
      width:314px;
      .card-content {
        display: block;
        padding-right: 13px;
      }

      .imgbox {
        overflow:hidden;
      }

      img {
        width: 100%;
      }
    }
  }

  // sec03
  .ts-theme-sec03 {
    padding: 80px 24px;

    .wrap-magazine {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 50vh;
        background: #0169de;
      }

      .tit-h2 {
        position: relative;
        color: $color-white;
      }

      .img-group {
        position: relative;
        margin-top: 32px;

        .item {
          padding-bottom: 70px;
          .card-content {
            margin:0 auto;
            max-width:327px;
            .detail {
              .title {
                font-size: 18px;
                line-height: 21px;
                font-weight: 500;
              }
              .infomation {
                .magazine {
                  font-size: 16px;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
    }
  }

  // sec04
  .ts-theme-sec04 {
    height: 200vh;
    padding: 0;
    box-sizing: border-box;
    background: #171717;
    overflow: visible;

    .sec04-offset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: sticky;
      top: 0;
      height: 100vh;
      height: 100dvh;
      opacity: 1 !important;
      overflow: hidden;
      &.aos-animate {
        .tit-h2 {
          transform: translateY(0);
          transition: all 0.5s ease 0.7s;
        }

        .card-box {
          margin-top: 184px;
          visibility: hidden;
          opacity: 0;
          transition: margin-top 0.5s ease 0.7s, opacity 0.3s ease 1.8s, visibility 0.5s ease 1.5s;

          .card-item {
            transition: all 0.5s ease;

            &.card1 {
              top: -100%;
              left: 150%;
            }

            &.card2 {
              top: 50%;
              left: 50%;
              margin-top: -13px;
              margin-left: -75%;
              transform: rotate(0deg) translate(-50%, -50%);
              transition: all 0.5s ease, margin-top 0.3s ease 1.5s, margin-left 0.3s ease 1.5s;
            }

            &.card3 {
              top: -100%;
              left: -150%;
            }

            &.card4 {
              top: 50%;
              left: 50%;
              margin-top: -13px;
              margin-left: 75%;
              transform: rotate(0deg) translate(-50%, -50%);
              transition: all 0.5s ease, margin-top 0.3s ease 1.5s, margin-left 0.3s ease 1.5s;
            }

            &.card5 {
              top: 50%;
              left: 50%;
              margin-top: -14px;
              width: 230px;
              height: 320px;
              transform: rotate(0deg) translate(-50%, -50%);
              transition: all 0.5s ease, width 0.3s ease 1.5s, height 0.3s ease 1.5s, margin-top 0.3s ease 1.5s, margin-left 0.3s ease 1.5s;
            }
          }
        }

        .swiper-card {
          opacity: 1;
          transition: all 0.3s ease 1.5s;
        }
      }
    }

    .tit-h2 {
      width: 100%;
      color: $color-white;
      text-align: center;
      opacity: 1;
      text-align: center;
      transform: translateY(calc(180%));
      transition: all 0.5s;
    }

    .card-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .card-item {
        position: absolute;
        transition: all 0.5s;

        &.card1 {
          top: -13%;
          left: 70%;
          transform: rotate(15deg);
        }

        &.card2 {
          top: -25%;
          left: 0%;
          transform: rotate(25deg);
        }

        &.card3 {
          top: 2%;
          left: -66%;
          transform: rotate(-38deg);
        }

        &.card4 {
          top: 79%;
          left: 8%;
          transform: rotate(-19deg);
        }

        &.card5 {
          top: 54%;
          left: 84%;
          transform: rotate(28deg);
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      width: 230px;
      height: 320px;
      box-sizing: border-box;
      color: $color-white;
      font-family: $font-family-2;
      text-decoration: none;

      b {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
      }

      h3 {
        margin: 0 0 11px 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        font-family: $font-family-2;
      }

      p {
        margin: 0;
        font-size: 10px;
        line-height: 12px;
      }

      &.blue {
        background-color: #0069de;
      }

      &.black {
        background-color: #404040;
      }

      &.orange {
        background-color: #e87500;
      }
    }

    .swiper-card {
      &-wrap {
        position: relative;
        margin: 70px auto 0;
        overflow: hidden;
        .dim-left {
          position: absolute;
          top: 0;
          right: calc(100% - 64px);
          bottom: 0;
          left: 0;
          background-color: #171717;
          z-index: 2;
          pointer-events: none;
          transition: 0.4s;
        }
        .dim-right {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: calc(100% - 64px);
          background-color: #171717;
          z-index: 2;
          pointer-events: none;
          transition: 0.4s;
        }
      }
      width: 100vw;
      padding: 32px 63px;
      overflow: hidden;
      opacity: 0;
      box-sizing: border-box;
      .card {
        width: auto;
        height: 320px;
        padding: 26px 20px;
        margin: 0 6.4%;
        text-align: left;
      }
    }

    .swiper-slide {
      // width: 220px;
      text-align: center;
      transition: all 0.3s ease;

      &.swiper-slide-active {
        transform: scale(1.136);
      }
    }
  }

  // sec05
  .ts-theme-sec05 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // min-height: 100vh;
    padding: 80px 24px 160px;
    box-sizing: border-box;
    background-color:#f5f6f8;

    .tab-cont {
      display: none;
      &.active {
        display: block;
      }
    }

    .tab-menu {
      overflow: visible;
      &-wrap {
        margin: 16px -24px 0;
        padding: 0 12px;
        overflow: hidden;
      }
      
      .swiper-wrapper {
        padding: 0;
        margin: 0;
        list-style: none;
        .swiper-slide {
          width: auto;
        }
      }

      a {
        display: block;
        font-family: $font-family-2;
        padding: 20px 0;
        margin: 0 14px;
        color: #999999;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
        &.active {
          color: $color-black;
          font-weight: 700;
        }
        .num {
          vertical-align: super;
          font-size: 12px;
        }
      }
    }

    .tab-cont {
      display: none;
      margin: 0 -24px;
      padding: 0 20px;
      &.active {
        display: block;
      }
    }

    .swiper-food {
      position: relative;
      margin: 20px 0 0 0;
      overflow:visible;

      .swiper-slide {
        width:295px;
      }
    }

    .restaurant-card {
      display:block;
      position: relative;
      margin: 0 24px 0 8px;
      padding: 0 0 20px;
      text-decoration: none;
      .badge {
        display: block;
        position: absolute;
        top: 9px;
        left: -8px;
        margin:0;
        padding: 6px 10px;
        border-radius:0;
        color: $color-white;
        font-family: $font-family-2;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        background-color: $color-black;
      }

      .imgbox {
        overflow:hidden;
      }

      img {
        width: 100%;
        vertical-align: top;
      }

      .txt {
        margin-top:16px;
        padding-left: 16px;
        color: $color-black;
        font-family: $font-family-2;
        font-weight: 700;

        .txt-floor {
          display: block;
          font-size: 16px;
          line-height: 19px;
        }

        .restaurant {
          margin: 4px 0 0 0;
          font-size: 28px;
          line-height:36px;
        }
      }
    }
  }

  .btn-up {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: -1;
    border-radius: 50%;
    border:0;
    box-shadow:none;
    outline:0;
    width: 48px;
    height: 48px;
    color: $color-white;
    font-family: $font-family-2;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    transition: opacity 0.3s;
    background-color: $color-black;
    opacity: 0;

    i {
      position: relative;
      display: block;
      margin: 4px 0 0 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &.on {
      opacity: 1;
      z-index: 10
    }

    &.app {
      bottom: 100px;
    }
  }
}
