&memb-wrap {
  position: relative;
  border-top: 2px solid $color-black;

  .in-save {
    display: flex;
    margin-top: 12px;
  }

  .in-find {
    margin-top: 24px;

    .find-link {
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0 0 0 auto;
      list-style: none;

      >li+li {
        position: relative;
        margin-left: 8px;
        padding-left: 9px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 12px;
          background: #e5e5e5;
          transform: translateY(-50%);
        }
      }

      a {
        display: block;
        color: inherit;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
      }
    }
  }

  // [dev] 임시 가림 20240730
  .login-join-iconbox {
    display: flex;
    justify-content: center;
    position: relative;
    margin:70px 0 0 0;

    [class*="icon"] {
      width: 40px;
      height: 40px;
      margin: 0 10px;
      border-radius: 50%;
      @include bg-inline;
    }
    // 애플 로그인 활성화 2024-10-24
    .icon {
      &:nth-child(3) {
        display:none;
      }
    }
  }

  // [dev] 임시 가림 20240730
  .login-join-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

     // [dev] sns 임시 가림 20240730
    .login-title {
      display:block;
      margin: 0;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;

      &+.login-txt {
        margin: 12px 0 0;
      }
    }

    // [dev] sns 임시 가림 20240730
    .login-txt {
      display:block;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      font-family: $font-family-2;
      text-align: center;
    }

    // [dev] sns 임시 가림 20240730
    &.bg-gray {
      background: #f5f6f8;
      display:flex;
      margin-top:-48px;
      .login-title,
      .login-txt {
        display:block;
      }
    }

    .login-account {
      margin: 0 0 16px;
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      @include bg-inline;
    }
    & + .login-join-iconbox {
      margin:0;
    }
  }

  // [dev] sns 임시 가림 20240730
  .login-txt-btns {
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;

    .txtbox {
      .tit {
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }

      .txt {
        margin: 8px 0 0 0;
        color: #666;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .btnbox {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 0 0 -16px;

      [class*="icon"] {
        width: 40px;
        height: 40px;
        margin: 8px 0 0 16px;
        border-radius: 50%;
        @include bg-inline;
      }
      .icon {
        // 애플 로그인 활성화 2024-10-24
        &:nth-child(3) {
          display:none;
        }
      }
      .kakao {
        background-image: url(../images/common/ico_memb_kakao_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_kakao_on.svg)
        }
      }
      .naver {
        background-image: url(../images/common/ico_memb_naver_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_naver_on.svg)
        }
      }
      .google {
        background-image: url(../images/common/ico_memb_google_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_google_on.svg)
        }
      }
      .apple {
        background-image: url(../images/common/ico_memb_apple_d.svg);
        &.on{
          background-image: url(../images/common/ico_memb_apple_on.svg)
        }
      }
    }

    & + .label-text {
      margin-top:32px;
    }
  }

  .easy-signin {
    margin: 48px 0 0 0;
    padding: 32px 0;
    box-sizing: border-box;
    text-align: center;
    background-color: #F5F6F8;
    .icon {
      display:inline-block;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      font-style: normal;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 32px 32px;
      &.naver {
        background-color: #03C75B;
      }
      &.kakao {
       background-color: #fddc3f; 
      }
      &.google {
        background-size: 18px 18px;
        background-color: $color-white;
      }
      &.apple {
        background-color: $color-black;
      }
    }
    .text {
      margin: 16px 0 0 0;
      font-size: 16px;
      letter-spacing: -0.2px; 
      line-height: 24px;
    }
    & + .form-title {
      margin-top: 32px;
    }
    /* CMS 임시 수정 */
    & + .login-join-title {
      &.bg-gray {
        margin-top:48px;
      }
    }
  }

  .box-gray {
    padding: 16px;
    background-color: #f5f6f8;
    /* 임시 CMS 수정 */
    & + .ts-theme-mt-16 + .txt-info-14 {
      margin-bottom:8px;
    }
  }

  .arrow-linkbox {
    margin: 0;
    padding: 0;
    list-style: none;

    &>li {
      &>a {
        position: relative;
        display: block;
        padding: 16px;
        border: 1px solid #d9d9d9;
        margin-bottom: -1px;
        font-size: 14px;
        font-weight: 300;
        color: inherit;
        text-decoration: none;
      }
      strong {
        display: inline-block;
        margin: 0 0 0 8px;
        color: $color-p;
        font-weight: 600;
      }
      .icon-arrow {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url(../images/common/ico_arrow_r.svg) no-repeat center/16px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .txt-center-16 {
    margin: 0;
    color: $color-black;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-family: $font-family-2;
    word-break: keep-all;

    a {
      margin-left: 8px;
      color: $color-p;
      font-weight: 600;
    }
  }

  .list-text-info {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      margin: 0;
      padding: 0 0 0 6px;
      color:#666;
      line-height: 21px;
      list-style: none;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        border-radius: 50%;
        width: 2px;
        height: 2px;
        background-color: #666;
      }

      &+li {
        margin: 8px 0 0 0;
      }
    }

    & + .label-text {
      margin-top: 32px;
    }
  }

  .txt-center-14 {
    margin: 0;
    color: #666;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    word-break: keep-all;
  }

  .txt-info-14 {
    margin: 8px 0 0;
    color: #666;
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;

    // word-break: keep-all;
    a {
      color: $color-p;
      font-weight: 600;
    }
  }

  .text-tip {
    position: relative;
    margin: 12px 0 0;
    padding-left: 20px;
    color: #666;
    font-size: 14px;
    line-height: 21px;
    word-break: keep-all;

    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../images/common/ico_note.svg) no-repeat center/contain;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }

  .bd-box {
    padding: 24px;
    margin-top: 24px;
    border: 1px solid #eaeaea;

    .box-title {
      font-size: 18px;
      line-height: 21px;
      margin: 0 0 20px;
    }

    .box-list {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        padding-left: 14px;
        position: relative;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;

        &+li {
          margin-top: 8px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 1px;
          background: $color-black;
          top: 9px;
          left: 0;
        }

        &.emphasis {
          color: #FC1B1B;
          &:before {
            background: #FC1B1B;
          }
        }
      }
    }
  }

  // 아이디 안내
  .zindex-guide {
    position: relative;
    z-index: 700;

    &::before {
      content: "";
      border-color: #1f75ff transparent;
      border-style: solid;
      width: 0;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.po-top {
      &::before {
        border-width: 6px 4px 0 4px;
        top: -3px;
      }
    }

    &.po-bottom {
      &::before {
        border-width: 0 4px 6px 4px;
        bottom: -13px;
      }
    }
  }

  .login-join-iconbox {
    margin-top:28px;
    .tooltip-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: absolute;
      bottom: 45px;
      left: 50%;
      z-index: 700;
      margin: 0;
      width: 296px;
      padding: 12px 16px;
      box-sizing: border-box;
      box-shadow: 0 6px 18px rgba(0,0,0,0.4);
      transform: translateX(-50%);
      background: $color-p;

      p {
        padding: 0;
        margin: 0 5px 0 0;
        color: #fff;
        font-size: 14px;
        font-family: $font-family-1;
      }

      .close-btn {
        flex: none;
        width: 16px;
        height: 16px;
        background: url(../images/common/btn_white_close.svg) no-repeat center/contain;
        cursor: pointer;
      }
    }
    /* CMS 임시 수정 */
    & + .login-join-title {
      &.bg-gray {
        margin-top:48px
      }
    }   
  }

  .ts-theme-form {
    .tooltip-box {
      display: flex;
      align-items: flex-start;
      position: absolute;
      top: 65px;
      left: 50%;
      width: 281px;
      padding: 12px 16px;
      margin: 0;
      background: $color-p;
      box-sizing: border-box;
      box-shadow: 0 6px 18px rgba(0,0,0,0.4);
      transform: translateX(-50%);
      z-index: 700;

      .tool-text {
        flex: 1;
        color: #fff;

        p {
          padding: 0;
          margin: 0 5px 13px 0;
          font-size: 14px;
          font-family: $font-family-1;
        }

        a {
          color: inherit;
          font-size: 14px;
          font-weight: 300;
          opacity: 0.8;
        }
      }

      .close-btn {
        flex: none;
        width: 16px;
        height: 16px;
        background: url(../images/common/btn_white_close.svg) no-repeat center/contain;
        cursor: pointer;
      }
    }
  }

  .btn-phone {
    padding: 0 16px 0 39px;
    font-size: 14px;
    line-height: 40px;
    background-image:  url(../images/common/ico_login_phone.svg);
    background-repeat: no-repeat;
    background-position: left 16px center;
    background-size: 20px 20px;
  }

  // 회원가입완료
  .join-finish-box {
    margin-top:-2px;
    border-top:2px solid $color-white;
    box-sizing:border-box;
    
    .login-finish-title {
      margin: 0;
      padding: 115px 0 0 0;
      color:$color-black;
      font-family: $font-family-2;
      font-size:32px;
      font-weight:700;
      line-height:38px;
      text-align:center;
      background: url(../images/common/img_join_finish.png) no-repeat top center;
      background-size: 88px;
    }
    .login-finish-text {
      margin:16px 0 0 0;
      color:$color-black;
      font-family: $font-family-2;
      font-size: 16px;
      font-weight:300;
      line-height:19px;
      text-align:center;
    }
    .box-gray {
      margin-top:48px;
      padding: 32px 16px;
      box-sizing:border-box;
      text-align:center;
      p {
        margin:0;
        padding:0;
        font-size:16px;
        line-height:24px;
      }
      .list-app-download {
        display:flex;
        align-items:center;
        justify-content: center;
        margin:24px 0 0;
        padding:0;
        li {
          width:100px;
          list-style: none;
          img {
            display:block;
            width:100%;
          }
          .link {
            display:block;
            margin:8px 0 0;
          }
          & + li {
            margin-left:16px;  
          }
        }
      }
    }
    .ts-theme-btnstyle-box {
      margin-top:48px;
      .ts-theme-btnstyle {
        flex:1;
        padding: 0;
        min-width: auto;
        width:50%;
        font-size:18px;
        font-weight: 700;
      }
    }
  }
}

// black-tooltip
&black-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  margin: 0 0 5px;
  background-color: $color-black;
  color: $color-white;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  transform: translateX(-50%);
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.2);

  &-group {
    position: relative;
  }

  &-arrow {
    position: relative;
    z-index: 700;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 50%;
      width: 0;
      height: 0;
      border-width: 6px 4px 0 4px;
      border-color: $color-black transparent;
      border-style: solid;
      transform: translate(-50%, -50%);
    }
  }
}