&parking-imgbox {
  .parking-img {
    display: none;
    border:1px solid $color-black;
    box-sizing:border-box;
  }

  img {
    width: 100%;
    vertical-align: top;
  }
}

&parking-txtbox {
  margin: 48px 0 0 0;
  font-family: $font-family-2;

  .item {
    margin: 0;
    padding: 0;

    .tit {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    dd {
      margin: 16px 0 0 0;
      padding: 0;
    }

    .list-bullet-diamond {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: 0;
        padding: 0 0 0 12px;
        font-size: 16px;
        line-height: 24px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 9px;
          left: 0;
          width: 4px;
          height: 4px;
          background: $color-black;
          transform: rotate(45deg);
        }

        &+li {
          margin: 12px 0 0 0;
        }
      }
    }

    &+.item {
      margin: 40px 0 0 0;
    }
  }

  &.app {
    .list-parking-status {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      gap: 4px;

      li {
        position: relative;
        width: 33.33%;
        margin: 0;
        padding: 0;
        border-radius: 12px;
        background-color: #F5F6F8;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        .box {
          position: absolute;
          padding: 12px 16px 16px;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          
          .location {
            display: block;
            color: $color-black;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
          }

          .status {
            display: block;
            font-size: 14px;
            font-weight: 500;
          }

          .icon-face {
            display: block;
            position: absolute;
            bottom: 16px;
            right: 16px;
            width: 28px;
            height: 28px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 28px 28px;
          }

          &.vacancy {
            .status {
              color: #38CB99;
            }

            .icon-face {
              background-image: url(../images/common/ico_face01.svg);
            }
          }

          &.congesion {
            .status {
              color: #FF7B40;
            }

            .icon-face {
              background-image: url(../images/common/ico_face02.svg);
            }
          }

          &.full {
            .status {
              color: #FC1A1B;
            }

            .icon-face {
              background-image: url(../images/common/ico_face03.svg);
            }
          }
        }
      }
    }
  }
}