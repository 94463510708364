&container {
  .inner-title-area {
    .title {
      margin: 0 0 32px;
      padding: 0;
      font-size: 40px;
      font-weight: 700;
    }

    .desc {
      margin: 0;
      padding: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;

      .color-b {
        color: #1E75FF;
        font-weight: 700;
      }
    }
  }

  .box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    background-color: $color-white;

    .icon {
      display: block;
      width: 64px;
      height: 64px;
      font-style: normal;

      svg {
        width:100%;
        height:100%;
        path,
        rect,
        line,
        circle {
          stroke: white;
          stroke-width:2;
          transition: all 1.5s linear;
        }
      }

      &.svg01 {
        path {
          stroke: $color-black;
          stroke-dasharray: 232;
          stroke-dashoffset: 232;
        }
      }

      &.svg02 {
        path {
          stroke: $color-black;
          stroke-dasharray: 168;
          stroke-dashoffset: 168;
        }
      }

      &.svg03 {
        path {
          stroke: $color-black;
          stroke-dasharray: 288;
          stroke-dashoffset: 288;
        }
      }

      &.svg04 {
        path {
          stroke: $color-black;
          stroke-dasharray: 283;
          stroke-dashoffset: 283;
        }
      }

      &.svg05 {
        path {
          stroke: $color-black;
          stroke-dasharray: 288;
          stroke-dashoffset: 288;
        }
      }

      &.svg06 {
        path {
          stroke: $color-black;
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
        }
      }

      &.svg07 {
        path {
          stroke: $color-black;
          stroke-dasharray: 163;
          stroke-dashoffset: 163;
        }

        circle {
          stroke: $color-black;
          stroke-dasharray: 82;
          stroke-dashoffset: 82;
        }

        rect {
          stroke: $color-black;
          stroke-dasharray: 188;
          stroke-dashoffset: 188;
        }

        line {
          stroke: $color-black;
          stroke-dasharray: 26;
          stroke-dashoffset: 26;
        }
      }

      &.svg08 {
        path {
          stroke: $color-black;
          stroke-dasharray: 222;
          stroke-dashoffset: 222;
        }

        line {
          stroke: $color-black;
          stroke-dasharray: 20;
          stroke-dashoffset: 20;
        }
      }

      &.svg09 {
        path {
          stroke: $color-black;
          stroke-dasharray: 225;
          stroke-dashoffset: 225;
        }
      }
    }

    .logo {
      width: 138px;

      img {
        display: block;
        width: 100%;
      }

      &+.desc {
        margin: 24px 0 0 0;
      }
    }

    .title {
      display: block;
      margin: 32px 0 0 0;
      padding: 0;
      font-family: $font-family-2;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    .desc {
      margin: 16px 0 0 0;
      padding: 0;
      font-size: 16px;
      font-weight: 300;
      line-height: 27px;
    }

    &.aos-animate {
      .svg01 {
        path {
          stroke-dashoffset: 0;
        }

        rect {
          stroke: $color-black;
          stroke-dashoffset: 0;
        }
      }

      .svg02 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg03 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg04 {
        path {
          stroke-dashoffset: 0;
        }

        rect {
          stroke-dashoffset: 88;
        }
      }

      .svg05 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg06 {
        path {
          stroke-dashoffset: 0;
        }
      }

      .svg07 {
        path {
          stroke-dashoffset: 0;
        }

        circle {
          stroke-dashoffset: 0;
        }

        rect {
          stroke-dashoffset: 0;
        }

        line {
          stroke-dashoffset: 0;
        }
      }

      .svg08 {
        path {
          stroke-dashoffset: 0;
        }

        line {
          stroke-dashoffset: 0;
        }
      }

      .svg09 {
        path {
          stroke-dashoffset: 0;
        }
      }
    }

    &+.box-item {
      margin: 24px 0 0 0;
    }
  }

  .ts-theme-develope {
    padding: 96px 24px;
    background: #F5F6F8;
    margin: 0 -24px;

    .devl-card-area {
      .card-item {
        padding: 32px;
        border-radius: 24px;
        background: $color-white;

        .num {
          display: block;
          margin: 0;
          padding: 0;
          font-family: $font-family-2;
          font-size: 18px;
          font-weight: 500;
        }

        b {
          display: block;
          margin: 32px 0 0;
          padding: 0;
          font-size: 18px;
          font-weight: 700;
        }

        ul {
          margin: 16px 0 0;
          padding: 0;

          li {
            position: relative;
            margin: 0;
            padding: 0 0 0 12px;
            font-size: 16px;
            font-weight: 300;
            line-height: 19px;
            list-style: none;

            &+li {
              margin: 8px 0 0 0;
            }

            &::after {
              content: '';
              position: absolute;
              top: 7px;
              left: 0;
              width: 4px;
              height: 4px;
              background: $color-black;
            }
          }
        }

        &+.card-item {
          margin: 20px 0 0 0;
        }
      }

      .card-title {
        margin: 0;
        padding: 0;
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
      }
    }

    .devl-type-area {
      margin-top: 83px;

      .type-title {
        padding: 0;
        margin: 0;
        font-size: 28px;
      }

      .type-imgbox-wrap {
        .img-box {
          width: 100%;
          img {
            display:block;
            width:100%;
          }
        }

        .type-img-box {
          .type-img-title {
            display: block;
            margin: 24px 0 0 0;
            padding: 0;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
          }

          .type-desc {
            padding: 0;
            margin: 16px 0 0 0;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }
        }
      }
    }
  }

  .ts-theme-operation {
    padding: 96px 0;

    .inner-section {
      .opar-ico-wrap {
        .box-item {
          height: 264px;
        }
      }
    }
  }

  .ts-theme-management {
    padding: 96px 24px;
    background: #F5F6F8;
    margin: 0 -24px;

    .mang-ico-area {
      .mang-ico-wrap {
        .box-item {
          height: 229px;
        }

        .mang-ico-box {
          padding: 56px 0;
          background: $color-white;
          border: 1px solid #EAEAEA;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .mang-ico {
            display: block;
            margin: 0 auto;
          }

          .mang-title {
            padding: 0;
            margin: 32px 0 0 0;
            text-align: center;
            font-size: 18px;
            font-family: $font-family-2;
            font-weight: 700;
          }
        }
      }
    }
  }

  .ts-theme-consulting {
    padding: 96px 0;

    .inner-section {
      .cons-vision-wrap {
        .cons-title {
          font-size: 28px;
          font-weight: 700;
          padding: 0;
          margin: 0;
        }

        .cons-ico-box {
          .box-item {
            height: 283px;

            .desc {
              font-size: 18px;
              font-weight: 500;
              text-align: center;
            }
            & + .box-item {
              margin-top:0;
            }
          }
          .ico-plus {
            display: block;
            position: relative;
            margin:16px auto;
            width: 24px;
            height: 24px;
  
            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              width: 24px;
              height: 6px;
            }
  
            &::before {
              background-color: #1E75FF;
              transform: translateY(-50%);
            }
  
            &::after {
              background-color: rgba(255, 172, 46, .6);
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }

        .cons-vision-box {
          padding: 56px 0;
          color: $color-white;
          font-family: $font-family-2;
          font-weight: 700;
          text-align: center;
          background: #1E75FF;

          .vision-title {
            margin: 0;
            padding: 0;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
          }

          .vision-desc {
            margin: 24px 0 0 0;
            padding: 0;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
          }
        }

        .cons-info-wrap {
          margin: 43px 0 0 0;

          .info-title {
            margin: 0 0 20px 0;
            padding: 0;
            font-size: 20px;
          }

          .cons-info-box {
            .info-text {
              display: block;
              padding: 0;
              margin: 0;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              & + .info-desc{
                margin-top:8px;
              }
            }

            .info-desc {
              padding: 0;
              margin: 0;
              font-family: $font-family-2;
              font-size: 16px;
              line-height: 24px;

              .desc-item {
                display: block;
                & + .desc-item {
                  margin-top: 8px;
                }
              }
              & + .info-desc {
                margin-top:8px;
              }
            }
          }
        }
      }
    }
  }

  .ts-theme-dev-tab {
    margin: 0 -24px -32px -24px;

    .inner-section {
      position: relative;

      .swiper-business {
        .swiper-slide {
          position: relative;
          margin: 0;
          padding: 176px 0 175px;
          box-sizing: border-box;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          .wrap-title {
            position:relative;
            z-index: 2;
            color: $color-white;
            text-align: center;
    
            .txt-ts {
              font-family: $font-family-2;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.06em;
              line-height: 19px;
            }
    
            .txt-title {
              margin: 8px 0 0 0;
              font-size: 48px;
              line-height: 58px;
              font-family: $font-family-2;
              font-weight: 700;
              letter-spacing: -0.033em;
            }
          }
    
          .box-information {
            position: relative;
            z-index: 2;
            margin: 48px auto 0;
            padding: 24px;
            box-sizing: border-box;
            width: calc(100% - 48px);
            background-color: rgba(0, 0, 0, .5);

            dl {
              display: flex;
              align-items: flex-start;
              margin: 0;
              padding: 0;
              color: $color-white;
              font-size: 18px;

              dt {
                flex-shrink: 0;
                margin: 0;
                padding: 0;
                width: 104px;
                font-family: $font-family-2;
                font-weight: 300;
              }

              dd {
                margin: 0;
                padding: 0;
                font-family: $font-family-2;
                font-weight: 500;
              }

              &+dl {
                margin: 24px 0 0 0;
              }
            }
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .2);
          }

        }

        .slide-bar {
          display:inline-flex;
          align-items:center;
          position: absolute;
          top: 120px;
          left: 50%;
          z-index: 2;
          border-radius: 16px;
          padding: 8px 15px 7px;
          border: 1px solid rgba(255,255,255,.22);
          color: rgba(255,255,255,.22);
          font-family: $font-family-2;
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
          transform: translateX(-50%);
          i {
            font-style:normal;
            margin:0 4px;
          }
          .current {
            color: $color-white;
            font-weight: 700;
          }
        }
      }
    }
  }
}