/* button */
&btnstyle {
  @include flex-center;
  width: 100%;
  padding: 0;
  border: 1px solid $color-black;
  background-color: $color-white;
  color: $color-black;
  font-family: $font-family-1;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  &-box {
    display: flex;
    align-items: flex-end;
    background: $color-white;
    box-sizing: border-box;
    > * + * {
      margin-left: 8px;
    }
  }
  &:disabled {
    border-color: transparent !important;
    background: #f8f8f8 !important;
    color: #d9d9d9 !important;
  }
  &.h-56 {
    height: 56px;
    font-size: 18px;
    font-weight: 700;
  }
  &.h-48 {
    height: 48px;
    font-size: 16px;
    font-weight: 700;
  }
  &.h-36 {
    height: 36px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
  }

  &.bg-p {
    border-color: transparent;
    background: $color-p;
    color: $color-white;
  }
  &.bg-t {
    flex: none;
    width: auto;
    padding-right: 19px;
    padding-left: 19px;
    font-size: 16px;
    font-weight: 400;
  }
  &.bg-n {
    background: #0c2044;
    color: $color-white;
    font-size: 16px;
  }
  &.bd-black {
    border-color: $color-black;
    background-color: $color-white;
    color: $color-black;
  }
  &.r-12 {
    border-radius: 12px;
  }
  .ico-16 {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    @include bg-inline;
  }
  &.btn-qna {
    padding:12px;
    border-radius:12px;
    box-sizing:border-box;
    width:auto;
    height:40px;
    font-size: 16px;
    font-weight: 700;
    i.txt {
      padding-right:20px;
      font-family:$font-family-1;
      font-size:14px;
      font-style:normal;
      font-weight: 500;
      line-height: 16px;
      background:url(../images/common/ico_wr.svg) no-repeat right center;
      background-size:16px 16px;
    }
  }
}

/* tab */
&tab {
  &box {
    display: flex;
    margin: 0;
    padding: 2px;
    border-radius: 12px;
    background-color: #f5f6f8;
    list-style: none;
    box-sizing: border-box;
    > li {
      flex: 1;
      > a,
      > button {
        display: block;
        position: relative;
        border-radius: 10px;
        color: #666;
        font-size: 16px;
        line-height: 44px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        &.active {
          background: $color-p;
          color: $color-white;
          z-index: 1;
        }
      }
      button {
        border:0px solid transparent;
        box-shadow:none;
        background-color:transparent;
      }
    }
    &.left {
      justify-content: flex-start;
      padding: 0;
      background-color: transparent;
      > li {
        flex: none;
        & + li {
          margin-left: 8px;
        }
        > a {
          padding: 0 16px;
          border-radius: 12px;
          color: $color-black;
          &.active {
            color: $color-white;
          }
        }
      }
    }
  }
}

/* switch button */
&switch {
  display: inline-flex;
  align-self: center;
  position: relative;
  margin:0;
  input {
    margin: 0;
    width: 28px;
    height: 8px;
    border-radius: 16px;
    background-color: #ddd;
    appearance: none;
    cursor: pointer;
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      background-color: #1e75ff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: calc(100% - 16px);
      transform: translateY(-50%);
      transition: all 0.2s;
    }
    &:checked {
      background-color: #8ebcfc;
      &::before {
        right: 0px;
      }
    }
  }
}
