&main {
  .section {
    position:relative;
    z-index:10;
    .tit-h2 {
      margin: 0;
      padding: 0;
      color: $color-black;
      font-family: $font-family-2;
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
    }

    .inner-section {
      margin: 0;
      box-sizing: border-box;
    }

    .card-content {
      display: block;
      text-decoration: none;

      .imgbox {
        overflow:hidden;
      }

      img {
        display: block;
        width: 100%;
      }

      .detail {
        padding: 16px 0 0 0;
        box-sizing: border-box;

        .title {
          margin: 0;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
        }

        .infomation {
          display: flex;
          align-items: center;
          margin: 8px 0 0 0;
          color: #999;
          font-family: $font-family-2;
          font-size: 14px;
          line-height: 17px;

          .location {
            display: block;
            padding: 0 8px 0 0;
          }

          .date {
            display: block;
            position: relative;
            padding: 0 0 0 9px;

            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              width: 1px;
              height: 10px;
              background-color: #999;
              transform: translateY(-50%);
            }
          }

          .magazine {
            display: block;
            width: 100%;
            color: $color-black;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &.giant {
        .detail {
          font-family: $font-family-2;
          text-align: center;

          .title {
            font-size: 28px;
            line-height: 33px;
            font-weight: 500;
          }

          .information {
            justify-content: center;
          }
        }
      }
    }
  }

  [class^="sec"] {
    position: relative;
  }

  // sec01
  .sec01 {
    margin: -80px 0 0 0;
    overflow: hidden;
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 33px;
      left: 50%;
      z-index: 10;
      width: 24px;
      height: 24px;
      background: url("../images/main/img_arrow.png") no-repeat center center / 100%;
      transform: translateX(-50%);
    }

    .main-bg {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: #475586;
      transition: all 0.65s;
    }

    .swiper-main {
      width: 100%;
      height: 100vh;
      height: 100dvh;
      padding: 0 24px;
      box-sizing: border-box;
      overflow: visible;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        opacity: 0.2;
        filter: blur(10px);
        transition: 0.5s;
        .inbox {
          display:block;
          position: relative;
          margin:0 auto;
          width:100%;
          max-width:327px;
          height:527px;
          color: $color-white;
          font-family: $font-family-2;
          text-decoration:none;
          .imgbox {
            overflow:hidden;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: top;
          }
          .title {
            position: absolute;
            top: 24px;
            right: 24px;
            left: 24px;
            font-size: 50px;
            line-height: 60px;
            font-weight: 500;
            p {
              margin:0;
              padding:0;
            }
          }
          .txt01 {
            position: absolute;
            right: 24px;
            bottom: 90px;
            left: 24px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 700;
          }
          .txt02 {
            position: absolute;
            right: 24px;
            bottom: 70px;
            left: 24px;
            font-size: 14px;
            line-height: 17px;
          }
        }

        &-active {
          opacity: 1;
          filter: blur(0px);
        }
      }
      .wrap-scroll {
        position:absolute;
        bottom:50%;
        left:50%;
        z-index:2;
        height:14px;
        transform:translate(-96%, 236px);
      }
      .paging-box {
        &-wrap {
          pointer-events: none;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 146px;
        span {
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
        }
      }
      .swiper-pagination-wrap {
        pointer-events: none;
      }
      .swiper-pagination-progressbar {
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100px;
        transform-origin: 0 0;
        transform: rotate(-90deg) translate(-50%, -50%);
        background:rgba(255,255,255,.3);

        .swiper-pagination-progressbar-fill {
          background: #fff;
        }
      }
    }
  }

  // sec02
  .sec02 {
    background-color:#fff;
    .inner-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 102px 24px;
      overflow: hidden;
      background-color:#fff;
    }

    .swiper-news {
      width: 100%;
      margin: 32px 0 0 0;
      overflow: visible;

      .swiper-slide {
        width:314px;
        .card-content {
          display: block;
          padding-right: 13px;
        }

        .imgbox {
          overflow:hidden;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  // sec03
  .sec03 {
    background-color: #f5f6f8;
    .inner-section {
      padding: 70px 24px;
    }

    .scroll {
      padding: 0 20px;
    }

    .img-group {
      position: relative;
      margin-top: 32px;

      .item {
        padding-bottom: 70px;
        .card-content {
          margin:0 auto;
          max-width:327px;
          .detail {
            .title {
              font-size: 18px;
              line-height: 21px;
              font-weight: 500;
            }
            .infomation {
              .magazine {
                font-size: 16px;
                line-height: 19px;
              }
            }
          }
          /* CMS 임시 수정 */
          &.item {
            padding-bottom:0;
          }
        }
      }
    }
  }
  
  // sec04
  .sec04 {
    height: 200vh;
    background: #171717;
    .inner-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: sticky;
      top: 0;
      height: 100vh;
      height: 100dvh;
      padding: 24px 0;
      &.aos-animate {
        .tit-h2 {
          opacity: 1;
          transform: translateY(0);
          transition: 0.4s;
        }
        .swiper-card {
          &-wrap {
            opacity: 1;
            transform: translateY(0);
            transition: 0.4s 0.4s;
            .dim-left {
              right: 100%;
              transition: 0.4s 0.8s;
            }
            .dim-right {
              left: 100%;
              transition: 0.4s 0.8s;
            }
          }
        }
      }
    }
    .tit-h2 {
      width: 100%;
      color: $color-white;
      text-align: center;
      transform: translateY(100px);
      opacity: 0;
      transition: 0.4s;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 26px 20px;
      margin: 0 6.4%;
      box-sizing: border-box;
      height: 320px;
      box-sizing: border-box;
      color: $color-white;
      text-align: left;
      font-family: $font-family-2;
      text-decoration: none;

      b {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        
      }

      h3 {
        margin: 0 0 11px 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        font-family: $font-family-2;
      }

      p {
        margin: 0;
        font-size: 10px;
        line-height: 12px;
      }

      &.blue {
        background-color: #0069de;
      }

      &.black {
        background-color: #404040;
      }

      &.orange {
        background-color: #e87500;
      }
    }

    .swiper-card {
      &-wrap {
        position: relative;
        margin: 70px auto 0;
        overflow: hidden;
        opacity: 0;
        transform: translateY(100px);
        transition: 0.4s;
        .dim-left {
          position: absolute;
          top: 0;
          right: calc(100% - 64px);
          bottom: 0;
          left: 0;
          background-color: #171717;
          z-index: 2;
          pointer-events: none;
          transition: 0.4s;
        }
        .dim-right {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: calc(100% - 64px);
          background-color: #171717;
          z-index: 2;
          pointer-events: none;
          transition: 0.4s;
        }
      }
      width: 100vw;
      padding: 32px 63px;
      overflow: hidden;
      box-sizing: border-box;
    }

    .swiper-slide {
      // width: 220px;
      text-align: center;
      transition: all 0.3s ease;

      &.swiper-slide-active {
        transform: scale(1.136);
      }
    }
  }

  // sec05
  .sec05 {
    .inner-section{
      background-color:#fff;
    }
    .tit-box {
      @include flex-center(flex);
      position: sticky;
      top: 0;
      height: 100vh;
      height: 100dvh;
      pointer-events: none;
    }
    .tit-h2 {
      width: 100%;
      overflow: hidden;
      h2 {
        margin: 0 auto;
        width: 226px;
      }

      .txt-about {
        display: block;
        font-family: $font-family-2;
        font-size: 48px;
        line-height: 58px;
        color: $color-black;
        font-weight: 700;

        & + .txt-about {
          margin-top: 12px;
        }

        &:nth-child(1) {
          text-align:left
        }

        &:nth-child(2) {
          text-align: right;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: right;
        }
      }

      .txt {
        display: block;
        margin: 8px auto 0;
        width: 226px;
        font-family: $font-family-1;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .cont {
      position: relative;
      z-index: 2;
      padding: 0 20px 80px;
      box-sizing: border-box;

      .card-content {
        max-width:327px;
        margin: 0 0 80px 0;
        background: $color-white;
        .detail {
          padding-top: 24px;
        }
        .title {
          font-size: 18px;
          line-height: 28px;
          p {
            margin:0;
            padding:0;
          }
        }
        .infomation {
          margin-top: 12px;
          .txt {
            color: $color-black;
            font-size: 16px;
            line-height: 24px;
          }
        }
        &:nth-child(even) {
          margin-left:auto;
        }
        &:last-child {
          margin-bottom:0;
        }
      }
    }
    
  }

  // sec06 FOOD & BEVERAGE
  .sec06 {
    .sec06-visual {
      position: relative;
      height: 300vh;
      background-color:#fff;
    }

    .pin-wrap {
      overflow: hidden;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      position: sticky;
      height: 100vh;
      height: 100dvh;
      top: 0;
      left: 0;
      width: 100%;
    }

    .track {
      transform: translate(0%);
    }

    .track-flex {
      display: flex;
      margin-right: -100vw;
      height: 100%;
    }

    .eat-beverage {
      width: 150vw;
      height: 100vh;
      height: 100dvh;
      .img-box {
        position: relative;
        top: 0%;
        right: auto;
        bottom: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background: url(../images/main/img_eat_n_beverage_bg.png) no-repeat;
        background-position: center center;
        background-size: cover;
        transform: scale(1.5);
      }

      .txt-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100vw;
        height: 100%;
        font-weight: 700;
        color: #fff;
        text-align: center;
        h2 {
          margin: 0;
          font-size: 48px;
          font-weight: 700;
          line-height: 58px;
          text-transform: uppercase;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          font-weight: 700;
        }
      }
    }

    .horizon-scroll {
      display: flex;
      align-items: flex-start;
      margin-top:110px;
      width: 100vw;

      .inner {
        padding: 0 24px;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .tab-menu {
      overflow: visible;
      &-wrap {
        margin: 16px -24px 0;
        padding: 0 12px;
        overflow: hidden;
      }
      .swiper-wrapper {
        padding: 0;
        margin: 0;
        list-style: none;
        .swiper-slide {
          width: auto;
        }
      }

      a {
        display: block;
        font-family: $font-family-2;
        padding: 20px 0;
        margin: 0 14px;
        color: #999999;
        font-size: 20px;
        font-weight: 500;
        text-decoration: none;
        &.active {
          color: $color-black;
          font-weight: 700;
        }
        .num {
          vertical-align: super;
          font-size: 12px;
        }
      }
    }

    .tab-cont {
      display: none;
      margin: 0 -24px;
      padding: 0 20px;
      overflow: hidden;

      &.active {
        display: block;
      }
    }

    .swiper-food {
      position: relative;
      margin-top: 20px;
      overflow: visible;
    }
    .swiper-slide {
      width:295px;
    }
    .restaurant-card {
      position: relative;
      margin: 0 24px 0 8px;
      padding: 0 0 20px;
      text-decoration: none;
      .badge {
        display: block;
        position: absolute;
        top: 9px;
        left: -8px;
        margin:0;
        padding: 6px 10px;
        border-radius:0;
        color: $color-white;
        font-family: $font-family-2;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        background-color: $color-black;
      }

      .imgbox {
        overflow:hidden;
      }

      img {
        width: 100%;
        vertical-align: top;
      }

      .txt {
        margin:16px 0 0 0;
        padding-left:16px;
        color: $color-black;
        font-family: $font-family-2;
        font-weight: 700;

        .txt-floor {
          display: block;
          font-size: 16px;
          line-height:19px;
        }

        .restaurant {
          margin: 4px 0 0 0;
          font-size: 28px;
          line-height:36px;
        }
      }
    }
  }

  .sec07{
    z-index: 1;
    .last-box-wrap {
      position: relative;
      width:100%;
      height:100%;
    }

    .business_wrap {
      overflow: hidden;
      position: relative;
      margin-top: 80px;
    }

    .family_list {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 24px;
      box-sizing: border-box;
      z-index: 4;

      .list {
        display:block;
        padding: 40px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
        text-decoration: none;
        &:last-child {
          border-bottom: none;
        }

        .left {
          display: flex;
          align-items: center;
        }

        .type {
          margin: 0;
          font-family: $font-family-2;
          color: transparent;
          font-size: 40px;
          font-weight: 700;
          -webkit-text-stroke: 1px rgba(255, 255, 255, 0.32);
        }

        .txt {
          font-family: $font-family-1;
          font-size: 16px;
          font-weight: 700;
          color: #fff;
          opacity: 0.32;
        }

        &.on {
          .type {
            color: #fff;
            -webkit-text-stroke: transparent;
          }

          .txt {
            opacity: 1;
          }
        }
      }
    }

    .bg-list-div {
      position:relative;
      width:100%;
      height:100%;
      pointer-events: none;

      .item {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        width:100%;
        height:100%;
        transition: 1s linear;
        background-repeat:no-repeat;
        background-position:center center;
        background-size: 100%;
        transform:translateZ(0);
        will-change: transform;

        &.active {
          opacity: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          vertical-align: top;
        }
      }
    }

    .last-box {
      overflow-y: auto;
      background-color: #000;

      .business_wrap {
        margin: 240px 0;
      }
    }

    .ts-theme-footer {
      position: relative;
      z-index: 10;
    }
  }
  .ts-theme-footer {
    position: relative;
    z-index: 10;
  }
  // fb
  @keyframes fbImgMove {
    0% {
      transform: translateX(-100vw) scale(0.5);
    }

    25% {
      transform: translateX(-50vw) scale(0.5);
    }

    50% {
      transform: translateX(0) scale(0.5);
    }

    75% {
      transform: translateX(0) scale(0.75);
    }

    100% {
      transform: translateX(0) scale(1);
    }
  }

  @keyframes fbTxtMove {
    0% {
      transform: translate(100vw, -50%);
    }

    25% {
      transform: translate(100vw, -50%);
    }

    50% {
      transform: translate(100vw, -50%);
    }

    75% {
      transform: translate(50vw, -50%);
    }

    100% {
      transform: translate(-50%, -50%);
    }
  }

  .wait-animation {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 20%, 0);
      transform: translate3d(0, 20%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .btn-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: -1;
    border-radius: 50%;
    border:0;
    box-shadow:none;
    outline:0;
    width: 48px;
    height: 48px;
    color: $color-white;
    font-family: $font-family-2;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;
    background-color: $color-black;
    opacity: 0;

    i {
      position: relative;
      display: block;
      margin: 4px 0 0 0;
      padding-top: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 8px;
        height: 8px;
        box-sizing: border-box;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: $color-white;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &.on {
      opacity: 1;
      z-index: 12;
    }

    &.reverse-color {
      color: $color-black;
      background-color: $color-white;
      transition:all 0.3s;
      i {
        &::before {
          border-color: $color-black;
        }
      }
    }
  }
}
