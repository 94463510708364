&introduce-content {
  position: relative;
  z-index: 1;
  margin: 0 -24px;
  width: calc(100% + 48px);
  font-family: $font-family-2;

  .scroll-bigger-box {
    position: relative;
    margin: 0 auto;
    height: 100vh;
    height: 100dvh;
    margin: 0 auto;

    .scroll-text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 320px;
      transform: translate(-50%, -50%);

      .scroll-title {
        margin: 0;
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 48px;
        font-weight: 700;

        span {
          display: block;

          &.scene01 {
            opacity: 1;
          }

          &.scene02 {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            font-size: 64px;
            transform: translateY(-50%);

            b {
              display: block;
              font-family: $font-family-2;
              text-transform: uppercase;

              &:nth-child(1) {
                text-align: left;
                opacity: 0;
                transform: translateX(-10%);
              }

              &:nth-child(2) {
                text-align: right;
                opacity: 0;
                transform: translateX(20%);
              }

              &:nth-child(3) {
                text-align: center;
                opacity: 0;
                transform: translateX(-10%);
              }
            }
          }
        }
      }
    }

    .bg {
      display: block;
      margin: 0 auto;
      width: 271px;
      height: 100%;
      box-shadow: inset 2000rem 2000rem rgba(0, 0, 0, .3);
      background: url(../images/sub/img_bg_timessquare_introduction.png) no-repeat center center;
      background-size: cover;
    }
  }
}

&section-about {
  .inner-section {
    .about-title {
      position: sticky;
      top: 50%;
      padding: 0;
      margin: 0;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      font-family: $font-family-2;
      transform: translateY(-50%);

      .flex-div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .ico-dot {
        margin: 0 10px;
        width: 6px;
        height: 6px;
        background: #000;
        display: inline-block;
        transform: rotate(45deg);
      }

      .an-font {
        font-weight: 300;
        letter-spacing: 3.84px;
      }

      .txt-about {
        &:nth-child(1) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }

        &:nth-child(3) {
          opacity: 0;
          transform: translateY(200%);
          transition-property: opacity, transform;
          transition-duration: .3s;
        }
      }
    }

    .about-gallery {
      position: relative;
      z-index: 2;
      margin: 70vh 0 0 0;
      font-family: $font-family-2;

      .gallery-inner-wrap {
        background-color: $color-white;

        .scroll-wrap {
          .scroll-item {
            margin-bottom: 96px;

            .img-box {
              width: 100%;
              height: auto;
              img {
                display:block;
                width:100%;
              }
            }

            .text-box {
              .scroll-title {
                padding: 0;
                margin: 20px 0 0 0;
                font-size: 20px;
              }

              .scroll-desc {
                padding: 0;
                margin: 12px 0 0 0;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  &.active {
    .about-title {
      .txt-about {
        &:nth-child(1) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .3s;
          transition-duration: 1s;
        }

        &:nth-child(2) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .35s;
          transition-duration: 1s;
        }

        &:nth-child(3) {
          opacity: 1;
          transform: translateY(0);
          transition-property: opacity, transform;
          transition-delay: .4s;
          transition-duration: 1s;
        }
      }
    }
  }
}

&section-character {
  margin: 0 -24px;
  padding:96px 24px;
  box-sizing:border-box;
  font-family: $font-family-2;
  text-align:center;
  background-color: #F5F6F8;
  .title {
    margin:0;
    font-size:40px;
    line-height:52px;
  }

  .img-visual {
    margin:56px auto 0;
    padding:0 24px;
    box-sizing:border-box;
    width:100%;
    img {
      display:block;
      width:100%;
    }
  }

  .box-text {
    margin: 56px 0 0 0;
    padding: 0 24px;
    box-sizing:border-box;
    .subtitle,
    .name {
      display:block;
    }
    .subtitle {
      font-size:32px;
      font-weight:300;
      line-height:39px;
    }
    .name {
      margin:8px 0 0 0;
      color:$color-p;
      font-size:40px;
      font-weight:700;
      line-height:48px;
    }
    .text {
      margin:20px 0 0 0;
      font-size:16px;
      font-weight:300;
      line-height:24px;
    }
  }

  .box-tab-character {
    margin: 56px 0 0 0 ;
    padding: 48px 0;
    box-sizing:border-box;
    background-color: $color-white;
    
    .wrap-tab {
      overflow-x:scroll;
      overflow-y:auto;
      white-space: nowrap;
      -ms-overflow-style:none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .ts-theme-tabbox {
      margin:0 0 0 32px;
      padding:8px 0 0;
      box-sizing:border-box;
      background-color: $color-white;
      li {
        margin:0;
        & + li {
          margin:0 0 0 32px;
        }
        &:last-child {
          padding-right:32px;
        }
      }
    }
    button {
      display:block;
      margin:0;
      padding:0;
      font-size:18px;
      font-weight:300;
      line-height:1;
      .img-tab-button {
        display:block;
        position:relative;
        margin:0 auto;
        border-radius: 50%;
        width:64px;
        height:64px;
        background-color:#F5F6F8;
        img {
          position:relative;
          z-index: 2;
          width:100%;
        }
        &:before,
        &:after {
          content:"";
          display:block;
          position:absolute;
          border-radius: 50%;
        }
        &:before {
          top:-2px;
          left:-2px;
          z-index:1;
          width:64px;
          height:64px;
          border:2px solid $color-white;
          background-color:#F5F6F8
        }
        &:after {
          top:-4px;
          left:-4px;
          width:72px;
          height:72px;
        }
        &.tomnova{
          & + figcaption{
            width:79px;
          }
        }
        &.miamally{
          & + figcaption{
            width:83px;
          }
        }
        &.pieglee{
          & + figcaption{
            width:64px;
          }
        }
        &.joyartnet{
          & + figcaption{
            width:89px;
          }
        }
        &.lukamilo{
          & + figcaption{
            width:83px;
          }
        }
      }
      figcaption {
        margin:12px 0 0 0;
        font-family: $font-family-2;
      }
      &.active {
        background-color:transparent;
        color:$color-black;
        font-weight:700;
        .img-tab-button {
          &.tomnova{
            background-color:#EE9C21;
            &:before,
            &:after{
              background-color:#EE9C21;
            }
          }
          &.miamally{
            background-color:#FF80BD;
            &:before,
            &:after{
              background-color:#FF80BD;
            }
          }
          &.pieglee{
            background-color:#FF313B;
            &:before,
            &:after{
              background-color:#FF313B;
            }
          }
          &.joyartnet{
            background-color:#00A1F0;
            &:before,
            &:after{
              background-color:#00A1F0;
            }
          }
          &.lukamilo{
            background-color:#CA7AEC;
            &:before,
            &:after{
              background-color:#CA7AEC;
            }
          }
        }
      }
    }
    .swiper-character {
      margin:56px 0 0;
      padding: 0;
      .character-tab-contents {
        figure {
          display:block;
          margin:0 auto;
          border-radius:50%;
          width:240px;
          height:240px;
          img {
            display:block;
            width:100%;
          }
        }
        figcaption {
          margin:20px 0 0 0;
          color:$color-black;
          font-family: $font-family-2;
          font-size:40px;
          font-weight: 700;
          line-height:48px;
        }
        span {
          display:block;
          margin:8px 0 0 0;
          font-size:20px;
          font-weight: 700;
          line-height:30px;
        }
        &.tomnova{
          figure {
            background-color:#EE9C21;
          }
        }
        &.miamally{
          figure {
            background-color:#FF80BD;
          }
        }
        &.pieglee{
          figure {
            background-color:#FF313B;
          }
        }
        &.joyartnet{
          figure {
            background-color:#00A1F0;
          }
        }
        &.lukamilo{
          figure {
            background-color:#CA7AEC;
          }
        }
      }
    }
  }
  & + .ts-theme-section-ci {
    background-color: transparent;
    .ts-theme-tabbox {
      background-color:$color-white;
    }
  }
}

&section-ci {
  margin: 0 -24px -32px;
  padding: 98px 0px 96px;
  font-family: $font-family-2;
  background: #F5F6F8;

  .inner-section {
    .ci-title {
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
      font-family: $font-family-2;
      padding: 0 0 56px 0;
      margin: 0;
    }

    .ci-tab-area {
      margin: 0 0 32px 0;
      .tab-wrap {
        margin: 0;
        overflow-x: scroll;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        .ts-theme-tabbox {
          padding: 0 0 0 40px;
          li {
            flex: 0 0 auto;
            a {
              position: relative;
              height: 37px;
              line-height: 37px;
              padding: 0;
              margin: 0 40px 0 0;
              box-sizing: border-box;
              color: #000;
              font-family: $font-family-2;
              font-weight: 500;
              font-size: 18px;
              &.active {
                color: #fff;
                background-color: unset;
                font-weight: 700;
                &::before {
                  --spacing: 16px;
                  content: "";
                  position: absolute;
                  left: calc(var(--spacing) * -1);
                  top: 0;
                  z-index: -1;
                  width: calc(100% + var(--spacing) * 2);
                  height: 100%;
                  background-color: #1e75ff;
                  border-radius: 12px;
                }
              }
            }

          }
        }
      }

      // .ci-tab-contents {
      //   display: none;

      //   &.active {
      //     display: block;
      //   }

      //   .tab-desc {
      //     text-align: center;
      //     font-size: 16px;
      //     font-weight: 300;
      //     padding: 0;
      //     margin: 32px 0 0 0;
      //   }
      // }
    }

    .swiper-ci {
      padding: 0;
      .ci-tab-contents {
        padding: 0 24px;
        box-sizing: border-box;
      }
      .swiper-pagination {
        overflow-x:scroll;
        top: 0;
        bottom: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .swiper-pagination-bullet {
          display: inline-flex;
          padding: 7px 16px 9px;
          border-radius: 12px;
          box-sizing: border-box;
          width: auto;
          height: auto;
          color: $color-black;
          font-family: $font-family-2;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          background: transparent;
          opacity:1;

          &.swiper-pagination-bullet-active {
            color: $color-white;
            background: $color-p;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .ci-box {
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 154px;
      }

      .tab-desc {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        padding: 0;
        margin: 32px 0 0 0;
      }

      &.ci-symbol {
        .img-box {
          background: url(../images/sub/img_about_cl_grid.png) repeat center top;
          background-size: 325px;
        }
        picture {
          & + picture {
            margin-left: 53px;
          }
        }
        img {
          display: block;
          width: 64px;

          &+img {
            margin-left: 53px;
          }
        }
      }

      &.ci-primary {
        .img-box {
          flex-direction: column;
          height: 226px;
          background: url(../images/sub/img_about_cl_grid2.png) repeat center top;
          background-size:375px;
        }
        picture {
          & + picture {
            margin: 43px 0 0 0;
          }
        }
        img {
          display: block;
          width: 147px;

          &+img {
            margin: 43px 0 0 0;
          }
        }
      }

      &.ci-color {
        .img-box {
          display: block;
          padding: 40px 24px;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          height: auto;
          background-color: #fff;

          .box-color {
            display: flex;
            align-items: center;

            .color-box {
              flex-shrink: 0;
              width: 72px;
              height: 72px;
            }

            .text-box {
              margin: 0 0 0 12px;
            }

            .name {
              display: block;
              color: $color-black;
              font-family: $font-family-2;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              text-transform: uppercase;
            }

            .panton {
              display: block;
              margin: 8px 0 0 0;
              color: $color-black;
              font-family: $font-family-2;
              font-size: 16px;
              font-weight: 300;
              line-height: 16px;

              span {
                display: block;

                &+span {
                  margin: 8px 0 0 0;
                }
              }
            }

            &.blue {
              .color-box {
                background-color: #004A9C;
              }
            }

            &.gray {
              .color-box {
                background-color: #7E7F83;
              }
            }

            &.gold {
              .color-box {
                background-color: #997F42;
              }
            }

            &.black {
              .color-box {
                background-color: #221E1F;
              }
            }

            &+.box-color {
              margin: 40px 0 0 0;
            }
          }
        }
      }
    }
  }
}