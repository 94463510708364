&facilities-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $font-family-2;

  li {
    &+li {
      margin: 24px 0 0 0;
    }

    a {
      display: block;
      text-decoration: none;
    }

    i {
      font-style: normal;
    }

    .inbox {
      display: block;
      padding: 24px;
      border: 1px solid #eaeaea;
      box-sizing: border-box;

      .icon {
        display: block;
        width: 48px;
        height: 48px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .box-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 24px 0 0 0;

        .tit {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          color: $color-black;
          font-size: 20px;
          font-weight: 700;

          b {
            flex-shrink: 0;
          }

          .wrap-floor {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-left: 4px;

            .floor {
              display: block;
              margin: 2px;
              padding: 3px 8px;
              border: 1px solid $color-black;
              border-radius: 16px;
              box-sizing: border-box;
              min-width: 32px;
              height: 20px;
              color: inherit;
              font-size: 12px;
              font-family: $font-family-2;
              line-height: 1;
              text-align: center;
              text-decoration: none;

              &.ts-m-1 {
                margin: 2px 1px;
              }
            }
          }
        }

        .btn-location {
          display: block;
          margin: 0;
          padding: 0;
          width: 24px;
          height: 24px;
          border: 0;
          background-color: transparent;
          flex-shrink: 0;
          align-self: center;

          .ico-map {
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(../images/common/ico_map.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }

      [class^="item"] {
        display: flex;
        align-items: flex-start;
        margin: 0;

        .title {
          padding: 0 0 0 20px;
          width: 88px;
          color: $color-black;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          background-repeat: no-repeat;
          background-position: left top 3px;
          background-size: 16px;
        }

        .detail {
          display: block;
          margin: 0;
          width: calc(100% - 88px);
          color: $color-black;
          font-family: $font-family-2;
          font-size: 16px;
          line-height: 24px;

          .number {
            display: flex;
            align-items: center;

            span {
              font-family: $font-family-2;
              color: $color-black;
            }

            .icon {
              margin: 0 0 0 4px;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              background: url(../images/common/ico_phone02.svg) no-repeat center center/contain;
            }

            &+.number {
              margin: 12px 0 0 0;
            }
          }

          p {
            margin: 0;
            padding: 0;

            &+p {
              margin-top: 12px;
            }
          }
        }

        &+[class^="item"] {
          margin: 16px 0 0 0;
        }

        &:first-of-type {
          margin: 20px 0 0 0;
        }
      }

      .item-call {
        .title {
          background-image: url(../images/common/ico_tel.svg);
        }
      }

      .item-working-time {
        .title {
          background-image: url(../images/common/ico_time.svg);
        }

        .time {
          font-family: $font-family-2;
        }

        .info {
          color: #666666;
          font-family: $font-family-1;
          font-size: 14px;
          line-height: 16px;
        }
      }

      .item-location {
        .title {
          background-image: url(../images/common/ico_location_pin.svg);
        }

        .detail {
          word-break: keep-all;
        }
      }
    }

    // svg
    .icon svg path {
      stroke: white;
      transition: all 1.5s linear;
    }

    .icon svg line {
      stroke: #000;
      transition: all 1.5s linear;
    }

    &.svg01 {
      path {
        stroke-width: 4;
        stroke-dasharray: 332;
      }
    }

    &.svg02 {
      path {
        stroke-width: 4;
        stroke-dasharray: 212;
      }
    }

    &.svg03 {
      path {
        stroke-width: 4;
        stroke-dasharray: 196;
      }
    }

    &.svg04 {
      path {
        stroke-width: 8;
        stroke-dasharray: 290;
      }
    }

    &.svg05 {
      path {
        stroke-width: 5;
        stroke-dasharray: 256;
      }
    }

    &.svg06 {
      path {
        stroke-width: 4;
        stroke-dasharray: 128;
      }
    }

    &.svg07 {
      path {
        stroke-width: 4;
        stroke-dasharray: 99;
      }
    }

    &.svg08 {
      path {
        stroke-width: 8;
        stroke-dasharray: 220;
      }
    }

    &.svg09 {
      path {
        stroke-width: 4;
        stroke-dasharray: 228;
      }
    }

    &.svg10 {
      path {
        stroke-width: 4;
        stroke-dasharray: 130;
      }
    }

    &.svg11 {
      path {
        stroke-width: 4;
        stroke-dasharray: 216;
      }
    }

    &.svg12 {
      path {
        stroke-width: 4;
        stroke-dasharray: 246;
      }
    }

    &.svg13 {
      svg {
        line {
          fill: none;
          stroke: #000;
          stroke-width: 4;
          stroke-dasharray: 0;
        }

        path {
          fill: none;
          stroke: #000;
          stroke-width: 4;
          stroke-dasharray: 111;
        }
      }
    }

    &.aos-animate {
      &.svg01 {
        path {
          stroke-dashoffset: 332;
        }
      }

      &.svg02 {
        path {
          stroke-dashoffset: 212;
        }
      }

      &.svg03 {
        path {
          stroke-dashoffset: 196;
        }
      }

      &.svg04 {
        path {
          stroke-dashoffset: 290;
        }
      }

      &.svg05 {
        path {
          stroke-dashoffset: 256;
        }
      }

      &.svg06 {
        path {
          stroke-dashoffset: 128;
        }
      }

      &.svg07 {
        path {
          stroke-dashoffset: 99;
        }
      }

      &.svg08 {
        path {
          stroke-dashoffset: 220;
        }
      }

      &.svg09 {
        path {
          stroke-dashoffset: 228;
        }
      }

      &.svg10 {
        path {
          stroke-dashoffset: 130;
        }
      }

      &.svg11 {
        path {
          stroke-dashoffset: 216;
        }
      }

      &.svg12 {
        path {
          stroke-dashoffset: 246;
        }
      }

      &.svg13 {
        svg {
          line {
            stroke-dashoffset: 0;
          }

          path {
            stroke-dashoffset: 223;
          }
        }
      }
    }
  }

  &.lang-en {
    li {
      .inbox {
        .box-header {
          .tit {
            display: block;

            .wrap-floor {
              margin-left: 0;

              .floor {
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }

          .btn-location {
            align-self: flex-start;
            margin: 4px 0 0;
          }
        }
      }
    }
  }
}