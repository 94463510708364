/***** layout *****/
&wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: $color-white;
}

&open-allmenu {
  overflow: hidden !important;
}
&header {
  position: relative;
  height: 80px;
  // 전체메뉴
  .all-menu {
    margin: 40px 0;
    padding: 0 24px;
    list-style: none;
    font-family: $font-family-2;
    > li {
      &.in-ul {
        > a {
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            background: url(../images/common/ico_arrow_down.svg) no-repeat center/contain;
            transition: 0.2s;
          }
          &.on {
            color: $color-p;
            font-weight: 700;
            &::before {
              transform: rotate(180deg);
            }
            + .depth {
              max-height: 800px;
              transition: max-height 0.5s ease-in-out;
            }
          }
        }
      }
      > a {
        display: block;
        position: relative;
        padding: 10px 32px 10px 0;
        color: inherit;
        font-size: 22px;
        line-height: 28px;
        font-weight: 300;
        text-decoration: none;
      }
      > .depth {
        max-height: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        > li {
          &:last-child {
            margin-bottom: 30px;
          }
          > a {
            display:block;
            padding:8px 0;
            color: #666;
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
            text-decoration: none;
          }
        }
      }
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-white;
      z-index: 999;
      transform: translateX(101%);
      transition: 0.3s;
      .ts-theme-open-allmenu & {
        transform: translateX(0);
      }
    }
    &-head {
      flex: none;
      display: flex;
      align-items: center;
      position: relative;
      height: 80px;
      padding: 0 24px;
      .all-menu-logo {
        display: inline-flex;
        margin: 0 auto 0 0;
        a {
          display:block;
          width: 78px;
          height: 28px;
          background: url(../images/common/logo.svg) no-repeat center/contain;
          text-indent: -9999px;
          overflow: hidden;
        }
        &.stream {
          a {
            background-image: url(../images/common/stream_logo.svg);
          }
        }
        &.terrace {
          a {
            background-image: url(../images/common/terrace_logo.svg);
          }
        }
        &.exitHongdae {
          a {
            background-image: url(../images/common/exit_logo.svg);
          }
        }
      }
      .btn-login {
        flex: none;
        width: 24px;
        height: 24px;
        margin-left: 12px;
        border: none;
        background: url(../images/common/btn_login.svg) no-repeat center/contain;
      }
      .btn-logout {
        flex: none;
        width: 24px;
        height: 24px;
        margin-left: 12px;
        border: none;
        background: url(../images/common/ico_logout.svg) no-repeat center/contain;
      }
      .btn-close {
        flex: none;
        width: 24px;
        height: 24px;
        margin-left: 12px;
        border: none;
        background: url(../images/common/ico_24_close.svg) no-repeat center/contain;
      }
      // 로그아웃버튼 삭제 20240819
      #sign-out {
        display:none;
      }
    }
    &-con {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      overflow: auto;
      .all-menu-banner {
        display:none;
        margin: 0 24px;
        a {
          display:block;
        }
        img {
          width: 100%;
          border-radius: 8px;
          vertical-align: top;
        }
      }
      .box-search {
        position: relative;
        margin: 24px 24px 0;
        .input-search {
          width: 100%;
          height: 48px;
          margin: 0;
          padding: 0 48px 0 16px;
          border: none;
          border-radius: 8px;
          background: #f8f9fa;
          font-size: 14px;
          font-weight: 300;
          box-sizing: border-box;
          outline: none;
          &::-ms-clear,
          &::-ms-reveal {
            display: none;
            appearance: none;
          }

          &::-webkit-search-decoration,
          &::-webkit-search-cancel-button,
          &::-webkit-search-results-button,
          &::-webkit-search-results-decoration {
            display: none;
            -webkit-appearance: none;
          }
          &::-webkit-search-cancel-button {
            display: none;
            -webkit-appearance: none;
          }
        }
        [class^="btn"] {
          position: absolute;
          top: 50%;
          right: 16px;
          margin:0;
          padding:0;
          width: 24px;
          height: 24px;
          border: none;
          transform: translateY(-50%);
        }
        .btn-search {
          z-index: 5;
          background: url(../images/common/ico_search.svg) no-repeat center/contain;
        }
        .btn-clear {
          display:none;
          z-index: 5;
          background: #f8f9fa url(../images/common/btn_ico_del.svg) no-repeat center/contain;
        }

        .portlet-search-bar{
          .search-bar-keywords-input-wrapper {
            overflow: hidden;
            margin:0;
            border-radius: 8px;
            width:100%;
          }
        }
      }
      .all-menu-links {
        // display: flex;
        display:none;
        margin: 24px 24px 0;
        .inbox {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 8px;
          border-radius: 12px;
          background-color: #f5f6f8;
          color: inherit;
          text-decoration: none;
          + .inbox {
            margin-left: 6px;
          }
          .ico {
            position: relative;
            width: 32px;
            height: 32px;
            img {
              width: 100%;
              vertical-align: top;
            }
            .cnt {
              position: absolute;
              top: -2px;
              right: -9px;
              padding: 0 4px;
              border: 1px solid $color-p;
              border-radius: 8px;
              background-color: #e2edff;
              color: $color-p;
              font-size: 10px;
              line-height: 14px;
              font-weight: 700;
            }
          }
          .txt {
            margin-top: 9px;
            font-size: 14px;
            line-height: 16px;
          }
        }
        & + .all-menu {
          margin: 48px 0 32px;
        }
      }
      .all-menu-gray {
        display:none;
        margin:16px 0 48px;
        padding:0 24px;
        box-sizing:border-box;
        a {
          display:flex;
          align-items:center;
          padding:14px 12px 14px 16px;
          border-radius: 8px;
          box-sizing: border-box;
          width: 100%;
          color: $color-black;
          text-decoration: none;
          background-color: #F5F6F8;
        }
        .ico-instagram {
          display:block;
          flex:none;
          margin-right:8px;
          width: 24px;
          height: 24px;
          background:url(../images/common/ico_instagram.svg) no-repeat center center/20px 20px;
        }
        span {
          flex:1;
          font-family: $font-family-2;
          color: $color-black;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
        .ico-arrow {
          display:block;
          flex:none;
          width: 24px;
          height: 24px;
          background:url(../images/common/ico_arrow_header.svg) no-repeat center center/24px 24px;
        }
      }
      .all-menu-footer {
        display:none;
        list-style: none;
        margin: 32px 0 48px;
        padding:0 24px;
        a {
          display: block;
          color: $color-black;
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          text-decoration: none;
        }
        li {
          + li {
            margin-top: 18px;
          }
        }
      }
    }
    &-lang {
      padding:24px;
      box-sizing:border-box;
      .box-language {
        position: relative;
        margin: 0;
        padding: 0;

        .btn-select {
            display: block;
            position: relative;
            margin: 0;
            padding: 9px 44px 9px 15px;
            border-width: 1px;
            border-style: solid;
            border-color: $color-black;
            border-radius: 48px;
            box-shadow: none;
            width:100%;
            color: $color-black;
            font-family: $font-family-1;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-align:left;
            background-color: transparent;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 16px;
                width: 16px;
                height: 16px;
                background: url(../images/common/ico_lang_down.svg) no-repeat center/contain;
                transform: translateY(-50%);
            }

            &.active {
                border-width: 0 1px 1px 1px;
                border-radius: 0 0 16px 16px;

                &::before {
                    transform: translateY(-50%) rotate(180deg);
                }

                &+.list-language {
                    display: block;
                }
            }
        }

        .list-language {
            display: none;
            position: absolute;
            bottom: 33px;
            left: 0;
            z-index: 1;
            margin: 0;
            padding: 9px 0 0 0;
            border-width: 1px 1px 0 1px;
            border-style: solid;
            border-color: $color-black;
            border-radius: 16px 16px 0 0;
            box-sizing: border-box;
            width: 100%;
            list-style: none;
            background-color: $color-white;

            .link {
                display: block;
                padding: 4px 15px;
                color: $color-black;
                font-size: 14px;
                line-height: 16px;
                text-decoration: none;
            }
        }
      }
    }
  }
  // head
  .header-con {
    display: flex;
    align-items: center;
    position: relative;
    height: inherit;
    padding: 0 24px;
    z-index: 99;
    &.fix {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: $color-white;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      .logo {
        a {
          background-image: url(../images/common/logo.svg);
        }
        &.stream {
          a {
            background-image: url(../images/common/stream_logo.svg);
          }
        }
        &.terrace {
          a {
            background-image: url(../images/common/terrace_logo.svg);
          }
        }
        &.exitHongdae {
          a {
            background-image: url(../images/common/exit_logo.svg);
          }
        }
      }
      .btn-home {
        background-image: url(../images/common/ico_home.svg);
      }
      /* CMS 임시 수정 */
      .btn-user,
      .btn-my {
        background-image: url(../images/common/btn_my.svg)
      }
      .btn-menu {
        background-image: url(../images/common/ico_menu.svg);
      }
      .btn-back {
        background-image: url(../images/common/ico_back.svg);
      }
      .btn-alarm {
        background-image: url(../images/common/ico_alarm.svg);
      }
      .btn-family-drop {
        background-image: url(../images/common/ico_header_app_family_black.svg);
      }

      .title {
        color: $color-black;
      }
    }
    &.hide, &.head-hide  {
      transform: translateY(-100%);
      transition: 0s;
    }
  }
  .logo {
    display: inline-flex;
    a {
      width: 78px;
      height: 28px;
      margin: 0;
      background: url(../images/common/logo.svg) no-repeat center/contain;
      text-indent: -9999px;
      overflow: hidden;
    }
    &.stream {
      a {
        background-image: url(../images/common/stream_logo.svg);
      }
    }
    &.terrace {
      a {
        background-image: url(../images/common/terrace_logo.svg);
      }
    }
    &.exitHongdae {
      a {
        background-image: url(../images/common/exit_logo.svg);
      }
    }
  }
  .btn-home {
    position: absolute;
    top: 50%;
    right: 64px;
    width: 24px;
    height: 24px;
    border: none;
    background: url(../images/common/ico_home.svg) no-repeat center/contain;
    transform: translateY(-50%);
  }
  .btn-menu {
    flex: none;
    width: 24px;
    height: 24px;
    margin-left: auto;
    border: none;
    background: url(../images/common/ico_menu.svg) no-repeat center/contain;
  }
  .btn-back {
    display:none;
    flex: none;
    width: 24px;
    height: 24px;
    border: none;
    background: url(../images/common/ico_back.svg) no-repeat center/contain;
  }
  .btn-alarm {
    display:none;
    flex: none;
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: auto;
    border: none;
    background: url(../images/common/ico_alarm.svg) no-repeat center/contain;
    &.on::before {
      content: "";
      position: absolute;
      top: 3px;
      right: 2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ff2e2e;
    }
  }

  /* CMS 임시 수정 */
  .btn-user,
  .btn-my {
    flex: none;
    width: 24px;
    height: 24px;
    border: none;
    margin-left:12px;
    background: url(../images/common/btn_my.svg) no-repeat center/contain;
  }

  .title {
    // display: flex;
    display:none;
    flex: 1;
    justify-content: center;
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    .cnt {
      height: 24px;
      padding: 0 7px;
      margin-left: 4px;
      border: 1px solid $color-p;
      border-radius: 12px;
      background-color: #e2edff;
      color: $color-p;
      font-size: 12px;
      line-height: 22px;
      font-weight: 900;
      box-sizing: border-box;
    }
  }

  // subpage
  &.sub {
    .header-con {
      .logo {
        display:none;
      }
      .title {
        display:flex;
      }
      .btn-back {
        display:block;
      }
      .btn-alarm {
        display:none;
      }
    }
  }

  &.sub_my {
    .logo {
      display:none;
    }
    .btn-back {
      display:block;
    }
    .title {
      display:flex;
    }
    .btn-alarm {
      display:block;
    }
    .btn-menu {
      display:none;
    }
  }

  // app
  .btn-family-drop {
    // display:block;
    display:none;
    margin: 0 0 0 12px;
    padding: 0;
    border: 0;
    width: 24px;
    height: 24px;
    background:transparent url(../images/common/ico_header_app_family_black.svg) no-repeat center center/contain;
    &.active {
      transform:rotate(180deg);
    }
  }
  .family-site-wrap {
    // display:block;
    display:none;
    position: fixed;
    top:-100%;
    left:0;
    z-index: 100;
    border-radius:0 0 16px 16px;
    box-shadow: 0 6px 6px rgba(0,0,0,.16);
    width:100%;
    background-color:$color-white;
    transition:all 0.3s;
    .links-wrap {
      margin:0;
      padding:0;
      list-style: none;
      li {
        & + li {
          border-top:1px solid #E0E0E0;
        }
        .link {
          display:block;
          padding:25px 24px 23px;
          color: $color-black;
          font-family:$font-family-2;
          font-size:24px;
          font-weight:300;
          line-height:29px;
          text-decoration: none;
          .ico-arrow {
            display:none;
            margin:0 0 0 12px;
            position: relative;
            vertical-align: top;
            width:24px;
            height:29px;
            &:before {
              content:"";
              display:block;
              position:absolute;
              top:50%;
              left:50%;
              width:24px;
              height:24px;
              background:url(../images/common/ico_header_app_shortcut_blue.svg) no-repeat center center/contain;
              transform:translate(-50%,-50%);
            }
          }
          .ico-exit {
            display:inline-block;
            position:relative;
            margin:0 2px;
            width:2px;
            height:16px;
            &:before,
            &:after {
              content:"";
              display:block;
              position:absolute;
              left:0;
              width:2px;
              height:5px;
              background-color:$color-black;
            }
            &:before {
              top:0;
            }
            &:after {
              bottom:0;
            }
          }
          &.on {
            color: $color-p;
            font-weight:700;
            .ico-exit {
              &:before,
              &:after {
                background-color:$color-p;
              }
            }
            .ico-arrow {
              display:inline-block;
            }
          }
        }
      }
    }
    &.active {
      top:80px;
      background-color:$color-white;
      transition:all 0.3s;
    }
  }
  &.ver-white {
    .logo {
      a {
        background-image: url(../images/common/logo_white.svg);
      }
      &.stream {
        a {
          background-image: url(../images/common/stream_logo_white.svg);
        }
      }
      &.terrace {
        a {
          background-image: url(../images/common/terrace_logo_white.svg);
        }
      }
      &.exitHongdae {
        a {
          background-image: url(../images/common/exit_logo_white.svg);
        }
      }
    }
    .btn-home {
      background-image: url(../images/common/ico_home_white.svg);
    }
    .btn-menu {
      background-image: url(../images/common/ico_menu_white.svg);
    }
    .btn-back {
      background-image: url(../images/common/ico_back_white.svg);
    }
    .btn-alarm {
      background-image: url(../images/common/ico_alarm_white.svg);
    }
    .btn-family-drop {
      background-image: url(../images/common/ico_header_app_family_white.svg);
    }
    .title {
      color: $color-white;
    }
  }

  &.app {
    .btn-family-drop {
      display:block;
    }
    .family-site-wrap {
      display:block;
    }
    .box-search {
      .input-search {
        outline:0;
        border: 1px solid #d9d9d9;
        background: $color-white;
      }
      .btn-clear {
        background: #fff url(../images/common/btn_ico_del.svg) no-repeat center/contain;
      }
    }
    .all-menu-links {
      display:flex;
    }
    .all-menu-gray {
      display:block;
    }
    .all-menu-banner {
      display:block;
    }
    .all-menu-footer {
      display:block;
    }
    .all-menu-lang {
      display:none;
    }
    .btn-home {
      display:none;
    }
    .header-con {
      .title {
        display:none;
      }
      .btn-back {
        display:none;
      }
      .btn-alarm {
        display:none;
      }
    }
    &.sub {
      .header-con {
        .btn-family-drop {
          display:none;
        }
        .logo {
          display:none;
        }
        .title {
          display:flex;
        }
        .btn-back {
          display:block;
        }
        .btn-alarm {
          display:none;
        }
      }
    }
  
    &.sub_my {
      .btn-family-drop {
        display:none;
      }
      .logo {
        display:none;
      }
      .btn-back {
        display:block;
      }
      .title {
        display:flex;
      }
      .btn-alarm {
        display:block;
      }
      .btn-menu {
        display:none;
      }
    }

    & + .ts-theme-container {
      .ts-theme-my-main {
        padding-bottom:0;
      }
    }
  }
}


&container {
  display: flex;
  flex-direction: column;
  padding: 56px 24px 24px;
}

&footer {
  position:relative;
  z-index: 11;
  padding: 48px 24px;
  background-color: $color-black;
  color: $color-white;
  font-family: $font-family-2;
  .foot-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: -12px 0 0 -24px;
    list-style: none;
    > li {
      margin: 12px 0 0 24px;
      > a {
        display: block;
        color: $color-white;
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .foot-familysite {
    margin-top: 44px;
    > .tit {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      opacity: 0.4;
    }
    > .list {
      padding: 4px 0 0 0;
      margin: 0;
      list-style: none;
      > li {
        margin-top: 5px;
        > a {
          display: inline-flex;
          align-items: center;
          color: $color-white;
          font-size: 20px;
          line-height: 27px;
          font-weight: 700;
          text-decoration: none;
          .colon {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: 3px;
            height: 15px;
            margin: 0 2px;
            &::before,
            &::after {
              content: "";
              width: 3px;
              height: 5px;
              background: $color-white;
            }
          }
        }
      }
    }
  }
  .foot-con {
    margin-top: 40px;
    font-size: 12px;
    line-height: 14px;
    // opacity: 0.4;
    .links {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: -8px 0 0 -16px;
      list-style: none;
      > li {
        margin: 8px 0 0 16px;
        > a {
          display: block;
          color: $color-white;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
    .txt_address {
      margin: 24px 0 0 0;
      font-weight: 300;
      opacity: 0.4;
    }
    .txt_info {
      margin: 9px 0 0 0;
      font-weight: 300;
      opacity: 0.4;
      .ib + .ib {
        margin-left: 16px;
      }
    }
    .copyright {
      margin: 14px 0 0 0;
      font-size: 10px;
      line-height: 14px;
      font-weight: 300;
      opacity: 0.4;
    }
  }
}

&btm-fixbox {
  height: 104px;
  .ts-theme-btnstyle-box {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: inherit;
    padding: 0 24px 24px;
    box-sizing: border-box;
    z-index: 101;
  }
}

// nav bar
&navbar {
  height: 80px;
  ul {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.88);
    list-style: none;
    z-index: 99;
    transition: .3s ease;
    &.hide {
      transform: translateY(100%);
    }
    > li {
      flex: 1;
      > a {
        @include flex-center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-decoration: none;
        opacity: 0.48;
        &.active {
          font-weight: 600;
          opacity: 1;
        }
        > img {
          width: 24px;
          height: 24px;
          margin-bottom: 5px;
          vertical-align: top;
        }
      }
    }
  }
}
