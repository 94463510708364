/* form */
&form {
  .form-title {
    display: block;
    margin: 48px 0 0;
    font-size: 18px;
    font-weight: 700;

    &~.form-title {
      margin-top: 56px;
    }
  }

  .form-desc {
    margin: 12px 0;
    font-size: 16px;
    font-weight: 300;
    word-break: keep-all;
  }

  .label-text {
    display: block;
    margin: 24px 0 12px;
    font-size: 14px;
    font-weight: 600;
  }

  .input-group {
    display: flex;
    align-items: center;

    &+.input-group {
      margin-top: 8px;
    }

    &>* {
      flex: 1;

      &+* {
        margin-left: 8px;
      }
    }

    &+.label-text {
      margin-top: 32px;
    }
  }

  .pw-box {
    display: flex;
    position: relative;

    &>* {
      flex: 1;
    }

    .input-text {
      padding-right: 48px;
    }

    .btn-pwview {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      border: none;
      @include bg-inline;
      background-color: transparent;
      background-image: url(../images/common/ico_eye_off.svg);
      transform: translateY(-50%);
      cursor: pointer;
    }

    :not([type="password"])+.btn-pwview {
      background-image: url(../images/common/ico_eye.svg);
    }
  }

  .email-box {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    width: 100%;

    .input-text {
      border-width: 0;
      border-color: transparent;
    }

    .selectstyle {
      border-width: 0;
      border-color: transparent;
    }

    &.error {
      border-color: $color-error;
    }
  }

  .search-box {
    display: flex;
    position: relative;

    &>* {
      flex: 1;
    }

    .input-text {
      padding-right: 48px;
    }

    .btn-search {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      border: none;
      @include bg-inline;
      background-color: transparent;
      background-image: url(../images/common/btn_24_search.svg);
      transform: translateY(-50%);
      cursor: pointer;
    }

    .btn-del {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      border: none;
      @include bg-inline;
      background-color: transparent;
      background-image: url(../images/common/btn_24_del_gray.svg);
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .limit-textarea {
    position: relative;
    width: 100%;

    .input-textarea {
      height: 172px;
      padding-bottom: 47px;
    }

    .cnt {
      position: absolute;
      right: 15px;
      bottom: 15px;
      color: #999;
      font-size: 13px;
      line-height: 15px;
      font-weight: 300;
    }
  }

  .input {
    &-text {
      width: 100%;
      min-width: 0;
      height: 56px;
      padding: 18px 15px 17px;
      border: 1px solid #d9d9d9;
      border-radius: 0;
      outline: 0;
      background: $color-white;
      color: $color-black;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
      font-family: $font-family-1;
      box-sizing: border-box;
      vertical-align: top;

      &:focus {
        border-color: $color-black;
      }

      &.error {
        border-color: $color-error;
      }

      &:read-only {
        color: #999;
        border-color: #d9d9d9;
        background: #f8f8f8;
      }

      &:disabled {
        border-color: #d9d9d9;
        color: #999;
      }
    }

    &-textarea {
      width: 100%;
      min-width: 0;
      height: 140px;
      padding: 18px 15px 17px;
      border: 1px solid #d9d9d9;
      border-radius: 0;
      background: $color-white;
      color: $color-black;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
      font-family: $font-family-1;
      resize: none;
      box-sizing: border-box;
      vertical-align: top;

      &:focus {
        border-color: $color-black;
      }

      &.error {
        border-color: $color-error;
      }

      &:read-only {
        height: 140px !important;
        padding-bottom: 17px !important;
        border-color: #d9d9d9;
        background: #f8f8f8;

        &+.cnt {
          display: none;
        }
      }

      &:disabled {
        height: 140px !important;
        padding-bottom: 17px !important;
        border-color: #d9d9d9;
        color: #999;

        &+.cnt {
          display: none;
        }
      }
    }

    &-labelbox {
      display: inline-flex;
      align-items: flex-start;
      margin-right: 24px;
      vertical-align: top;
      cursor: pointer;

      &-list {
        margin: 0;
        padding: 0;

        li {
          list-style: none;

          &+li {
            margin-top: 12px;
          }
        }
      }

      input {
        flex: none;
        width: 24px;
        height: 24px;
        margin: 0;
        appearance: none;
        cursor: pointer;

        &[type="checkbox"] {
          border-radius: 0;
          background: url(../images/common/ico_checkbox_off.svg) no-repeat center/contain;

          &:checked {
            background-image: url(../images/common/ico_checkbox_on.svg);

            &:disabled {
              background-image: url(../images/common/ico_checkbox_on_disabled.svg);
            }
          }

          &:disabled {
            background-image: url(../images/common/ico_checkbox_off_disabled.svg);
          }

          &.error {
            background-image: url(../images/common/ico_checkbox_error.svg);
            & + .txt {
              color:$color-error;
            }
          }
        }

        &[type="radio"] {
          position: relative;
          width: 24px;
          height: 24px;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
          background-color: $color-white;

          &:checked {
            border-color: $color-p;
            background-color: $color-p;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              transform: translate(-50%, -50%);
            }

            &:disabled {
              border-color: #d9d9d9;
              background: #f8f8f8;

              &::before {
                background-color: #d9d9d9;
              }
            }
          }

          &:disabled {
            background: #f8f8f8;
          }

          &.error {
            border-color:$color-error;
            & + .txt {
              color:$color-error;
            }
          }
        }

        &:disabled+.txt {
          color: #999;
        }
      }

      .txt {
        margin-left: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }

  .selectstyle {
    width: 100%;
    min-width: 0;
    height: 56px;
    padding: 18px 15px 17px;
    border: 1px solid #d9d9d9;
    outline:0;
    background: url(../images/common/ico_select_arrow.svg) no-repeat right 15px center/24px;
    color: $color-black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    font-family: $font-family-1;
    appearance: none;
    cursor: pointer;
    box-sizing: border-box;

    &:focus {
      border-color: $color-black;
    }

    &.error {
      border-color: $color-error;
    }

    &:disabled {
      border-color: #d9d9d9;
      color: #999;
    }
  }

  .validate-box {
    display: flex;
    flex-wrap: wrap;
    margin: 1px 0 0 -16px;

    .validate-check {
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      font-weight: 300;
      color: #999999;
      margin: 8px 0 0 16px;

      >.icon {
        margin-right: 4px;
        width: 12px;
        height: 12px;
        background: url(../images/common/ico_validate_check.svg) no-repeat center/contain;
      }

      &.active {
        color: $color-p;

        >.icon {
          background-image: url(../images/common/ico_validate_check_on.svg);
        }
      }
    }
  }

  .flex-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .txtbox {
      .tit {
        margin: 0;
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
      }

      .txt {
        margin: 8px 0 0 0;
        color: #666;
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
      }
    }

    .cntbox {
      display: flex;

      .btn-minus {
        flex: none;
        width: 32px;
        height: 32px;
        border: none;
        background: url(../images/common/btn_cnt_minus.svg) no-repeat center/contain;
      }

      .btn-plus {
        flex: none;
        width: 32px;
        height: 32px;
        border: none;
        background: url(../images/common/btn_cnt_plus.svg) no-repeat center/contain;
      }

      .input-cnt {
        flex: none;
        width: 40px;
        height: 32px;
        padding: 0;
        margin: 0 -1px;
        border: 1px solid #d9d9d9;
        background: #fff;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  .terms-list {
    padding: 0;
    margin: 0;
    border: 1px solid #d9d9d9;
    list-style: none;

    >li {
      position: relative;

      &+li {
        border-top: 1px solid #d9d9d9;
      }

      .inbox {
        display: flex;
        align-items: flex-start;
        padding: 15px;
        color: inherit;
        text-decoration: none;

        .txt {
          flex: 1;
          margin: 0 8px 0 0;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;

          &.medium {
            font-weight: 600;
          }
        }

        .ico-arrow {
          flex: none;
          width: 16px;
          height: 16px;
          background: url(../images/common/ico_arrow_r.svg) no-repeat center/contain;
        }
      }

      .terms-labelbox {
        &+.inbox {
          padding-left: 39px;
        }

        input {
          position: absolute;
          top: 15px;
          left: 15px;
          width: 16px;
          height: 16px;
          margin: 0;
          appearance: none;
          cursor: pointer;

          &[type="checkbox"] {
            border-radius: 0;
            background: url(../images/common/terms_check.svg) no-repeat center/contain;

            &:checked {
              background-image: url(../images/common/terms_check_on.svg);
            }
          }
        }

        &.type02 {
          &+.inbox {
            padding-left: 43px;

            .txt {
              line-height: 21px;
            }

            .ico-arrow {
              width: 20px;
              height: 20px;
              background-position: center right;
              background-size: 16px 16px;
            }
          }

          input {
            top: 15px;
            left: 15px;
            width: 20px;
            height: 20px;

            &[type="checkbox"] {
              background: url(../images/common/ico_checkbox_off.svg) no-repeat center/contain;

              &:checked {
                background-image: url(../images/common/ico_checkbox_on.svg);
              }
            }
          }
        }

        &.type03 {
          display: flex;
          align-items: center;
          padding: 16px 0 16px 44px;
          box-sizing: border-box;
          width: calc(100% - 52px);

          input {
            top: 16px;
            left: 16px;
            width: 20px;
            height: 20px;

            &[type="checkbox"] {
              background: url(../images/common/ico_checkbox_off.svg) no-repeat center/contain;

              &:checked {
                background-image: url(../images/common/ico_checkbox_on.svg);
              }
            }
          }

          .txt {
            font-size: 14px;
            line-height: 21px;
          }

          &+.inbox {
            display: block;
            position:absolute;
            top:0;
            right:0;
            padding: 0;
            width:52px;
            height:100%;

            .ico-arrow {
              display:block;
              position:absolute;
              top: 16px;
              right:16px;
              width: 20px;
              height: 20px;
              background-position: center right;
              background-size: 16px 16px;
            }
          }

        }
      }
    }
  }
}