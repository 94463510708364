/* popup */
&layer {
  &-pop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    padding: 24px;
    box-sizing: border-box;
    overflow: auto;
    z-index: 500;
    .pop-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .pop-box {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      margin: auto;
      background: $color-white;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
      .in-head {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 24px;
        &.bg-gray {
          background-color: #f5f6f8;
        }
        .title {
          margin: 0;
          color: $color-black;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
        }
        .btn-close {
          position: absolute;
          top: 50%;
          right: 24px;
          width: 24px;
          height: 24px;
          border: none;
          background: url(../images/common/ico_popup_close.svg) no-repeat center/contain;
          transform: translateY(-50%);
          text-indent: -999px;
          overflow: hidden;
          cursor: pointer;
        }
        .do_next {
          display: inline-flex;
          margin-left: auto;
          color: #666;
          font-size: 14px;
          line-height: 16px;
          font-weight: 300;
          text-decoration: underline;
        }
        .stitle {
          margin: 4px 0 0 0;
          color: $color-black;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }
        .stext {
          margin: 0;
          color: $color-black;
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
        }
      }
      .in-cont {
        padding: 24px 24px 0;
        .num-box-wrap {
          .num-box {
            width: 65px;
            height: 28px;
            line-height: 28px;
            margin: 0 auto;
            text-align: center;
            border-radius: 17px;
            background: #1e75ff;
            margin-top: 24px;
            span {
              font-family: $font-family-2;
              font-size: 12px;
              color: #fff;
              font-weight: 300;
              &.tit-num {
                margin-right: 9px;
                font-size: 16px;
                font-weight: 500;
              }
              &.active {
                font-weight: 500;
              }
            }
          }
          .title {
            text-align: center;
            margin: 16px 0 12px;
            padding: 0;
            font-size: 20px;
          }
          .text {
            font-size: 16px;
            text-align: center;
            font-weight: 300;
            margin: 0;
            padding: 0;
          }
        }

        .quest-select-wrap {
          border: 1px solid #eaeaea;
          padding: 24px;
          margin-top: 8px;
          .head-ico {
            display: flex;
            justify-content: flex-end;
            align-items: end;
            margin: -12px 0 16px;
            * {
              vertical-align: top;
            }
            a {
              display: block;
            }
          }
          .btn-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 0;
            font-size: 0;
            .title {
              font-size: 16px;
            }
          }
          .date-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            .txt-wrap {
              .choose {
                color: #1e75ff;
                font-size: 14px;
                font-weight: 500;
              }
              .title {
                margin: 0;
                padding: 0;
              }
            }
          }
        }

        .car-input-area {
          background: #f5f6f8;
          margin-top: 32px;
          padding: 32px 72px;
          text-align: center;
          .ico {
            width: 40px;
            height: 40px;
            background: url(../images/common/ico_parking.svg) no-repeat center/contain;
            margin: 0 auto;
          }
        }

        .chk-btnlist {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          .item {
            flex: 0 0 calc(100% / 2 - 4px * 1 / 2);
            cursor: pointer;
            input {
              display: none;
              &:checked + .btn {
                background-color: $color-black;
                color: $color-white;
                font-weight: 600;
              }
            }
            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 80px;
              background-color: #f5f6f8;
              color: $color-black;
              font-size: 20px;
              font-weight: 300;
            }
          }
          &.ts-col-3 {
            .item {
              flex: 0 0 calc(100% / 3 - 4px * 2 / 3);
            }
          }
        }

        .add-info {
          margin-top: 16px;
          float: right;
          padding: 11px 16px;
          border: 1px solid #000;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .ico {
            width: 16px;
            height: 16px;
            background: url(../images/common/ico_add.svg) no-repeat center/contain;
            margin-right: 4px;
          }
          .text {
            padding: 0;
            margin: 0;
          }
        }

        .appguide-img {
          img {
            width: 100%;
            vertical-align: top;
          }
        }
        &.event {
          .label-text {
            text-align: center;
            margin-bottom: 32px;
          }
          .ts-theme-form {
            .input-text {
              padding: 20px;
              height:auto;
              box-sizing:border-box;
              font-family: $font-family-2;
              font-size: 40px;
              font-weight: 700;
              line-height: 48px;
            }
          }
        }
      }
      .in-foot {
        .pop-btn {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
          border: 1px solid transparent;
          background: transparent;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          font-family: $font-family-1;
          text-decoration: none;
          box-sizing: border-box;
          cursor: pointer;
          &.bd-black {
            border-color: $color-black;
            color: $color-black;
          }
          &.bg-blue {
            border-color: transparent;
            background: $color-p;
            color: $color-white;
          }
          &box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 32px 24px 24px;
            * + * {
              margin-left: 8px;
            }
            .link-txt {
              width: 100%;
              margin: 16px 0 0;
              text-align: center;
              a {
                color: #111;
                font-size: 12px;
                line-height: 24px;
                text-decoration: underline;
              }
              & + .link-txt {
                margin: 4px 0 0;
              }
            }
          }
        }
        .ts-theme-move-store {
          display:flex;
          align-items:center;
          justify-content:center;
          width:100%;
          & > a {
            display:block;
            width:160px;
            height:48px;
            background-repeat:no-repeat;
            background-position: center center;
            background-size: 100%;
            &.store-google {
              background-image:url(../images/common/img_event_google.png)
            }
            &.store-apple {
              background-image:url(../images/common/img_event_appstore.png)
            }
          }
        }
      }
    }
    .pop-coupon {
      margin: auto;
      

      .content {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        border-radius: 16px 16px 0 0;
        padding: 64px 24px 20px;
        background-color: $color-white;
        margin-bottom: 26px;
        box-sizing: border-box;
        &:after {
          content: "";
          position:absolute;
          left: 0;
          bottom: -27px;
          width: 100%;
          height: 28px;
          background:url(../images/common/bg_coupon_hole.png) no-repeat center center;
          background-size: 100% 100%;
        }
        .btn-close {
          position: absolute;
          top: 24px;
          right: 24px;
          width: 24px;
          height: 24px;
          border: none;
          background: url(../images/common/ico_popup_close.svg) no-repeat center/contain;
          text-indent: -999px;
          overflow: hidden;
          cursor: pointer;
        }
        .box {
          position: relative;
          flex-grow: 1;
          .badge {
            display: inline-block;
            margin: 0 0 16px;
            padding: 0px 8px;
            border-radius:0;
            font-size: 12px;
            line-height: 22px;
            color: #fff;
            font-family: $font-family-2;
            font-weight: 700;
            background-color: $color-black;
            border-radius: 16px;
          }
          .tit {
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
          }
          .desc {
            display: block;
            margin-top: 10px;
            color: #666666;
            font-size: 16px;
            line-height: 24px;
          }
          .date {
            margin-top: 24px;
            display: block;
            color: #999999;
            font-family: $font-family-2;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
          }
        }
        .barcode {
          position: relative;
          margin: 72px 0 0 0;
          text-align: center;
          .img-barcode {
            opacity: 0.1;
            img {
              display: block;
              width: 100%;
              max-width: 279px;
              margin: 0 auto;
            }
          }
          .txt {
            position: absolute;
            top: 20px;
            left: 50%;
            margin: 0;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            transform: translateX(-50%);
          }
          // [app]
          &.app {
            .img-barcode {
              opacity: 1;
            }
          }
        }

      }
      .notice {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        border-radius: 0 0 16px 16px;
        padding: 20px 24px 34px;
        box-sizing: border-box;
        background-color: #f5f6f8;

        .sec-title {
          display: flex;
          align-items: center;
          margin: 0 0 16px 0;
          padding: 0;
          .ico {
            display: inline-block;
            margin-right: 4px;
            width: 14px;
            height: 14px;
            background: url(../images/common/ico_14_info_black.svg) no-repeat center/contain;
            border-radius: 50%;
          }
          .title {
            display: inline-block;
            margin: 0;
            padding: 0;
            font-size: 16px;
          }
        }
        .list-desc {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            position: relative;
            padding: 0 0 0 6px;
            color: #666;
            font-size: 14px;
            font-weight: 300;
            &:before {
              display: block;
              position: absolute;
              top: 8px;
              left: 0;
              width: 2px;
              height: 2px;
              background-color: #666;
              content: "";
            }
            & + li {
              margin: 8px 0 0 0;
            }
          }
        }
      }
    }

    &.ver-drop {
      display: block;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      transition: 0.3s;
      &.show {
        visibility: visible;
        opacity: 1;
        .pop-commercial,
        .pop-share {
          transform: translateY(0);
        }
      }
    }
    .pop-commercial {
      display: flex;
      flex-direction: column;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: 90vh;
      border-radius: 20px 20px 0 0;
      background: $color-white;
      overflow: hidden;
      transform: translateY(101%);
      transition: 0.3s;
      .content {
        flex: 1;
        margin: 0;
        padding: 0;
        overflow: auto;
        img {
          width: 100%;
          vertical-align: top;
        }
      }
      .ts-theme-form {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        box-sizing: border-box;
        .input-labelbox {
          .txt {
            color: #666;
            font-size: 14px;
          }
        }
        .btn-txt {
          overflow: visible;
          padding: 0;
          border: none;
          box-shadow: none;
          border-radius: 0;
          font-family: $font-family-1;
          color: $color-black;
          font-size: 14px;
          background: inherit;
          cursor: pointer;
        }
      }
    }
    .pop-share {
      display: flex;
      flex-direction: column;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      height:196px;
      background: $color-white;
      overflow: hidden;
      transform: translateY(101%);
      transition: 0.3s;
      .pop-share-header {
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding:24px;
        box-sizing: border-box;
        .title {
          margin:0;
          padding:0;
          color:$color-black;
          font-size:16px;
        }
        .btn-close {
          display: block;
          position:relative;
          border:0;
          width:24px;
          height:24px;
          background-color:transparent;
          &:before,
          &:after {
            content:"";
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            width:1px;
            height:17px;
            background-color:$color-black;
          }
          &:before {
            transform:translate(-50%, -50%) rotate(-45deg);
          }
          &:after {
            transform:translate(-50%, -50%) rotate(45deg);
          }
        }
      }
      .content {
        padding:8px 24px 46px;
        box-sizing:border-box;
        .wrap-btn-shares {
          display:flex;
          align-items: center;
          justify-content: center;
          .btn-share {
            border:0;
            border-radius:50%;
            width:70px;
            height:70px;
            background-color:transparent;
            color:$color-black;
            font-family: $font-family-2;
            font-size:16px;
            font-weight: 700;
            text-align:center;
            &.url{
              background-color:#F5F6F8;
            }
            // 개발 전 임시 가림 20240902
            &.kakao {
              display: none;
              margin-right:28px;
              background:url(../images/common/ico_share_kakao.png) no-repeat center center;
              background-size: 70px;
            }
            &.message {
              display: none;
              margin-right:28px;
              background:url(../images/common/ico_share_message.png) no-repeat center center;
              background-size: 70px;
            }
          }
        }
      }
    }
  }
}

&page {
  // 임시 레이어 팝업 처리
  position:absolute;
  top:0;
  left:0;
  z-index: 100;
  padding-bottom:104px;
  box-sizing:border-box;
  width:100%;
  min-height:100vh;
  min-height:100dvh;
  background-color:$color-white;

  > .page-head {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    .title {
      overflow: hidden;
      margin: 0;
      max-width: calc(100% - 24px);
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btn-close {
      width: 24px;
      height: 24px;
      margin-left: auto;
      padding: 0;
      border: none;
      background: url(../images/common/ico_24_close.svg) no-repeat center/contain;
      background-color: transparent;
    }
  }
  .page-cont {
    overflow-y:auto;
    padding: 24px;
    box-sizing:border-box;
    height:calc(100vh - 64px);
    
    .pop-common {
      img {
        display: block;
        margin: 0 auto;
      }
    }
    .ts-theme-policy {
      .ts-theme-form {
        margin-top: 0;
      }
    }
  }

  &.survey {
    .page-head {
      align-items: flex-start;
      flex-direction: column;
      margin: 0;
      padding: 32px 24px 24px;
      box-sizing: border-box;
      height: auto;
      background-color: #f5f6f8;
      .stitle {
        margin: 0;
        color: $color-black;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
      .stext {
        margin: 0;
        color: $color-black;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
      .do_next {
        display: inline-block;
        align-self: flex-end;
        margin: 12px 0 0 0;
        color: #666;
        text-align: right;
      }
    }
    .page-cont {
      padding-bottom:104px;
    }
    .num-box-wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      .num-box {
        padding: 6px 12px;
        margin-top: 24px;
        border-radius: 16px;
        line-height: 1;
        text-align: center;
        background: #1e75ff;
        span {
          font-family: $font-family-2;
          font-size: 12px;
          color: #fff;
          font-weight: 300;
          &.tit-num {
            margin-right: 8px;
            font-size: 16px;
            font-weight: 500;
          }
          &.active {
            font-weight: 500;
          }
        }
      }
      .title {
        text-align: center;
        margin: 32px 0 16px;
        padding: 0;
        font-size: 20px;
      }
      .text {
        font-size: 16px;
        text-align: center;
        font-weight: 300;
        margin: 0;
        padding: 0;
      }
    }
    .car-input-area {
      background: #f5f6f8;
      margin-top: 32px;
      padding: 32px 24px;
      text-align: center;
      .ico {
        width: 40px;
        height: 40px;
        background: url(../images/common/ico_parking_car.svg) no-repeat center/contain;
        margin: 0 auto;
      }
    }
    .chk-btnlist {
      display: flex;
      margin: 0 -4px 0;
      flex-wrap: wrap;
      .item {
        margin: 4px;
        width: calc(50% - 8px);
        cursor: pointer;
        input {
          overflow: hidden;
          position: absolute;
          margin: -1px;
          padding: 0;
          border: 0;
          width: 1px;
          height: 1px;
          clip: rect(0, 0, 0, 0);
          &:checked + .btn {
            background-color: $color-black;
            color: $color-white;
            font-weight: 600;
          }
          // &:focus + .btn {
          //   outline:1px solid ;
          // }
        }
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding:0;
          height: 64px;
          background-color: #f5f6f8;
          color: $color-black;
          font-size: 18px;
          font-weight: 300;
        }
      }
    }
    .quest-select-wrap {
      border: 1px solid #eaeaea;
      padding: 24px;
      .head-ico {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        margin: -12px 0 16px;
        * {
          vertical-align: top;
        }
        > button {
          margin:0;
          padding:0;
          border:0;
          box-sizing: none;
          background:transparent;
        }
      }
      .btn-select {
        .title {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: 500;
        }
        .chk-btnlist {
          width: calc(100% + 8px);
          margin: 12px -4px 0;
          .btn {
            height: 48px;
          }
        }
      }
      .date-select {
        margin: 20px 0 0 0;
        .txt-wrap {
          display: flex;
          margin: 0 0 16px;
          align-items: flex-end;
          .choose {
            color: #1e75ff;
            font-size: 14px;
            font-weight: 500;
          }
          .title {
            margin: 0 0 0 4px;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .type01 {
          flex: 1 1 auto;
          padding: 14px 31px 14px 11px;
          width: 40%;
          height: 48px;
          background-position: right 8px center;
          & + .type01 {
            width: 30%;
          }
        }
      }
      & + .quest-select-wrap {
        margin: 16px 0 0 0;
      }
    }
    .add-info {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid #000;
      box-shadow: none;
      background-color: transparent;
      cursor: pointer;
      .ico-add {
        display: block;
        padding: 0 0 0 25px;
        font-family: $font-family-1;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        background: url(../images/common/ico_add.svg) no-repeat left center;
        background-size: 16px;
      }
    }
    & + .ts-theme-btm-fixbox {
      .pop-btn {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        border: 1px solid transparent;
        background: transparent;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        font-family: $font-family-1;
        text-decoration: none;
        box-sizing: border-box;
        cursor: pointer;
        &.bd-black {
          border-color: $color-black;
          color: $color-black;
        }
        &.bg-blue {
          border-color: transparent;
          background: $color-p;
          color: $color-white;
        }
        &box {
          display: flex;
          justify-content: center;
          padding: 32px 24px 24px;
          * + * {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

&floor-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  background-color: $color-white;
  box-sizing: border-box;
  z-index: 999;
  &.vis-opa {
    display: flex !important;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
  }
  .in-head {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 24px;
    .title {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .btn-pop-close {
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 50%;
      background: url(../images/common/ico_popup_close.svg) no-repeat center/contain;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .in-cont {
    overflow:hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;
  }
  .floor-tab {
    flex: none;
    display: flex;
    overflow-x:auto;
    align-items: center;
    margin: 0;
    padding: 0 24px;
    box-sizing:border-box;
    width:100%;
    height: 56px;
    background-color: $color-black;
    list-style: none;
    > li {
      + li {
        margin-left: 24px;
      }
      > a {
        display: block;
        position: relative;
        color: $color-white;
        font-size: 20px;
        line-height: 56px;
        font-weight: 500;
        font-family: $font-family-2;
        white-space: nowrap;
        text-decoration: none;
        opacity: 0.4;
        &.active {
          opacity: 1;
          &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 4px;
            background-color: $color-white;
          }
        }
      }
    }
    &con {
      flex: 1;
      min-height: 0;
      overflow: auto;
    }
  }

  /* floor-map-box */
  .floor-map {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-sizing: border-box;
    overflow: hidden;
    .ts-icon-map {
      z-index:999 !important;
    }
  }

  .leaflet-container .leaflet-right .leaflet-control-zoom {
    margin-right: 24px;
    margin-bottom: 24px;
    border: none;
    border-radius: 0;
    a {
      width: 24px;
      height: 24px;
      margin: 0 0 2px 0;
      border: none;
      border-radius: 4px;
      @include bg-inline;
      background-color: transparent;
      color: transparent;
      box-sizing: border-box;
      &.leaflet-control-zoom-in {
        background-image: url(../images/common/btn_map_plus.svg);
      }
      &.leaflet-control-zoom-out {
        background-image: url(../images/common/btn_map_minus.svg);
      }
      &.leaflet-disabled {
        background-color: transparent;
        opacity: 0.35;
      }
    }
  }
  .leaflet-control-attribution {
    display: none;
  }
  .leaflet-marker-pane {
    .custom-label {
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 500;
        font-family: $font-family-2;
        text-shadow: 0 0 5px #fff;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        transition: 0.1s linear;
        pointer-events: none;
      }
    }
  }
}

/* 상세팝업 */
&floor-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  background-color: $color-white;
  box-sizing: border-box;
  overflow: auto;
  z-index: 999;
  &.vis-opa {
    display: flex !important;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
  }
  .btn-close-detail {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    @include bg-inline;
    background-image: url(../images/common/btn_white_close.svg);
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
  }

  /* 매장상세 */
  .floor-store {
    display: flex;
    flex-direction: column;
    width: 100%;
    .store-head {
      position: relative;
      height:440px;
      .img-visual {
        display: flex;
        align-items: flex-end;
        position: relative;
        // height: 0;
        height:440px;
        // padding: 0 24px 117.3%;
        @include bg-inline(cover);
        background-color: #f5f6f8;
        box-sizing: border-box;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $color-black;
          opacity: 0.1;
        }
        .cont {
          position: absolute;
          right: 24px;
          bottom: 24px;
          left: 24px;
          padding: 16px;
          background-color: rgba(0, 0, 0, 0.8);
          color: $color-white;
          font-family: $font-family-2;
          .titbox {
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 24px;
            .tit {
              font-weight: 700;
            }
            .f {
              margin-left: 12px;
              font-weight: 300;
              a {
                color:inherit;
                text-decoration: none;
              }
            }
            .heart {
              display: inline-flex;
              margin-left: 8px;
              input {
                width: 24px;
                height: 24px;
                background-image:url(../images/common/ico_heart_transparent_off.svg);
                &:checked {
                  background-image:url(../images/common/ico_heart_on.svg);                  
                }
              }
            }
          }
          .typebox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: hidden;
            > li {
              display: flex;
              position: relative;
              margin: 12px 12px 0 0;
              padding-right: 13px;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 12px;
                background-color: $color-white;
                transform: translateY(-50%);
              }
              &:last-child {
                margin-right: 0;
                padding-right: 0;
                &::before {
                  display: none;
                }
              }
              > span {
                font-size: 16px;
                line-height: 19px;
                font-weight: 300;
                &.event {
                  padding: 0 8px;
                  border: 1px solid $color-white;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }
          }
          .contactbox {
            margin: 24px 0 0 0;
            padding: 0;
            list-style: none;
            > li {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              + li {
                margin-top: 12px;
              }
              .ico {
                flex: none;
                display: inline-flex;
                align-items:center;
                margin: 0 6px 0 0;
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                }
                span {
                  display:inline-block;
                  vertical-align: top;
                }
              }

              .txt {
                padding: 1px 0 0 0;
                font-size: 14px;
                line-height: 18px;
                font-weight: 300;
                span {
                  display:block;
                  & + span {
                    margin-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .store-cont {
      position: relative;
      padding: 28px 24px 24px;
      background-color: $color-white;
      z-index: 1;
      .store-news {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 16px;
        text-decoration: none;
        background-color: #f5f6f8;
        .ico {
          flex: none;
          width: 24px;
          height: 24px;
          margin-right: 12px;
          background: url(../images/common/ico_black_alarm.svg) no-repeat center/contain;
        }
        .txt {
          flex: 1;
          color:$color-black;
          padding: 3px 0 2px;
          font-size: 16px;
          line-height: 19px;
          font-weight: 300;
          a {
            color:$color-black;
            text-decoration:none;
          }
        }
        & + .store-map {
          margin-top: 24px;
        }
      }
    }
  }

  /* store-map-box */
  .store-map {
    overflow: hidden;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    width: 100%;
    height: 240px;
    background-color:transparent;
    .ts-icon-map {
      z-index:999 !important;
    }
  }

  .leaflet-container .leaflet-right .leaflet-control-zoom {
    margin-right: 16px;
    margin-bottom: 16px;
    border: none;
    border-radius: 0;
    a {
      width: 24px;
      height: 24px;
      margin: 0 0 2px 0;
      border: none;
      border-radius: 4px;
      @include bg-inline;
      background-color: transparent;
      color: transparent;
      box-sizing: border-box;
      &.leaflet-control-zoom-in {
        background-image: url(../images/common/btn_map_plus.svg);
      }
      &.leaflet-control-zoom-out {
        background-image: url(../images/common/btn_map_minus.svg);
      }
      &.leaflet-disabled {
        background-color: transparent;
        opacity: 0.35;
      }
    }
  }
  .leaflet-control-attribution {
    display: none;
  }
  .leaflet-marker-pane {
    .custom-label {
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: 500;
        font-family: $font-family-2;
        text-shadow: 0 0 5px #fff;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        transition: 0.1s linear;
        pointer-events: none;
      }
    }
  }
}
