&gate- {
    &header {
        position: relative;
        margin: 0;
        padding: 0;
        height: 80px;

        .wrap-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding: 22px 20px;

            .box-language {
                position: relative;
                margin: 0;
                padding: 0;

                .btn-select {
                    display: block;
                    position: relative;
                    margin: 0;
                    padding: 9px 44px 9px 15px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $color-black;
                    border-radius: 48px;
                    box-shadow: none;
                    color: $color-black;
                    font-family: $font-family-1;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    background-color: transparent;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 16px;
                        width: 16px;
                        height: 16px;
                        background: url(../images/common/ico_lang_down.svg) no-repeat center/contain;
                        transform: translateY(-50%);
                    }

                    &.active {
                        border-width: 1px 1px 0 1px;
                        border-radius: 16px 16px 0 0;

                        &::before {
                            transform: translateY(-50%) rotate(180deg);
                        }

                        &+.list-language {
                            display: block;
                        }
                    }
                }

                .list-language {
                    display: none;
                    position: absolute;
                    top: 33px;
                    left: 0;
                    z-index: 1;
                    margin: 0;
                    padding: 0 0 9px 0;
                    border-width: 0 1px 1px 1px;
                    border-style: solid;
                    border-color: $color-black;
                    border-radius: 0 0 16px 16px;
                    box-sizing: border-box;
                    width: 100%;
                    list-style: none;
                    background-color: $color-white;

                    .link {
                        display: block;
                        padding: 4px 15px;
                        color: $color-black;
                        font-size: 14px;
                        line-height: 16px;
                        text-decoration: none;
                    }
                }
            }

            .txt-fml {
                color: inherit;
                font-size: 14px;
                font-weight: 700;
                font-family: $font-family-2;
                text-decoration: none;
            }

            .btn-box {
                display: flex;
                align-items: center;
                margin-left: auto;

                button[class^=btn] {
                    display: block;
                    margin-left: 12px;
                    border: 0;
                    box-shadow: none;
                    width: 24px;
                    height: 24px;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center center;
                }

                .btn-user {
                    background-image: url(../images/common/btn_my.svg);
                }

                .btn-login {
                    background-image: url(../images/common/btn_login.svg);
                }

                .btn-logout {
                    background-image: url(../images/common/btn_logout.svg);
                }
            }
            .btn-back {
                display:none;
                border:0;
                width:24px;
                height:24px;
                box-shadow: none;
                background:transparent url(../images/common/ico_back.svg) no-repeat center / contain;
            }
        }

        &.sub {
            .wrap-header {
                .txt-fml {
                    display:none;
                }
                .btn-box {
                    button.btn-logout,
                    button.btn-login,
                    button.btn-user {
                        display:none;
                    }
                }
                .btn-back {
                    display:block;
                }
            }
        }
    }

    &wrap {
        display: flex;
        flex-direction: column;
        margin: -36px 0 0 0;
        padding: 0;
        height: calc(100vh - 124px);
        height: calc(100dvh - 124px);
        list-style: none;

        &.start {
            &::after {
                opacity: 0;
                visibility: hidden;
                transform: translateY(-40px);
            }

            &>.item {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);

                &:nth-child(1) {
                    transition-property: opacity, visibility, transform;
                    transition: .3s .2s linear;
                }

                &:nth-child(2) {
                    transition-property: opacity, visibility, transform;
                    transition: .3s .4s linear;
                }

                &:nth-child(3) {
                    transition-property: opacity, visibility, transform;
                    transition: .3s .6s linear;
                }

                &:nth-child(4) {
                    transition-property: opacity, visibility, transform;
                    transition: .3s .8s linear;
                }
            }
        }

        &.show {
            &>.item {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }

        .item {
            overflow: hidden;
            position: relative;
            flex: 1 1 auto;
            margin: 0;
            padding: 0;
            border-radius: 12px;
            box-shadow: inset 1000px 1000px rgba(0, 0, 0, 0.4);
            color: $color-white;
            font-family: $font-family-2;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-40px);
            transition-property: flex;
            transition-duration: .2s;

            &+.item {
                margin-top: 16px;
            }

            &.active {
                flex: 4 1 auto;
                transition-property: flex;
                transition-duration: .2s;

                .bg {
                    img {
                        transform: scale(2.5) translate(-50%, -50%);
                        animation: 20s linear 0s infinite animate-scale;
                    }
                }

                .working-time {
                    z-index: 3;
                    margin: 16px 0 87px;
                    opacity: 1;

                    .ico_clock {
                        display: inline-block;
                    }

                }

                .link {
                    z-index: 3;
                    opacity: 1;
                }

                .wrap-video {
                    display: block;
                    z-index: 0;
                }
            }

            // &.timessquare {
            // background: url(../images/sub/img_gate_timessquare.png) no-repeat center center;
            // background-size: cover;
            // }

            // &.timesstream {
            // background: url(../images/sub/img_gate_timesstream.png) no-repeat center center;
            // background-size: cover;
            // }

            // &.timesterrace {
            // background: url(../images/sub/img_gate_timesterrace.png) no-repeat center center;
            // background-size: cover;
            // }

            &.exithongdae {
                // background: url(../images/sub/img_gate_exithongdae.png) no-repeat center center;
                // background-size: cover;

                .branch-title {
                    .ico_i {
                        display: inline-block;
                        overflow: hidden;
                        position: relative;
                        vertical-align: middle;
                        width: 4px;
                        height: 29px;
                        margin: 0 2px;
                        font-style: normal;
                        font-size: 0;
                        text-indent: -999px;

                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 6px;
                            background-color: $color-white;
                        }

                        &:before {
                            top: 4px;
                        }

                        &:after {
                            bottom: 9px;
                        }
                    }
                }
            }
        }

        .branch {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            border: 0px solid transparent;
            box-shadow: none;
            background-color: transparent;

            .bg {
                overflow: hidden;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 0;
                    width: 100%;
                    transform: translate(-50%, -50%) scale(1.2);
                    transform-origin: center;
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.4);
                    backdrop-filter: blur(1px);
                }
            }

            .branch-title {
                position: relative;
                margin: 0;
                padding: 0;
                z-index: 2;
                color: $color-white;
                font-family: $font-family-2;
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
            }

            .working-time {
                display: block;
                position: relative;
                z-index: -1;
                margin: 0;
                opacity: 0;

                .ico_clock {
                    display: none;
                    padding-left: 24px;
                    color: $color-white;
                    font-family: $font-family-2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19px;
                    background: url(../images/common/ico_clock.svg) no-repeat left center;
                    background-size: 16px 16px;
                }
            }

            .wrap-video {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                box-shadow: 2000px 2000px rgba(0, 0, 0, 0.4);
                width: 100%;
                height: 100%;

                >.ts-theme-video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                }
            }

        }

        .link {
            display: block;
            position: absolute;
            bottom: 27%;
            left: 50%;
            z-index: -1;
            padding: 11px 31px 11px 15px;
            border: 1px solid $color-white;
            border-radius: 8px;
            box-sizing: border-box;
            min-width: 144px;
            color: $color-white;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            text-align: left;
            text-decoration: none;
            background: url(../images/common/ico_gate_shortcut.svg) no-repeat right 16px center;
            background-size: 16px 16px;
            opacity: 0;
            transform: translateX(-50%);
        }
    }

    @keyframes animate-scale {
        0% {
            transform: translate(-50%, -50%) scale(2.5);
        }

        100% {
            transform: translate(-50%, -50%) scale(3);
        }
    }
}