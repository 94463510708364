/* board */
&bo {
  &-search-box {
    display: flex;
    position: relative;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;

    input {
      width: 100%;
      height: 56px;
      padding: 18px 56px 17px 16px;
      border: 1px solid transparent;
      background: #f5f6f8;
      font-size: 16px;
      line-height: 19px;
      font-weight: 300;
      font-family: $font-family-1;
      outline: none;
      box-sizing: border-box;

      &:focus {
        border-color: $color-black;
      }
    }

    .btn-search {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
      border: none;
      background: url(../images/common/ico_search.svg) no-repeat center/contain;
      text-indent: -999px;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  &-row-list {
    padding: 0;
    margin: 0;
    border-top: 2px solid $color-black;
    list-style: none;

    > li {
      display: flex;
      border-bottom: 1px solid #eaeaea;

      * {
        color: inherit;
        text-decoration: none;
      }

      .badge {
        display: inline-block;
        margin:0;
        padding: 7px;
        border: 1px solid $color-black;
        border-radius:0;
        font-size: 12px;
        line-height: 1;
        font-family: $font-family-2;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;

        &.round {
          padding: 4px 8px;
          border-radius: 16px;
          font-weight: 300;

          &.bold {
            font-weight: 500;
          }

          &.active {
            border-color: $color-p;
            color: $color-white;
            background-color: $color-p;
          }
        }
      }

      >.inbox {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 32px 0;
        font-size: 18px;
        line-height: 24px;
        box-sizing: border-box;

        .tit-box {
          display: block;

          .tit {
            margin: 0;
            font-size: 18px;
            line-height: 27px;
            font-weight: 300;

            &.bold {
              font-weight: 500;
            }
          }

          .badge+.tit {
            margin: 12px 0 0 0;
          }
        }

        .date-box {
          margin: 24px 0 0 0;
          color: #666;
          font-family: $font-family-2;
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;
        }
      }

      .btn-box {
        display: flex;
        margin: 0 -12px 0 0;
        flex: 0 0 auto;

        .fav-box {
          @include flex-center(flex);
          margin:0;
          padding: 0 12px;
        }

        .btn-call {
          @include flex-center(flex);
          padding: 0 12px;
          min-height: 60px;

          .ico-call {
            display: block;
            width: 24px;
            height: 24px;
            background: url(../images/common/ico_call.svg) no-repeat center/contain;
          }
        }
      }

      .ts-theme-bo-no-content {
        margin-top: -2px;
        text-align: center;
        width: 100%;
      }
      &[class^=item]{
        .inbox {
          width:calc(100% - 48px)
        }
        .btn-box{
          margin-right:0;
        }
        .category {
          display:inline-flex;
          align-items:center;
          position:relative;
          margin-right:8px;
          padding-right:9px;
          vertical-align: middle;
          box-sizing:border-box;
          color:#666;
          font-size:14px;
          font-weight:300;
          line-height:27px;
          &:after {
            content:"";
            display:block;
            position:absolute;
            top:50%;
            right:0;
            width:1px;
            height:12px;
            background-color:#666;
            transform:translateY(-50%);
          }
          & + span {
            display:inline-block;
            overflow:hidden;
            vertical-align: middle;
            width:calc(100% - 60px);
            text-overflow:ellipsis;
            white-space:nowrap;
          }
        }
      }
      &.item-event {
        .inbox{
          padding:20px 0;
          box-sizing: border-box;
        }
        
        .date {
          display:block;
          margin-top:8px;
          color:#666;
          font-family:$font-family-2;
          font-size:14px;
          line-height:16px;
          font-weight:300;
        }
      }
    }

    &.list-alarm {
      border-top-width: 0px;

      li {
        
        border: 1px solid #eaeaea;

        .inbox {
          padding: 24px;

          .date-box {
            margin-top: 44px;
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
          }

          .tit {
            font-size: 16px;
          }
        }

        .ts-theme-no-content {
          margin: 0 -1px 0 -1px;
          padding: 120px 0;
          border-width: 2px 0 1px 0;
          border-style: solid;
          border-color: $color-black transparent #eaeaea transparent;
          width: calc(100% + 2px);
          background-color: $color-white;
        }
        & + li {
          margin-top: 24px;
        }
      }
    }

    .ts-theme-bo-no-content {
      border-width: 2px 0 0 0;
      border-style: solid;
      border-color: $color-black transparent #eaeaea transparent;
      &.border-none {
        border:0;
      }
      &.ts-theme-py-120 {
        padding: 120px 0;
      }
    }
  }

  &-view- {
    &titbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      position:relative;
      margin: -136px -24px 0px;
      padding: 136px 24px 64px;
      text-align: center;
      background: #f5f6f8;

      .badge {
        display: inline-block;
        margin:0;
        padding: 7px;
        border: 1px solid $color-black;
        border-radius:0;
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;
        background-color: $color-white;

        &+.tit {
          margin: 24px 0 0 0;
        }
      }

      .tit {
        margin: 0;
        font-family: $font-family-2;
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        letter-spacing: -0.012em;
      }

      .date {
        margin-top: 32px;
        color: #999;
        font-family: $font-family-2;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
      }
      .wrap-btn-share {
        display:flex;
        align-items:center;
        justify-content: flex-end;
        position:absolute;
        bottom:24px;
        right:24px;
        margin:24px 0 0;
        width:100%;
        .btn-share {
          display:block;
          border:0;
          width:20px;
          height:20px;
          background:transparent url(../images/common/ico_share.svg) no-repeat center center;
          background-size:20px;
        }
      }
    }

    &conbox {
      padding: 64px 0;
      border-bottom: 1px solid #eaeaea;
      font-family: $font-family-2;
      p {
        img {
          width:100%;
          height:auto;
        }
      }
    }
  }

  &-prev-next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    font-family: $font-family-2;

    .btn-prev,
    .btn-next {
      position: relative;
      color: $color-black;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .btn-prev {
      padding-left: 28px;

      &::before {
        left: 0;
        background-image: url(../images/common/ico_view_prev.svg);
        background-position: left center;
      }
    }

    .btn-next {
      padding-right: 24px;

      &::before {
        right: 0;
        background-image: url(../images/common/ico_view_next.svg);
        background-position: right center;
      }
    }

    .disabled {
      opacity: 0.35;
      pointer-events: none;
    }

    .btn-list {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: $color-p;
      color: #fff;

      .ico-list {
        position: relative;
        border-width: 3px 0 3px 0;
        border-style: solid;
        border-color: $color-white;
        box-sizing: border-box;
        width: 24px;
        height: 16px;
      }
    }
  }

  &-image-list {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
      >.inbox {
        display: flex;
        flex-direction: column;
        text-decoration: none;

        .imgbox {
          display: flex;
          position: relative;

          img {
            display: block;
            width: 100%;
          }

          .tag {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 7px 8px 6px 8px;
            background-color: $color-black;
            color: $color-white;
            font-size: 12px;
            line-height: 15px;
            font-weight: 700;
          }
          .fav-box {
            display:flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            width:32px;
            height:32px;
            background-color:rgba(0,0,0,.48);
            .ts-theme-check-heart {
              width:18px;
              height:18px;
              background-image:url(../images/common/ico_heart_transparent_off.svg);
              &:checked {
                background-image:url(../images/common/ico_heart_white.svg);
              }
            }
          }
          .txt-ended {
            @include flex-center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.64);
            color: $color-white;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            z-index: 1;
          }
        }

        .txtbox {
          .tit {
            display: block;
            overflow: hidden;
            margin: 20px 0 0 0;
            color: $color-black;
            font-family: $font-family-2;
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .date {
            display: block;
            margin-top: 12px;
            color: #999;
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            font-family: $font-family-2;
          }
        }
      }

      &+li {
        margin-top: 64px;
      }
    }
  }

  &-coupon-list {
    .ts-theme-bo-no-content {
      border-width: 2px 0 1px 0;
      border-style: solid;
      border-color: $color-black transparent #eaeaea transparent;
      padding: 96px 0;
    }
  }

  &-faq {
    margin: 0;
    padding: 0;
    border-top: 2px solid $color-black;
    color: $color-black;
    list-style: none;

    >li {
      border-bottom: 1px solid #eaeaea;

      .titbox {
        display: flex;
        position: relative;
        padding: 32px 72px 32px 16px;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 10px;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
          background: url(../images/common/btn_open.svg) no-repeat center;
          background-size: 12px 6px;
          transition: 0.3s;
        }

        &.on {
          .tit {
            font-weight: 700;
          }

          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .tit {
          flex: 1;
          display: flex;
          align-items: center;
          margin: 0;
          font-size: 18px;
          line-height: 21px;
          font-weight: 300;
        }
      }

      .conbox {
        display: none;
        padding: 24px 16px;
        background: #f5f6f8;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;

        p {
          margin: 0;
        }
      }
    }
  }

  &-inquiry {
    border-top: 2px solid $color-black;
    color: $color-black;
    list-style: none;

    &>[class^="box"] {
      position: relative;
      padding: 32px 20px 36px 50px;

      .bullet {
        display: block;
        position: absolute;
        top: 32px;
        left: 20px;
        font-family: $font-family-2;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
      }
    }

    .box-question {
      background-color: #fff;

      .bullet {
        color: $color-p;
      }
    }

    .box-answer {
      background-color: #f5f6f8;
      .text {
        margin:0;
      }
      .date {
        margin-top:20px;
      }
      .file {
        margin-top:24px;
      }
    }

    .tit {
      margin: 0;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
    }

    .date {
      display: block;
      margin: 12px 0 0 0;
      font-size: 14px;
      line-height: 17px;
      font-weight: 300;
      color: #666666;
      font-family: $font-family-2;
    }

    .text {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }

    .file {
      display: block;
      position: relative;
      margin: 20px 0 0 0;
      padding-left: 20px;
      color: $color-black;
      font-size: 14px;
      font-weight: 300;
      text-decoration: underline;
      background: url(../images/common/ico_upload.svg) no-repeat left top 3px;
      background-size: 15px 15px;
    }
  }

  &-register-inquiry {
    border-top: 2px solid $color-black;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 32px;

    .font-16 {
      font-size: 16px;
      line-height: 21px;
    }

    .input-group {
      flex-wrap: wrap;
      width: 100%;

      .input-labelbox {
        flex-basis: auto;
        width: 50%;
        margin: 8px 0;
      }

      &.radio {
        margin: -8px 0;
      }
    }

  }

  &-no-content {
    @include flex-center(flex);
    flex-direction: column;
    width: 100%;
    background-color: $color-white;

    &>.txtbox {
      width: 100%;
      text-align: center;

      .ico-error {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        background: url(../images/common/ico_no_result.svg) no-repeat center/contain;
      }

      .txt {
        margin: 16px 0 0 0;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        .txt-heart {
          display: inline-flex;
          vertical-align: middle;
          margin-top:-3px;
          width: 20px;
          height: 20px;
          background-position: center;
          background-size: 20px 20px;
        }
      }
    }

    /* 추가내용 있을시 */
    .store-link-list {
      @include flex-center(flex);
      width: 100%;
      margin: 56px -4px -1px -4px;
      padding: 0;
      border-bottom: 1px solid $color-white;
      list-style: none;

      &>li {
        position: relative;
        margin: 4px 4px;
        width: calc(50% - 8px);
        min-width: 125px;
        background-color: #f5f6f8;

        &:before {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        .store-link {
          @include flex-center(flex);
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          color: inherit;

          .ico {
            width: 64px;
            height: 64px;
            @include bg-inline;
          }

          .txt {
            margin: 8px 0 0 0;
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            font-family: $font-family-2;
          }
        }
      }
    }
  }

  &-event- {
    &view-wrap {
      padding: 0 0 64px;
      border-bottom: 1px solid #eaeaea;
      & + .ts-theme-msgbox {
        margin:56px 0 0 0
      }
    }
    &view-conbox {
      padding: 64px 0 0;
      font-family: $font-family-2;
      p {
        margin:0;
        padding:0;
      }
      img {
        width:100%;
        height:auto;
        vertical-align:top;
      }
    }
    &action {
      // temporary
      .wrap-attendance-check {
        display:flex;
        flex-wrap:wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin:0 auto;
        padding:20px 23px;
        border-radius: 0 0 20px 20px;
        box-sizing:border-box;
        width:100%;
        background-color:#F5F6F8;
        .attendance {
          display:flex;
          justify-content: center;
          margin:4px;
          width:calc(20% - 8px);
          .inner {
            padding:12px 4px 6px;
            border:1px solid #EAEAEA;
            border-radius:48px;
            box-sizing:border-box;
            font-family: $font-family-2;
            background-color:#fff;
            text-align:center;
            width:100%;
            max-width:48px;
          }
          span {
            display:none;
            font-family: inherit;
            font-size:12px;
            line-height:14px;
          }
          b {
            display:block;
            margin: 0;
            font-family: inherit;
            font-size:16px;
            font-weight: 300;
            line-height: 19px;            
          }
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            position:relative;
            margin:6px auto 0;
            border-radius: 50%;
            width:100%;
            max-width:36px;
            background-color:#F5F6F8;
            &:after {
              content:"";
              display:block;
              padding-bottom:100%;
            }
            span {
              display:block;
              font-family: inherit;
              font-style:normal;
              font-size:14px;
              text-align:center;
              line-height:1;  
            }
          }
          &.attended {
            .inner {
              border-color: #692AE0;
              background-color: #692AE0;
            }
            span,
            b {
              color:$color-white
            }
            i {
              background:rgba(255,255,255,.15) url(../images/common/ico_attendance_checked.svg) no-repeat center center;
              background-size: 16px;
              span {
                display:none;
              }
            }
          }
          &.passed {
            .inner {
              border-color: #692AE0;
              background-color: #692AE0;
            }
            span,
            b {
              color:$color-white
            }
            i {
              background:rgba(255,255,255,.15);
              background-size: 16px;
              span {
                display:block;
              }
            }   
          }
        }
      }

      // temporary
      .wrap-setting-push {
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding:22px 20px;
        border-radius:12px;
        box-sizing:border-box;
        background-color:#F5F6F8;
        .text {
          b {
            display:block;
            color: $color-black;
            font-size: 15px;
            font-weight: 700;
            line-height: 18px;
          }
          span {
            display:block;
            margin-top:4px;
            color:#666;
            font-size:12px;
            line-height: 14px;
          }
        }
        .ts-theme-app-switch {
          flex:none;
          display: inline-flex;
          align-items: center;
          input[type=checkbox] {
            appearance: none;
            position:relative;
            padding:2px;
            border-radius:54px;
            box-sizing:border-box;
            width:54px;
            height:32px;
            background-color:#eaeaea;
            transition: background-color .25s linear;
            &:before {
              content:"";
              position: absolute;
              left:2px;
              width:28px;
              height:28px;
              border-radius:50%;
              background-color:$color-white;
              transition: left .25s linear;
            }
            &:checked {
              background-color:#4E49E3;
              transition: background-color .25s linear;
              &:before {
                left:24px;
                transition: left .25s linear;
              }
            }
          }
        }
      }

      & + .ts-theme-bo-prize-draw {
        margin:56px 0 0 0;
      }
      & + .ts-theme-msgbox {
        margin:56px 0 0 0;
      }
    }
    &inducing-app {
      margin: 56px 0 0 0;
      text-align: center;

      .title {
        margin: 0;
        font-size: 18px;
        line-height: 21px;
      }

      .txt {
        margin: 12px 0;
        font-size: 16px;
        line-height: 24px;
      }

      .box-links {
        @include flex-center(flex);
        margin: 24px 0;

        .link {
          display: block;
          width: calc(50% - 4px);
          max-width: 160px;
          text-decoration: none;

          img {
            display: block;
            width: 100%;
          }

          &+.link {
            margin-left: 7px;
          }
        }
      }
    }
    &chips {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0 0 0;

      // .ts-theme-chip-heart {
      //   display: flex;
      //   align-items: center;
      //   padding: 9px 15px;
      //   border: 1px solid $color-black;
      //   border-radius: 28px;
      //   color: $color-black;
      //   font-family: $font-family-1;
      //   & + .ts-theme-chip-heart {
      //     margin: 0 0 0 4px;
      //   }
      //   .ico-16 {
      //     width: 16px;
      //     height: 16px;
      //   }
      // }
      .ts-theme-check-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 8px;

        .ts-theme-ico-checkbox {
          margin: 0;
          width: 24px;
          height: 24px;
          border:0px solid transparent;
          box-shadow: none;
          background-color:transparent;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          appearance: none;

          &.ico-thumb-like {
            background-image: url(../images/common/ico_thumb_like.svg);
          }

          &.ico-face-smile {
            background-image: url(../images/common/ico_face_smile.svg);
          }

          &.ico-face-laugh {
            background-image: url(../images/common/ico_face_laugh.svg);
          }

          &.ico-face-love {
            background-image: url(../images/common/ico_face_love.svg);
          }
        }

        .num {
          display: block;
          margin: 4px 0 0 0;
          color: $color-black;
          font-family: $font-family-2;
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;
        }
      }
    }
  }
  // [app]
  &-prize-draw {
    text-align: center;

    .title {
      margin: 56px 0 0 0;
      padding: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
    }

    .text {
      margin: 12px 0 0 0;
      padding: 0 6px;
      box-sizing:border-box;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }

    .detail {
      display:flex;
      align-items:flex-start;
      margin: 12px 0 0 0;
      padding:0 10px;
      box-sizing:border-box;
      color: #666666;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      text-align:left;
      i {
        flex:none;
        margin-right:4px;
        font-style: normal;
      }
    }

    .box-gray {
      margin: 24px 0 0 0;
      padding: 16px 11px 16px 20px;
      box-sizing: border-box;
      background-color: #F5F6F8;

      .policy {
        display: flex;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
        text-align: left;

        .name {
          margin: 0 4px 0 0;
          padding: 0;
          flex: 0 0 fit-content;
          font-weight: 500;
        }

        .description {
          margin: 0;
          padding: 0;
          flex: 1 1 auto;
        }

        &+.policy {
          margin: 15px 0 0 0;
        }
      }
    }

    .btn-box {
      margin:32px 0 0 0;
    }
    & + .ts-theme-msgbox {
      margin:24px 0 0 0;
    }
  }
}

&no-content {
  padding: 40px 0;

  .ico-none {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    background: url(../images/common/ico_no_result.svg) no-repeat center/contain;
  }

  .txt {
    margin: 8px 0 0 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
  }
}

// 지금 타임스퀘어는
&now {
  margin: 96px -24px -32px;
  padding: 64px 24px;
  background-color: #f5f6f8;
  overflow: hidden;

  .swiper-container {
    margin: 0 -6px;
    overflow: visible;

    .swiper-wrapper {
      margin: 0;
      padding: 0;
      list-style: none;

      .swiper-slide {
        &.swiper-slide-active {
          .inbox {
            .txtbox {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        .inbox {
          display: block;
          position: relative;
          margin: 0 6px;
          color: $color-white;
          text-decoration: none;
          overflow: hidden;

          &.event {
            border-radius: 50%;
          }

          .imgbox {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              vertical-align: top;
            }
          }

          .txtbox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding:20px;
            box-sizing:border-box;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            transition: 0.6s;
            background-color: rgba(0, 0, 0, 0.64);

            .tit {
              font-size: 20px;
              line-height: 29px;
              font-weight: 700;
            }

            .info {
              display: flex;
              align-items: center;
              padding: 0;
              margin: 24px 0 0 0;
              list-style: none;

              >li {
                position: relative;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                font-family: $font-family-2;

                +li {
                  margin-left: 8px;
                  padding-left: 9px;

                  &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 12px;
                    background-color: $color-white;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}