&error-503 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: 100dvh;
    text-align: center;
    .box-error {
        .ico-error-503 {
            display:block;
            margin: 0 auto;
            width: 213px;
            height: 253px;
            background: url(../images/common/ico_error_503.svg) no-repeat center center/cover;
        }
        .title {
            margin: 52px 0 0 0;
            font-size: 24px;
            font-weight: 700;
            line-height: 40px;
        }
        .text {
            margin: 20px 0 0 0;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
        }
    }
}